<template>
	<div v-for="i in length" :key="i" class="mb-1">
        <v-card class="bg-lightGray w-100" :height="height" rounded="0" elevation="0"></v-card>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        },
        height: {
            type: Number,
            default: 30
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>