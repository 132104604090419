import axios from '@/config/api.request'

export default {
    // 根据 NFT 主键查询统计数据
    nftStat(nftId) {
        return axios.request({
            url: '/nft/stat/stat',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    // 浏览 NFT
    view(nftId) {
        return axios.request({
            url: '/nft/stat/view',
            method: 'get',
            params: { nftId: nftId }
        })
    },
    
}