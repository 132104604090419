<template>
	<div>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12" sm="6" md="3">
                    <v-sheet class="py-2 my-2 body-h4 text-grey04" color="black">
                        ERC-5173
                    </v-sheet>
                    <span class="body-p text-primaryGrey">Our Ethereum standard that enables a new financial market system on-chain</span>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mx-16">
                    <v-sheet class="py-2 my-2 body-h4 text-grey04" color="black">
                        Transparency
                    </v-sheet>
                    <span class="body-p text-primaryGrey">Untrading is open source and all transcations are public</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-sheet class="py-2 my-2 body-h4 text-grey04" color="black">
                        Stay in Control
                    </v-sheet>
                    <span class="body-p text-primaryGrey">Decentralized, open-source, self-custody on upgradeable smart contracts</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-8">
                <v-col cols="12" sm="6" md="3">
                    <v-sheet class="py-2 my-2 body-h4 text-grey04" color="black">
                        Upgrade ERC-20
                    </v-sheet>
                    <span class="body-p text-primaryGrey">Wrap ERC-20 to make it your OWN (as an originator)</span>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mx-16">
                    <v-sheet class="py-2 my-2 body-h4 text-grey04" color="black">
                        Effortless
                    </v-sheet>
                    <span class="body-p text-primaryGrey">Reap the rewards without additional effort</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-sheet class="py-2 my-2 body-h4 text-grey04" color="black">
                        Security
                    </v-sheet>
                    <span class="body-p text-primaryGrey">All assets are wrapped 1:1 and can be unwrapped</span>
                </v-col>
            </v-row>
    </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>