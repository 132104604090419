<template>
	<div>
        <v-expansion-panels class="mb-6">
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" expand-icon="mdi:mdi-plus"><h4>What is untrading?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="body-p text-primaryGrey">Untrading is a revolutionary DeFi platform that allows you to profit from asset appreciation even after selling. Using our unique ERC-5173 standard, we've created a system where past owners continue to benefit from future price increases. It's a commission-free protocol for trading both crypto and NFTs, designed to create a win-win scenario for all participants.</v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" expand-icon="mdi:mdi-plus"><h4>What are kingdoms, how do they work?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="body-p text-primaryGrey">
                    <div>Kingdoms are our gamified approach to community building and collaborative investing. Here's how they work:</div>
                    <ul class="mt-2">
                        <li>Start as a Knight: Begin your journey by creating or joining a small community.</li>
                        <li>Grow Your Influence: As your community grows and engages more with the platform, you climb the ranks.</li>
                        <li>Set Rules: As a leader, you can set custom rules for asset management and profit-sharing within your kingdom.</li>
                        <li>Collaborative Wealth: Work together with your community to maximize returns and share in the prosperity.</li>
                        <li>Rise to Royalty: Progress from Knight to Sheikh, Sultan, Jiangjun, and ultimately to King or Queen as your kingdom expands.</li>
                        <li>Outside Tournament: Join Untrading's annual tournaments and compete in exotic places around the world for prizes with other kingdoms.</li>
                    </ul>
                    <div class="mt-2">Kingdoms incentivize community growth, active participation, and collaborative strategies, aligning with Untrading's core principle of cooperative wealth creation.</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" expand-icon="mdi:mdi-plus"><h4>Why would I want to share my profit?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="body-p text-primaryGrey">
                    <div>Sharing profits on Untrading isn't about giving away your gains - it's about unlocking new avenues for wealth creation:</div>
                    <ul class="mt-2">
                        <li>Ongoing Rewards: By sharing a small portion of your profits, you gain the right to receive future rewards from assets you've sold.</li>
                        <li>Larger Profit Potential: The collective growth of assets benefits all participants, potentially leading to larger overall gains.</li>
                        <li>Reduced Risk: Diversify your earnings across multiple assets, even ones you no longer own.</li>
                        <li>Community Benefits: Stronger communities lead to more stable markets and better trading opportunities.</li>
                        <li>Aligned Incentives: When everyone benefits from an asset's success, it encourages better market behavior and reduces manipulation.</li>
                    </ul>
                    <div class="mt-2">Remember, on Untrading, you're not just sharing profits - you're gaining access to a new paradigm of collaborative wealth creation.</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
export default {
    data(){
        return {
           
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>