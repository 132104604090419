import axios from '@/config/api.request'

export default {
    // 是否已经认证登录
    authorization() {
        return axios.request({
            url: '/user/authorization',
            method: 'get'
        })
    },
    // 获取登录过期时间
    getExpiration() {
        return axios.request({
            url: '/user/expiration',
            method: 'get'
        })
    },
    // 链接钱包
    connectWallet(data) {
        return axios.request({
            url: '/user/wallet/connect',
            method: 'post',
            data: data
        })
    },
    // 查询登录用户信息
    getMe() {
        return axios.request({
        url: '/user/me',
        method: 'get'
        })
    },
    // 注册
    signup(data) {
        return axios.request({
            url: '/user/signup',
            method: 'post',
            data: data
        })
    },
    // 登录
    signin(data) {
        return axios.request({
        url: '/user/signin',
        method: 'post',
        data: data
        })
    },
    // 是否存在账户
    exists(data) {
        return axios.request({
            url: '/user/exists',
            method: 'post',
            data: data
        })
    },
    // 根据用户名获取用户信息
    getByUsername(username, blockchain) {
        return axios.request({
            url: '/user/user',
            method: 'get',
            params: { 
                username: username,
                blockchain: blockchain
            }
        })
    },
    // 根据用户名获取简单用户信息
    getSimpleUserByUsername(username) {
        return axios.request({
            url: '/user/username/simple',
            method: 'get',
            params: { username: username }
        })
    },
    // 根据钱包地址获取简单用户信息
    getSimpleUserByWallet(wallet) {
        return axios.request({
            url: '/user/wallet/simple',
            method: 'get',
            params: { wallet: wallet }
        })
    },
    // 根据用户主键获取简单用户信息
    getSimpleUserById(id) {
        return axios.request({
            url: '/user/id/simple',
            method: 'get',
            params: { id: id }
        })
    },
    // 编辑登录用户信息
    update(data) {
        return axios.request({
            url: '/user/update',
            method: 'post',
            data: data
        })
    },
    // 查看钱包地址的注册状态
    status(wallet) {
        return axios.request({
            url: '/user/wallet/status',
            method: 'get',
            params: { wallet: wallet }
        })
    },
    // 查询流行的艺术家列表
    getTrendingArtists() {
        return axios.request({
            url: '/user/trending/artists',
            method: 'get'
        })
    },
    // 查询最佳的收藏家列表
    getTopCollectors() {
        return axios.request({
            url: '/user/top/collectors',
            method: 'get'
        })
    },
    // 根据用户名分页获取该用户的收藏家
    getUserCollectorsByPage(username, page, size) {
        return axios.request({
            url: '/user/collectors',
            method: 'get',
            params: { 
                username: username,
                page: page,
                size: size
            }
        })
    },
    // 查询用户仪表盘 NFT 数据
    getDashboards(blockchain) {
        return axios.request({
            url: '/user/dashboard/nft',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
    // 查询用户仪表盘加密货币本地代币统计数据
    getCryptoNativeDashboards(blockchain) {
        return axios.request({
            url: '/user/dashboard/crypto/native',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
    // 查询用户仪表盘加密货币 ERC20 代币统计数据
    getCryptoTokenDashboards(blockchain) {
        return axios.request({
            url: '/user/dashboard/crypto/token',
            method: 'get',
            params: {
                blockchain: blockchain
            }
        })
    },
}