<template>
    <div>
        <!-- 这个id在详情页面全屏时需要 -->
        <!-- 政策规定自动播放时必须静音 -->
        <!-- <video :id="src" class="w-100" :style="{ height: height + 'px' }" :src="media.fileUrl || src" controls autoplay muted loop crossOrign="anonymous"></video> -->
        <div style="position: relative;" :style="{ paddingTop: ((1 / aspectRatio) * 100) + '%' }">
            <iframe :id="src" class="w-100" :src="(media.fileUrl || src) + '?muted=true&preload=true&loop=true&autoplay=true'" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="false"></iframe>
        </div>
    </div>
</template>
<script>
import MediaAPI from '@/api/media.js';
export default {
    data(){
        return {
            // 纵横比
            aspectRatio: 1,
            // 宽度
            width: 0,
            // 高度
            height: 0,
            // 媒体文件
            media: {}
        }
    },
    props: {
        // 文件链接
        src: { type: String, default: null },
    },
    components: {  },
    created(){
        
    },
    mounted(){

    },
    computed: {

    },
    watch:{
        src: {
            handler(newVal, oldVal) {
                setTimeout(() => {
                    let div = document.getElementById(this.src);
                    this.width = parseInt(div.style.width) || div.offsetWidth;
                    // 先默认为 1 正方形
                    this.setVideoDimensions(1);
                }, 10);
                // 查询媒体
                this.getMedia();
            },
            immediate: true
        }
    },
    methods: {
        // 查询媒体
        async getMedia() {
            if(this.src && this.src.indexOf('https://ipfs.io/ipfs/') > -1){
                this.getMediaByCid();
            } else {
                this.getMediaByFile();
            }
        },
        // 根据 CID 查询媒体
        async getMediaByCid() {
            let cid = this.src.substring(this.src.lastIndexOf('/') + 1, this.src.length);
            let res = await MediaAPI.getMediaByCid(cid);
            let data = res.data;
            if(data.success) {
                this.media = data.data;
                // 尺寸
                let dimensions = this.media.dimensions.split('x');
                // 纵横比
                this.aspectRatio = dimensions[0] / dimensions[1];
                this.setVideoDimensions(this.aspectRatio);
            }
        },
        // 根据文件链接查询媒体
        async getMediaByFile() {
            let res = await MediaAPI.getMediaByFile(this.src);
            let data = res.data;
            if(data.success) {
                this.media = data.data;
                // 尺寸
                let dimensions = this.media.dimensions.split('x');
                // 纵横比
                this.aspectRatio = dimensions[0] / dimensions[1];
                this.setVideoDimensions(this.aspectRatio);
            }
        },
        // 设置视频尺寸
        setVideoDimensions (aspectRatio) {
            let div = document.getElementById(this.src);
            this.width = parseInt(div.style.width) || div.offsetWidth;
            this.height = this.width / aspectRatio;
        },
        
    }
}
</script>
<style scoped>
</style>