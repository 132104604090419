import axios from '@/config/api.request'

export default {
    // 查询全部加密货币报价资产
    getCryptoQuotes(blockchain) {
        return axios.request({
            url: '/crypto/quote/list',
            method: 'get',
            params: { blockchain }
        })
    },
    // 根据区块链和地址查询加密货币报价资产详情
    async getCryptoQuoteDetail(blockchain, address) {
        return axios.request({
            url: '/crypto/quote/detail',
            method: 'get',
            params: { blockchain, address }
        })
    }
}