<template>
	<div class="h-100">
        <v-container class="h-100">
            <v-row no-gutters justify="center" align="end" class="h-100">
                <v-card color="transparent" elevation="0" rounded="0" width="1000" class="mx-auto">
                    <Hero></Hero>
                </v-card>
                <v-col cols="12" class="text-center" align="bottom">
                    <h5 class="body-p-small-b text-primaryGrey pb-4">Built to disrupt</h5>
                    <div class="arrow-scroll">
                        <div class="arrow-scroll__wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="18" viewbox="0 0 38 18">
                                <path fill="#7b7b7b" d="M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z"></path>
                            </svg>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container> 
        <v-container class="footer-class fullscreen bg-black">
            <v-row no-gutters class="mt-500" justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <CryptoScreenshot class="mb-200"></CryptoScreenshot>
                </v-card>
            </v-row>
            <!-- Introduction -->
            <v-row no-gutters justify="center" align="end" class="mb-100 mt-500">
                <v-card color="transparent" elevation="0" rounded="0" width="1000" class="mx-auto">
                    <div class="d-flex flex-column">
                        <div class="fs-36">Untrading, the Way Trading <span class="text-primary fw-b">Should Be</span></div>
                        <div class="title-h4 text-primaryGrey mt-5">Aligned Incentives for All Parties</div>
                    </div>
                </v-card>
            </v-row>
            <v-cols cols="12" class="px-0">
                <Introduction></Introduction>
            </v-cols>

            <!-- Kingdom -->
            <v-row no-gutters justify="center" align="end" class="mb-100 mt-500">
                <v-card color="transparent" elevation="0" rounded="0" width="1000" class="mx-auto">
                    <div class="d-flex flex-column">
                        <div class="fs-36">Build Your Kingdom on Untrading</div>
                        <div class="title-h4 text-primaryGrey mt-4">Become a King, Serve Your Realm</div>
                        <div class="body-p text-primaryGrey mt-4">Kingdoms on untrading allow you to utilize our technologies in a social and gamified way. The next evolution of trading groups.</div> 
                    </div>
                    <Kingdom></Kingdom>
                </v-card>
            </v-row>

            <!-- Feature -->
            <v-row no-gutters class="mt-500" justify="center" align="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <v-col cols="11" offset="1" class="pl-4 px-0">
                        <div class="fs-36">Features</div>
                        <div class="title-h4 text-primaryGrey mt-5">A whole new platform under the hood.</div>
                    </v-col>
                    <v-col cols="11" offset="1" class="pl-4 px-0 mt-5">
                        <Feature></Feature>
                    </v-col>
                </v-card>
            </v-row>

            <!-- CryptoScreenshot -->
            <v-row no-gutters class="mt-500" justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <NftScreenshot class="mb-200"></NftScreenshot>
                </v-card>
            </v-row>

            <!-- NFT -->
            <v-row no-gutters justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <div class="d-flex flex-column">
                        <div class="body-p text-primaryGrey">Our unique ERC-5173 standard allows users to trade both cryptocurrencies and NFTs while benefiting from future price increases, even after selling their assets.</div> 
                    </div>
                    <Nft></Nft>
                </v-card>
            </v-row>

            <!-- Data -->
            <v-row no-gutters class="mt-500" justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <Data></Data>
                    <div class="d-flex flex-column mt-200">
                        <div class="body-p text-primaryGrey mt-4">Self-Funding and Control: With over $2 million dollars invested with skin in the game, we are committed to reduce the conflicts in strategic direction or pressure for short-term gains at the expense of long-term value creation</div> 
                    </div>
                </v-card>
            </v-row>

            <!-- FAQ -->
            <v-row no-gutters class="mt-500 mb-500" justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                   <v-row no-gutters>
                        <v-col cols="12" sm="6" md="4" class="px-0">
                            <div class="fs-36 fw-b">FAQs</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="8" class="pa-0">
                            <Faq></Faq>
                            <div class="del-underline gradient-underline-hover text-primary sidebar-h2 pl-6 mt-5">
                                <a href="https://docs.untrading.org/faqs" target="_blank" class="body-p text-primary gradient-underline">
                                    More <span class="mdi mdi-arrow-right-thin"></span>
                                </a>
                            </div>
                        </v-col>
                   </v-row>
                </v-card>
            </v-row>

            <!-- News -->
            <!-- <v-row no-gutters class="mt-500 mb-500" justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <div class="d-flex flex-column">
                        <div class="fs-36 fw-b">In the News</div> 
                    </div>
                    <Meida class="ml-4"></Meida>
                </v-card>
            </v-row> -->
            <v-divider></v-divider>
            <!-- Footer -->
            <v-row no-gutters justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <Footer></Footer>
                </v-card>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters justify="center">
                <v-card color="transparent" elevation="0" rounded="0" width="1000">
                    <div class="body-p-small text-primaryGrey d-flex flex-nowrap align-center mt-8">
                        <span>
                            <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" class="del-underline pointer" aria-label="Licenses">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8554187d-f5dc-41f7-eb9e-637396d9d100/public" cover class="mb-3" width="80" alt="" />
                            </a>
                        </span>
                        <span class="pl-4 mb-2">
                            Except where otherwise noted, content on this site is licensed under a Creative Commons Attribution 4.0 International license.
                        </span>
                    </div>
                </v-card>
            </v-row>
     
        </v-container> 
	</div>
</template>
<script>
import Hero from './components/Hero.vue';
import CryptoScreenshot from './components/CryptoScreenshot.vue';
import Data from './components/Data.vue';
import Faq from './components/Faq.vue';
import Feature from './components/Feature.vue';
import Footer from './components/Footer.vue';
import Introduction from './components/Introduction.vue';
import Kingdom from './components/Kingdom.vue';
import Nft from './components/Nft.vue';
import NftScreenshot from './components//NftScreenshot.vue';
import Meida from './components//Meida.vue';
export default {
    data(){
        return {
            scroll: 0,
        }
    },
    components: {Hero, CryptoScreenshot, Data, Faq, Feature, Introduction, Kingdom, Nft, NftScreenshot,Meida,Footer },
    created(){
      
    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
    
    }
}
</script>
<style scoped>

</style>