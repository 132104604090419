<template>
	<div>
        <v-card rounded="0" elevation="12" class="text-primaryGrey" color="black">
            <MediaFile :nftId="nfr.nft.id" overlay></MediaFile>
            <div>
                <v-row no-gutters class="ma-4">
                    <v-col cols="6" class="pr-4">
                        <div class="body-h5 mb-2">
                            Originator
                        </div>
                        <div class="c-center parent">
                            <UserAvatar :avatar="nfr.nft.user.avatar" :username="nfr.nft.user.username" :name="nfr.nft.user.name"></UserAvatar>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="body-h5 mb-2">
                            Owner
                        </div>
                        <div>
                            <OwnerAvatar :avatar="nfr.nft.owner.avatar" :username="nfr.nft.owner.username" :name="nfr.nft.owner.name"></OwnerAvatar>
                        </div>
                    </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <div class="py-4">
                    <!-- Unwrap -->
                    <router-link v-if="wrap && !getUnwrapSignatureLoading && currentUnwrapSignature.status != null" :to="'/unnft/unwrap/' + nfr.nft.id" class="del-underline mb-4 data-wrap">
                        <div class="body-p-large">
                            <v-row no-gutters align="center">
                                <v-col cols="11" class="body-p">
                                    <span v-unwrap-signature-status="currentUnwrapSignature.status"></span>
                                </v-col>
                                <v-col cols="1" class="text-right">
                                    <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </router-link>
                    <!-- -------------- NFT -------------- -->
                    <a v-if="wrap || unwrap" :href="$tools.getBlockchainExplorerUrl(nfr.nft.blockchain) + '/token/' + nfr.nft.originalContractAddress + '?a=' + nfr.nft.originalTokenId" class="del-underline mb-4 data-nfr" target="_blank">
                        <div class="body-p-small">
                            ORIGINAL NFT
                        </div>
                        <div class="body-p-large">
                            <v-row no-gutters align="center">
                                <v-col cols="11" class="body-p">
                                    {{ $tools.privacyField(nfr.nft.originalContractAddress) }}(#{{ nfr.nft.originalTokenId }})
                                </v-col>
                                <v-col cols="1" class="text-right">
                                    <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </a>
                    <!-- -------------- ORs -------------- -->
                    <router-link :to="'/dashboard/asset/unnft/ors/received/' + nfr.nft.id" class="del-underline mb-4 data-nfr">
                        <div class="body-p-small">
                            TOTAL ORs RECEIVED
                        </div>
                        <div class="body-p-large">
                            <!-- <span>{{ nfr.totalORsReceived.amount > 0 ? $tools.formatNumber(nfr.totalORsReceived.amount, 8) : '--' }}</span>
                            <span class="ml-2 body-p">{{ nfr.totalORsReceived.amount > 0 ? nfr.totalORsReceived.paymentToken : '' }}</span>
                            <span class="float-right">
                                <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                            </span> -->
                            <v-row no-gutters align="center">
                                <v-col cols="7">
                                    {{ nfr.totalORsReceived.amount > 0 ? $tools.formatNumber(nfr.totalORsReceived.amount, 8) : '--' }}
                                </v-col>
                                <v-col cols="4" class="body-p">
                                   {{ nfr.totalORsReceived.amount > 0 ? nfr.totalORsReceived.paymentToken : '' }}
                                </v-col>
                                <v-col cols="1" class="text-right">
                                    <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </router-link>
                    <!-- -------------- FRs -------------- -->
                    <router-link :to="'/dashboard/asset/unnft/frs/received/' + nfr.nft.id" class="del-underline mb-4 data-nfr">
                        <div class="body-p-small">
                            TOTAL FRs RECEIVED
                        </div>
                        <div class="body-p-large">
                            <!-- <v-card color="transparent" max-width="200px" rounded="0" elevation="0">{{ nfr.totalFRsReceived.amount > 0 ? $tools.formatNumber(nfr.totalFRsReceived.amount, 8) : '--' }}</v-card>
                            <span class="ml-2 body-p">{{ nfr.totalFRsReceived.amount > 0 ? nfr.totalFRsReceived.paymentToken : '' }}</span>
                            <span class="float-right">
                                <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                            </span> -->
                            <v-row no-gutters align="center">
                                <v-col cols="7">
                                    {{ nfr.totalFRsReceived.amount > 0 ? $tools.formatNumber(nfr.totalFRsReceived.amount, 8) : '--' }}
                                </v-col>
                                <v-col cols="4" class="body-p">
                                    {{ nfr.totalFRsReceived.amount > 0 ? nfr.totalFRsReceived.paymentToken : '' }}
                                </v-col>
                                <v-col cols="1" class="text-right">
                                    <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </router-link>
                    <!-- -------------- nFRs -------------- -->
                    <router-link :to="'/dashboard/asset/unnft/nfrs/paid/' + nfr.nft.id" class="del-underline mb-4 data-nfr">
                        <div class="body-p-small">
                            TOTAL nFR PAYOUTS
                        </div>
                        <div class="body-p-large">
                            <!-- <v-card color="transparent" max-width="100px" rounded="0" elevation="0">{{ nfr.totalNFRsPayouts.amount > 0 ? $tools.formatNumber(nfr.totalNFRsPayouts.amount, 8) : '--' }}</v-card>
                            <span class="ml-2 body-p">{{ nfr.totalNFRsPayouts.amount > 0 ? nfr.totalNFRsPayouts.paymentToken : '' }}</span>
                            <span class="float-right ">
                                <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                            </span> -->
                            <v-row no-gutters align="center">
                                <v-col cols="7">
                                    {{ nfr.totalNFRsPayouts.amount > 0 ? $tools.formatNumber(nfr.totalNFRsPayouts.amount, 8) : '--' }}
                                </v-col>
                                <v-col cols="4" class="body-p">
                                    {{ nfr.totalNFRsPayouts.amount > 0 ? nfr.totalNFRsPayouts.paymentToken : '' }}
                                </v-col>
                                <v-col cols="1" class="text-right">
                                    <v-icon class="mt-n1" size="24" icon="fa-solid fa-up-right-from-square"></v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </router-link>
                </div>
            </div>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js';
import NFRAPI from '@/api/nfr.js';
import NFTUnwrapAPI from '@/api/nft-unwrap.js';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            // 查询 unwrap 签名加载中
            getUnwrapSignatureLoading: false,
            // 当前最新一条签名记录
            currentUnwrapSignature: {},
        }
    },
    props: {
        // NFR 对象
        nfr: { 
            type: Object, 
            default: {
                nft: {
                    user: {},
                    owner: {},
                    set: {}
                },
                totalORsReceived: {},
                totalFRsReceived: {},
                totalNFRsPayouts: {}
            }
        },
        // 是否封装来的 
        wrap: {
            type: Boolean,
            default: false
        },
        // 是否已经解封 
        unwrap: {
            type: Boolean,
            default: false
        }
    },
    components: { MediaFile },
    created(){
        
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user','darkTheme']),
    },
    watch:{
        'nfr.nft.id': {
            handler(n, o) {
                this.getUnwrapSignature();
            },
            immediate: true
        }
    },
    methods: {
        // 查询最新一条签名记录
        async getUnwrapSignature() {
            if(this.nfr.nft.contractFunction != 'WRAP') {
                return;
            }
            this.getUnwrapSignatureLoading = true;
            let res = await NFTUnwrapAPI.getUnwrapSignature(this.nfr.nft.id);
            let data = res.data;
            if(data.success) {
                this.currentUnwrapSignature = data.data;
            }
            this.getUnwrapSignatureLoading = false;
        },
    }
}
</script>
<style scoped>

</style>