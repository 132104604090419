<template>
	<div class="bg-lightGray align-center h-100">
        <v-container class="h-100">
            <v-card color="transparent" max-width="1400" rounded="0" elevation="0" class="mx-auto pt-7">
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-16">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1" class="justify-center">
                                <!-- 返回按钮 -->
                                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-13 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <h1 class="sub-page-h1 ml-13 text-primaryGrey r-m-l-0">
                                    <span v-if="nft.contractFunction == 'UNWRAP'">Unwrapped unNFT</span>
                                    <span v-else>Unwrap your unNFT</span>
                                </h1>
                                <v-card max-width="560" class="ml-13 pa-8 mb-16 mt-8 r-m-l-0" color="paleYellow" rounded="0" elevation="2">
                                    <!-- ------------------------- Price ------------------------- -->
                                    <div class="body-h4 mb-6 text-uppercase text-primaryGrey">Price</div>
                                    <v-row align="center">
                                        <v-col cols="8" class="pa-0">
                                            <v-text-field v-model="nftListInfo.price" type="number" min="0" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="text-primaryGrey">
                                            {{ nft.paymentToken }}
                                        </v-col>
                                    </v-row>
                                    <!-- ------------------------- Set ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">
                                        Sets
                                    </h3>
                                    <div class="mt-6">
                                        <v-text-field v-model="nft.set.name" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                    </div>
                                    <!-- ------------------------- Category ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">Category</h3>
                                    <div class="mt-6">
                                        <v-text-field v-model="nft.category" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                    </div>
                                    <!-- ------------------------- Tags ------------------------- -->
                                    <div class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">Tags</div>
                                    <div class="mt-3">
                                        <v-chip :rounded="0" label class="mr-3 mb-3" v-for="(tag, i) in nft.tags" :key="i">{{ tag }}</v-chip>
                                    </div>
                                </v-card>
                                <div class="ml-13 r-m-l-0" v-if="!loading">
                                    <div v-if="nft.contractFunction != 'UNWRAP'">
                                        <div class="d-flex justify-space-between">
                                            <router-link :to="'/unnft/list/' + id" class="del-underline gradient-left-red-purple-200">
                                                <v-btn v-if="!nftListInfo.isListed" :disabled="unwrapLoading" :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" aria-label="List">List</v-btn>
                                                <v-btn v-if="nftListInfo.isListed" :disabled="unwrapLoading" :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" aria-label="Change price">Change price</v-btn>
                                            </router-link>
                                            <span class="gradient-left-red-purple-200" v-if="!getUnwrapSignatureLoading">
                                                <v-btn v-if="isOwner && currentUnwrapSignature.status == 'REQUESTED'" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" disabled aria-label="Requested">Requested</v-btn>
                                                <v-btn v-else-if="isOriginator && currentUnwrapSignature.status == 'SIGNED'" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" disabled aria-label="Approved">Approved</v-btn>
                                                <v-btn v-else-if="nft.permission.confirmUnwrap && nft.permission.signUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" @click="unwrap()" aria-label="Unwrap">Unwrap</v-btn>
                                                <v-btn v-else-if="nft.permission.requestUnwrap && !nft.permission.signUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" @click="requestUnwrap()" aria-label="Request Unwrapping">Request Unwrapping</v-btn>
                                                <v-btn v-else-if="!nft.permission.requestUnwrap && nft.permission.signUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" @click="signUnwrap()" aria-label="Approve Unwrapping">Approve Unwrapping</v-btn>
                                                <v-btn v-else-if="nft.permission.confirmUnwrap" :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" @click="unwrap()" aria-label="Confirm Unwrapping">Confirm Unwrapping</v-btn>
                                                <v-btn v-else :rounded="0" :loading="unwrapLoading" color="button01" class="text-none text-grey05 body-p-small-b r-m-t-4" width="200" height="52" disabled aria-label="Unwrap">Unwrap</v-btn>
                                            </span>
                                        </div>
                                        <div class="mt-10">
                                            <router-link :to="$tools.getUnNFTUrl(nft.blockchain, id)" class="del-underline gradient-left-red-purple-block">
                                                <v-btn :disabled="unwrapLoading" :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b block" height="52" aria-label="View">View</v-btn>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="5" sm="6" cols="12" class="ml-12 mt-2 r-m-l-0 r-m-t-16">
                                <UnNftMedia :nftId="id"></UnNftMedia>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose" @signSuccess="signSuccess" @signError="signError"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose" @signSuccess="signSuccess" @signError="signError"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import * as Ethers from "ethers";
import NFTAPI from '@/api/nft.js';
import NFTCategoryAPI from '@/api/nft-category.js';
import NFTProvenanceAPI from '@/api/nft-provenance.js';
import NFTUnwrapAPI from '@/api/nft-unwrap.js';
import UnNftMedia from '@/components/nft/UnNftMedia.vue';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 解封加载中
            unwrapLoading: false,
            // nft 解封参数
            unwrapNFT: {
                nftId: null,
                blockchain: null,
                transactionHash: null,
                signature: null
            },
            // NFT 信息
            nft: {
                set: {},
                user: {},
                owner: {},
                permission: {}
            },
            // NFT 上市信息
            nftListInfo: {
                price: null,        // 上市价格
                lister: null,       // 上市人
                isListed: false     // 是否已经上市
            },
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 当前的区块链
            currentBlockchain: {},
            web3: {},
            // 签名消息
            signMessage: 'untrading.org uses this signature to verify that you are the owner of this wallet address.',
            // 签名
            signature: null,
            // 当前的解封状态
            unwrapStatus: null,
            // 解封交易成功
            unwrapTransactionSuccess: false,
            // 查询 unwrap 签名加载中
            getUnwrapSignatureLoading: false,
            // 当前最新一条签名记录
            currentUnwrapSignature: {},
            // 我是拥有者吗
            isOwner: false,
        }
    },
    components: { UnNftMedia },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "NFT_UNWRAP") {
                this.callGetTransactionReceipt(data.data);
            }
        })
        this.$bus.on('callGetListInfo', data => {
            if (data.type == "NFT_LIST") {
                this.callGetListInfo(data.data);
            }
        })
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'walletType', 'blockchains']),
        // 我是发起人吗
        isOriginator(){
            return this.user && this.nft.userId == this.user.id;
        }
    },
    watch:{
        id: {
            handler(n, o) {
                this.getNFTById();
            },
            immediate: true
        },
    },
    methods: {
        // 是否存在该 NFT
        async exists() {
            let res = await NFTAPI.exists(this.id);
            let data = res.data;
            if(data.success && data.data) {
                setTimeout(() => {
                    // 存在则去详情页面
                    this.$router.push(this.$tools.getUnNFTUrl(this.nft.blockchain, this.nft.id));
                }, 100);
            } else  {
                // 不存在则导航到404
                this.$router.push('/404');
            }
        },
        // 查询owner信息
        async ownerOf() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let owner = await NFTContract.methods.ownerOf(this.nft.tokenId).call();
            if(owner) {
                this.isOwner = this.$tools.equalsIgnoreCase(owner, this.user.wallet);
            }
        },
        // 根据主键查询 NFT
        async getNFTById() {
            this.loading = true;
            let res = await NFTAPI.getNFTById(this.id);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                // 判断权限
                if(!(this.nft.contractFunction == 'WRAP' || this.nft.contractFunction == 'UNWRAP')) {
                    // 判断是否存在
                    this.exists();
                    return;
                }
                // 查询最新一条签名记录
                this.getUnwrapSignature();
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0]
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 查询上市信息
                let listParams = {
                    type: "NFT_LIST",
                    blockchain: this.currentBlockchain.blockchain,
                    tokenId: this.nft.tokenId
                }
                this.$bus.emit('emitGetListInfo', listParams);
                // 查询owner信息
                this.ownerOf();
            }
            this.loading = false;
        },
        // 回调上市详情数据
        callGetListInfo(data) {
            this.nftListInfo.price = this.web3.utils.fromWei(data[0]);
            this.nftListInfo.lister = data[1];
            this.nftListInfo.isListed = data[2];
        },
        // 查询最新一条签名记录
        async getUnwrapSignature() {
            this.getUnwrapSignatureLoading = true;
            let res = await NFTUnwrapAPI.getUnwrapSignature(this.id);
            let data = res.data;
            if(data.success) {
                this.currentUnwrapSignature = data.data;
                this.signature = this.currentUnwrapSignature.signature;
            }
            this.getUnwrapSignatureLoading = false;
        },
        // 请求解封 NFT
        async requestUnwrap() {
            this.unwrapLoading = true;
            this.unwrapStatus = 'REQUESTED';
            if(this.signature) {
                // 已签名则调用 api
                let params = { nftId: this.id };
                let res = await NFTUnwrapAPI.requestUnwrap(params);
                let data = res.data;
                this.unwrapLoading = false;
                if(data.success && data.data) {
                    this.$store.dispatch('snackbarMessageHandler', "Requested!");
                    // 监听查询未读通知数量
                    this.$bus.emit('emitGetUnreadCount', null);
                    // 根据主键查询 NFT
                    this.getNFTById();
                } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                }
            } else {
                // 调起钱包签名
                if(this.walletType) {
                    this.$refs[this.walletType].signTypedData(this.nft.blockchain, this.nft.tokenId);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    this.unwrapLoading = false;
                }
            }
        },
        // 签名解封 NFT
        async signUnwrap() {
            this.unwrapLoading = true;
            this.unwrapStatus = 'SIGNED';
            if(this.signature) {
                // 已签名则调用 api
                let params = { 
                    nftId: this.id,
                    signature: this.signature
                };
                let res = await NFTUnwrapAPI.signUnwrap(params);
                let data = res.data;
                this.unwrapLoading = false;
                if(data.success && data.data) {
                    this.$store.dispatch('snackbarMessageHandler', "Approved!");
                    // 监听查询未读通知数量
                    this.$bus.emit('emitGetUnreadCount', null);
                    // 根据主键查询 NFT
                    this.getNFTById();
                } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                }
            } else {
                // 调起钱包签名
                if(this.walletType) {
                    this.$refs[this.walletType].signTypedData(this.nft.blockchain, this.nft.tokenId);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    this.unwrapLoading = false;
                }
            }
        },
        // 直接解封 NFT
        async unwrap() {
            this.unwrapLoading = true;
            this.unwrapStatus = 'UNWRAPPED';
            // 已签名则调用 api
            if(this.signature) {
                // 交易成功
                if(this.unwrapTransactionSuccess) {
                    let res = await NFTUnwrapAPI.unwrap(this.unwrapNFT);
                    let data = res.data;
                    this.unwrapLoading = false;
                    if(data.success && data.data) {
                        // 监听查询未读通知数量
                        this.$bus.emit('emitGetUnreadCount', null);
                        // 跳转到 Profile
                        window.open('/profile/' + this.user.username, '_self');
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                } else {
                    // 已签名但还未发送合约交易，发送解封交易
                    this.sendUnwrapTransaction();
                }
            } else {
                // 调起钱包签名
                if(this.walletType) {
                    this.$refs[this.walletType].signTypedData(this.nft.blockchain, this.nft.tokenId);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    this.unwrapLoading = false;
                }
            }
        },
        // 签名成功
        signSuccess(signature) {
            console.log("Sign Success!");
            this.signature = signature;
            switch (this.unwrapStatus) {
                case 'REQUESTED':
                    // 请求解封 NFT
                    this.requestUnwrap();
                    break;
                case 'SIGNED':
                    // 签名解封 NFT
                    this.signUnwrap();
                    break;
                case 'UNWRAPPED':
                    // 发送解封交易
                    this.sendUnwrapTransaction();
                    break;
                default:
                    break;
            }
        },
        // 签名错误
        signError(signature){
            this.unwrapLoading = false;
        },
        // 发送解封交易
        async sendUnwrapTransaction() {
            this.unwrapLoading = true;
            // 编码参数列表
            try {
                // 分割签名
                let sig = Ethers.utils.splitSignature(this.signature);
                // 合约方法
                let method  = this.web3.eth.abi.encodeFunctionSignature('unwrap(uint256,uint8,bytes32,bytes32)');
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameters(['uint256', 'uint8', 'bytes32', 'bytes32'], [this.nft.tokenId, sig.v, sig.r, sig.s]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.currentBlockchain.unNFTContract, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.unwrapLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.unwrapLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            // 交易成功
            if(success) {
                this.unwrapTransactionSuccess = true;
                // 更新参数
                this.unwrapNFT.nftId = this.id;
                this.unwrapNFT.blockchain = this.nft.blockchain;
                this.unwrapNFT.transactionHash = transactionHash;
                this.unwrapNFT.signature = this.signature;
                // 查询交易收据
                this.getTransactionReceipt();
                // 直接解封 NFT
                this.unwrap();
            } else {
                this.unwrapTransactionSuccess = false;
                // 加载完成
                this.unwrapLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt(){
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_UNWRAP",
                    blockchain: this.unwrapNFT.blockchain,
                    transactionHash: this.unwrapNFT.transactionHash
                }
                this.$bus.emit('emitGetTransactionReceipt', params);
            }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>