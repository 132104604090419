<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-2 text-primaryGrey">
                    <h4>Featured In</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 ml-5 r-m-l-0 mt-1 mb-5"></v-divider>
                <v-card class="ml-2 mt-16" elevation="0" color="transparent">
                    
                </v-card>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>