<template>
	<div class="align-center bg-white01 pb-16">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" rounded="0" max-width="1408" class="mx-auto pt-8">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card elevation="0" rounded="0" color="transparent">
                            <v-row no-gutters >
                                <v-col cols="12" class="r-m-l-0" style="display:flex; justify-content:space-between;">
                                    <h2 class="body-h4 text-primaryGrey">
                                       EXPLORE CRYPTOS
                                    </h2>
                                    <!-- <v-icon class="text-primaryGrey" icon="mdi mdi-magnify" size="small" @click="goToSearch"></v-icon> -->
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <!-- 基本信息 -->
                                <v-col cols="12" lg="12" class="r-m-l-0 text-grey04">
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="6" sm="6" class="mb-8">
                                            <div>
                                                <h1 class="ml-n1" :class="responsive == 'pc' ? 'hero-h3' :'mobile-h2'">NO COMMISSIONS</h1>
                                                <h6 class="mt-3 body-p">Individual Sellers Set Their Own Shared Rewards Rates.</h6>
                                            </div>
                                            <v-col cols="12" lg="12" class="mt-1 pl-0" v-if="$config.env != 'prod'">
                                                <div :class="responsive == 'pc' ? '' : 'mt-8 text-left'">
                                                    <!-- 测试代币水龙头 -->
                                                    <TestTokenFaucet class="mt-2"></TestTokenFaucet>
                                                    <div class="mt-2">
                                                        <span class="gradient-underline-hover">
                                                            <a href="https://lottery.untrading.org" aria-label="Tutorial" target="_blank" class="body-p gradient-text-hover del-underline">
                                                                <span>Lottery</span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div class="mt-2">
                                                        <span class="gradient-underline-hover">
                                                            <a href="https://docs.untrading.org/tutorials/how-to-buy-and-sell-uncryptos-on-untrading.org-testnet" aria-label="Tutorial" target="_blank" class="body-p gradient-text-hover del-underline">
                                                                <span>Tutorial</span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div class="mt-2">
                                                        <span class="gradient-underline-hover">
                                                            <a href="https://docs.untrading.org/zhong-wen-zi-liao/ru-he-shi-yong-untrading.org-ce-shi-wang" aria-label="中文教程" target="_blank" class="body-p gradient-text-hover del-underline">
                                                                <span>中文教程</span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-col>
                                        <v-col cols="12" lg="6" sm="6" :align="responsive == 'pc' ? 'end' :'start'">
                                            <TotalValueLocked  class="mb-5"></TotalValueLocked>
                                            <TotalNFRPaid></TotalNFRPaid></v-col>
                                    </v-row>
                                </v-col>
                                
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row no-gutters class="r-p-r-0" :class="responsive == 'pc' ? '' : 'mt-16'">
                    <v-col cols="12">
                        <v-row no-gutters justify="end">
                            <v-col cols="12">
                                <div>
                                    <!-- 区块链 -->
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                                <v-icon aria-label="mdi-menu-down" end icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="changeBlockchainCondition(null)">
                                                <v-list-item-title>
                                                    <Blockchain text all-chains></Blockchain>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                                <v-list-item-title>
                                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- 排序 -->
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn  aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                                {{ sortCondition }}
                                                <v-icon aria-label="mdi-menu-down" end icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mb-8 r-m-x-0"></v-divider>
                <div v-if="loading">
                    <SkeletonUnCryptos></SkeletonUnCryptos>
                </div>
                <div v-if="!loading && cryptoWrappedSummaries.length == 0">
                    <NoDataAvailable></NoDataAvailable>
                </div>
                <div v-else v-for="(summary, i) in cryptoWrappedSummaries" :key="summary.currency" class="mb-16 pb-16">
                    <!-- 摘要信息 -->
                    <v-card max-width="1440" elevation="0" rounded="0" color="transparent" align="left" class="pb-7 mx-auto">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card elevation="0" rounded="0" color="transparent">
                                    <v-row no-gutters class="pb-8">
                                        <v-col cols="12">
                                            <h1 class="text-primary mb-2" :class="responsive == 'pc' ? 'crypto-number' : 'mobile-h1'">{{ i + 1 }}</h1>
                                            <h1 class="gradient-underline-hover" :class="responsive == 'pc' ? 'sub-page-h1' : 'body-text-btn'">
                                                <Blockchain :blockchain="summary.blockchain" size="24"></Blockchain>
                                                <a :href="`${$tools.getBlockchainExplorerUrl(summary.blockchain)}/address/${summary.address}`" :aria-label="summary.currency"
                                                    class="del-underline text-primaryGrey gradient-text-hover" :class="summary.blockchain ? 'pointer' : 'pointer-none'" target="_blank">
                                                    <span>{{ summary.name }} ({{ summary.currency }})</span>
                                                </a>
                                            </h1>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters >
                                        <v-col cols="4">
                                            <h4 class="body-h4 text-primaryGrey">
                                                {{ $tools.formatNumber(summary.totalCrypto, 0) }} Total
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- 表格 -->
                    <v-card max-width="1440" color="transparent" rounded="0" elevation="0" class="mx-auto">
                        <div v-for="cryptoSymbolSummary in summary.cryptoSymbolSummaries" :key="cryptoSymbolSummary.symbol" class="mb-16">
                            <div class="mb-8 r-m-x-0">
                                <v-divider></v-divider>
                                <v-row no-gutters class="mt-8 r-p-r-0">
                                    <v-col cols="6">
                                        <div class="text-primaryGrey body-h4">
                                            {{ cryptoSymbolSummary.symbol }}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="float-right text-primaryGrey body-p">
                                            {{ $tools.formatNumber(cryptoSymbolSummary.total, 0) }} results
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- 数据表格 -->
                            <v-data-table-server width="100%" fluid :headers="headers" :items="cryptoSymbolSummary.results" :items-length="cryptoSymbolSummary.total" :page="cryptoSymbolSummary.page" :items-per-page="cryptoSymbolSummary.size" :loading="cryptoSymbolSummary.loading" item-value="name" 
                                class="body-p text-primaryGrey" color="transparent" density="compact" hover
                                @update:page="updatePage($event, cryptoSymbolSummary)" @update:itemsPerPage="updateSize($event, cryptoSymbolSummary)">
                                <template v-slot:headers>
                                    <tr class="body-h5">
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-50'">ID</td>
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Originator</td>
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Owner</td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">Total R
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unCrypto.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">FR
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">Gen
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">OR
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">Flow
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The Length of Flow is calculated as the sum of all prior owners along with the present holder of this token, starting from the time it was initially minted.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-250'">Price</td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-250'">Size</td>
                                    </tr>
                                    <tr>
                                        <td colspan="10" class="px-0 text-center">
                                            <v-divider color="primaryGrey" :thickness="3"></v-divider>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-hover>
                                        <template v-slot:default="{ isHovering, props }">
                                            <tr class="pointer" v-bind="props" :class="isHovering ? 'bg-primary text-white' : ''" @click="goToUnCrypto(item)">
                                                <td class="px-0">{{ item.tokenId }}</td>
                                                <td class="px-0">
                                                    <UserAvatar :avatar="item.user?.avatar" :username="item.user?.username" :name="item.user?.name" :text-color="isHovering ? 'text-white' : ''"  :underline-color="isHovering ? 'white-underline-hover' : ''" :size="24"></UserAvatar>
                                                </td>
                                                <td class="px-0">
                                                    <UserAvatar :avatar="item.owner?.avatar" :username="item.owner?.username" :name="item.owner?.name" :text-color="isHovering ? 'text-white' : ''" :underline-color="isHovering ? 'white-underline-hover' : ''" :size="24"></UserAvatar>
                                                </td>
                                                <td class="px-0 text-right">{{ item.totalRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ 100 - item.originatorRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ item.numberOfGenerations }}</td>
                                                <td class="px-0 text-right">{{ item.originatorRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ item.maxOwnerNo }}</td>
                                                <td class="px-0 text-right">{{ (item.listedSize > 0 ? $tools.formatNumber(item.limitPrice, item.cryptoQuote?.sizeDecimals) : '--') + ' ' + item.quoteCurrency }}</td>
                                                <td class="px-0 text-right">{{ item.listedSize > 0  ? $tools.formatNumber(item.listedSize, item.cryptoWrapped?.sizeDecimals) : $tools.formatNumber(item.currentSize, item.cryptoWrapped?.sizeDecimals) }}</td>
                                            </tr>
                                        </template>
                                    </v-hover>
                                </template>
                                <template v-slot:loading>
                                    <!-- <tr>
                                        <td colspan="10">
                                            <div class="text-center">
                                                <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="cryptoSymbolSummary.loading"></v-progress-circular>
                                            </div>
                                        </td>
                                    </tr> -->
                                    <v-skeleton-loader :type="`table-row@${cryptoSymbolSummary.size}`" color="transparent"></v-skeleton-loader>
                                </template>
                                <template v-slot:no-data>
                                    <NoDataAvailable></NoDataAvailable>
                                </template>
                                <template v-slot:tfoot>
                                    <tr>
                                        <td colspan="10" class="px-0">
                                            <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:bottom>
                                    <v-data-table-footer key="title" :items-per-page-options="perPageOptions"></v-data-table-footer>
                                </template>
                            </v-data-table-server>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SkeletonUnCryptos from '@/components/util/skeleton/SkeletonUnCryptos.vue';
import TotalNFRPaid from '@/components/crypto/TotalNFRPaid.vue';
import TotalValueLocked from '@/components/crypto/TotalValueLocked.vue';
import TestTokenFaucet from '@/components/faucet/TestTokenFaucet.vue';
import CryptoAPI from '@/api/crypto.js';
export default {
    data(){
        return {
            // 产品/封装的加密货币资产
            product: this.$route.query?.product,
            // 页面离开时上一个产品
            lastProduct: null,
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Price', 'Lowest Price', 'Highest Views', 'Lowest Views'],
            // 默认排序条件
            sortCondition: 'Lowest Price',
            // 加载中
            loading: false,
            // 表头
            headers: [{},{},{},{},{},{},{},{},{},{}],
            // 每页数量配置
            perPageOptions: [
                { title: '10', value: 10 },
                { title: '20', value: 20 },
                { title: '50', value: 50 },
                { title: '100', value: 100 }
            ],
            // 加密货币封装资产摘要信息
            cryptoWrappedSummaries: [],
        }
    },
    components: { SkeletonUnCryptos, TotalNFRPaid, TotalValueLocked, TestTokenFaucet },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain', 'responsive']),
    },
    watch: {
        $route(to, from){
            this.product = this.$route.query?.product;
        },
        product: {
            handler(n, o) {
                // 查询加密货币摘要信息
                this.getCryptoSummaries();
            },
            immediate: true
        }
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
            // 刷新
            this.refresh();
        },
        // 改变排序条件
        changeSortCondition(sortCondition) {
            // 改变条件
            this.sortCondition = sortCondition;
            // 刷新
            this.refresh();
        },
        // 刷新
        refresh() {
            // 查询加密货币摘要信息
            this.getCryptoSummaries();
        },
        // 查询加密货币摘要信息
        async getCryptoSummaries() {
            this.loading = true;
            this.cryptoWrappedSummaries = [];
            let res = await CryptoAPI.getCryptoSummaries(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.cryptoWrappedSummaries = data.data;
                // 有产品时过滤产品
                if(this.product) {
                    this.cryptoWrappedSummaries = this.cryptoWrappedSummaries.filter(summary => summary.currency?.toLowerCase() == this.product?.toLowerCase());
                }
                this.cryptoWrappedSummaries.forEach(summary => {
                    summary.cryptoSymbolSummaries.forEach(cryptoSymbolSummary => {
                        cryptoSymbolSummary.blockchain = summary.blockchain;
                        cryptoSymbolSummary.loading = false;
                        cryptoSymbolSummary.total = 0;
                        cryptoSymbolSummary.page = 1;
                        cryptoSymbolSummary.size = 10;
                        cryptoSymbolSummary.results = [];
                        // 分页查询加密货币列表
                        this.getCryptosByPage(cryptoSymbolSummary);
                    });
                });
            }
            this.loading = false;
        },
        // 分页查询加密货币列表
        async getCryptosByPage(cryptoSymbolSummary) {
            cryptoSymbolSummary.loading = true;
            // cryptoSymbolSummary.total = 0;
            cryptoSymbolSummary.results = [];
            let res = await CryptoAPI.getCryptosByPage(cryptoSymbolSummary.page, cryptoSymbolSummary.size, this.sortCondition, cryptoSymbolSummary.blockchain, cryptoSymbolSummary.wrappedCurrency, cryptoSymbolSummary.quoteCurrency);
            let data = res.data;
            if(data.success) {
                cryptoSymbolSummary.total = data.data.total;
                cryptoSymbolSummary.results = data.data.records;
            }
            cryptoSymbolSummary.loading = false;
        },
        // 更新页码
        updatePage(page, cryptoSymbolSummary) {
            // 更新参数
            cryptoSymbolSummary.page = page;
            // 分页查询加密货币列表
            this.getCryptosByPage(cryptoSymbolSummary);
        },
        // 更新每页数量
        updateSize(size, cryptoSymbolSummary) {
            // 更新参数
            cryptoSymbolSummary.page = 1;
            cryptoSymbolSummary.size = size;
            // 分页查询加密货币列表
            this.getCryptosByPage(cryptoSymbolSummary);
        },
        // 跳转到加密货币详情页
        goToUnCrypto(unCrypto) {
            this.$router.push(`/uncrypto/${unCrypto.wrappedCurrency.toLowerCase()}/${unCrypto.id}`);
        },
        //跳转搜索页面
        goToSearch(){
            this.$router.push('/uncrypto/mobileSearch');
        },
        // 监听 WETH 水龙头弹窗
        watchWETHFaucetDialog(show) {
            this.WETHFaucetDialog = show;
        },
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}

/* .v-data-table > .v-table__wrapper > table > .v-data-table__tbody > .pointer:hover{
  background: #03DAC5 !important;
} */
</style>