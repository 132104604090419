import axios from '@/config/api.request'

export default {
    // 根据钱包地址分页查询所有的 NFTs
    getNFTsByWallet(blockchain, wallet, cursor) {
        return axios.request({
            url: '/moralis/wallet/nfts',
            method: 'get',
            params: { 
                blockchain: blockchain,
                wallet: wallet,
                cursor: cursor
            }
        })
    },
    // 根据钱包地址分页查询所有的 unNFTs
    getUnNFTsByWallet(blockchain, wallet, cursor, unimported) {
        return axios.request({
            url: '/moralis/wallet/unnfts',
            method: 'get',
            params: { 
                blockchain: blockchain,
                wallet: wallet,
                cursor: cursor,
                unimported: unimported
            }
        })
    },
}