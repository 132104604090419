import store from '@/store'

const state = () => ({
	// 语言环境
	locale: 'en-US',
	// 黑色主题，1是true，0是false
	darkTheme: 1,
    // 钱包地址
	walletAddress: null,
    // 钱包类型
    walletType: null,
    // 认证令牌
	token: null,
	// 用户对象
	user: {},
})

const getters = {
	locale: state => {
		let locale = localStorage.getItem('untrading-locale');
		if (locale != null) {
			state.locale = locale;
		} else {
			localStorage.setItem('untrading-locale', 'en-US');
			state.locale = 'en-US';
		}
		return state.locale;
	},
	darkTheme: state => {
		let darkTheme = localStorage.getItem('untrading-darkTheme');
		if (darkTheme != null) {
			state.darkTheme = darkTheme;
		} else {
            // let responsive = store.getters.responsive;
            // darkTheme = (responsive == 'pc') ? 0 : 1;
			// 默认设置为黑暗主题
            darkTheme = 1;
			localStorage.setItem('untrading-darkTheme', darkTheme);
			state.darkTheme = darkTheme;
		}
		return state.darkTheme;
	},
    walletAddress: state => state.walletAddress,
    walletType: state => state.walletType,
    token: state => {
		if (state.token) {
			return state.token;
		}
		state.token = localStorage.getItem('untrading-token');
		return state.token;
	},
	user: state => {
		if (state.user) {
			return state.user;
		}
		state.user = JSON.parse(localStorage.getItem('untrading-user'));
		return state.user;
	},
}

const actions = {
	localeHandler(context, payload){
		context.commit('localeHandler', payload);
	},
	darkThemeHandler(context, payload){
		context.commit('darkThemeHandler', payload);
	},
    walletAddressHandler(context, payload){
        context.commit('walletAddressHandler', payload);
    },
    walletTypeHandler(context, payload){
        context.commit('walletTypeHandler', payload);
    },
    tokenHandler(context, payload){
		context.commit('tokenHandler', payload);
	},
	userHandler(context, payload){
		context.commit('userHandler', payload);
	},
}

const mutations = {
	localeHandler(state, locale){
		localStorage.setItem("untrading-locale", locale);
		state.locale = locale;
	},
	darkThemeHandler(state, darkTheme){
		localStorage.setItem("untrading-darkTheme", darkTheme);
		state.darkTheme = darkTheme;
	},
    walletAddressHandler(state, walletAddress){
		state.walletAddress = walletAddress;
	},
    walletTypeHandler(state, walletType){
		state.walletType = walletType;
	},
    tokenHandler(state, token){
		if(token == null){
            // 删除 token
			localStorage.removeItem('untrading-token');
            // 删除用户信息
            localStorage.removeItem('untrading-user');
            // 清除钱包信息
            Object.keys(localStorage)
                .filter(key => key.startsWith("wagmi") || key.startsWith("wc@2"))
                .forEach(key => localStorage.removeItem(key));
		} else {
			localStorage.setItem('untrading-token', token);
		}
		state.token = token;
	},
	userHandler(state, user){
		localStorage.setItem('untrading-user', JSON.stringify(user));
		state.user = user;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}