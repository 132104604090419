<template>
	<div>
        <span class="pointer ml-1" v-show="showInstallApp">
            <v-menu open-on-hover location="top">
                <template v-slot:activator="{ props }">
                    <v-icon color="primary" v-bind="props" id="installApp" v-if="responsive == 'pc'" size="28" class="mr-8" icon="mdi:mdi-monitor-arrow-down-variant" aria-label="monitor-arrow-down-variant"></v-icon>
                    <v-icon color="primary" v-bind="props" id="installApp"  v-else size="30" icon="mdi:mdi-cellphone-arrow-down-variant" aria-label="mdi-cellphone-arrow-down-variant"></v-icon>
                </template>
                <v-card class="pa-3 text-primaryGrey" rounded="0">Install App</v-card>
            </v-menu>
        </span>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            showInstallApp: false
        }
    },
    props: {
        
    },
    components: {  },
    created(){
        
    },
    mounted(){
        // 检测是否可以安装
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
            deferredPrompt = e;
            this.showInstallApp = true;
        });
        // 点击按钮事件
        const installApp = document.getElementById('installApp');
        installApp.addEventListener('click', async () => {
            if (deferredPrompt !== null) {
                deferredPrompt.prompt();
                // outcome: accepted / dismissed
                const { outcome } = await deferredPrompt.userChoice;
                if (outcome === 'accepted') {
                    deferredPrompt = null;
                }
            }
        });
    },
    computed: {
        ...mapGetters(['responsive'])
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>