import axios from '@/config/api.request'

export default {
    // 根据钱包地址获取王国团体
    getKingdomGroup(wallet) {
        return axios.request({
            url: '/kingdom/group',
            method: 'get',
            params: { wallet }
        })
    },
}