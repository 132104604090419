<template>
	<div>
        <v-card elevation="12" color="black" rounded="0" class="text-primaryGrey ma-4">
            <MediaFile :nftId="nft.id"></MediaFile>
            <v-row no-gutters class="px-4 py-4">
                <v-col cols="12">
                    <div class="body-text-btn">
                        nFR Parameters
                    </div>
                </v-col>
                <v-divider class="my-1"></v-divider>
                <v-col cols="12" sm="12" justify="between" class="body-p-small">
                    <v-row no-gutters class="text-primaryGrey">
                        <v-col cols="12" class="d-flex justify-space-between">
                            <div class="form-text">Total Reward (f)</div>
                            <div>{{ nft.totalRewardRatio }}%</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">OR Ratio (a)</div>
                            <div>{{ nft.originatorRewardRatio }}%</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">FR Ratio (r)</div>
                            <div>{{ 100 - nft.originatorRewardRatio }}%</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">OR Split</div>
                            <div>100/0</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text"># of Gen</div>
                            <div>{{ nft.numberOfGenerations }}</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text"><em>g</em></div>
                            <div>{{ $tools.calcCommonRatio(nft.numberOfGenerations) }}</div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<script>
import NFTAPI from '@/api/nft.js';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            // NFT 
            nft: {
                user: {},
                owner: {},
                set: {}
            },
        }
    },
    // NFT 主键
    props: {
        nftId: {
            type: String,
            default: null
        }
    },
    components: { MediaFile },
    created(){

    },
    mounted(){
        
    },
    computed: {

    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询 NFT
                this.getSimpleNFTById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据 NFT 主键查询 NFT
        async getSimpleNFTById() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.getSimpleNFTById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
    }
}
</script>
<style scoped>

</style>