import axios from '@/config/api.request'

export default {
    // 查询全部类别
    getCategories() {
        return axios.request({
            url: '/collection/category/categories',
            method: 'get'
        })
    }
}