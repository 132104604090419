import axios from '@/config/api.request'

export default {
    // 更新 NFT 的标签
    updateTagNFT(data) {
        return axios.request({
            url: '/nft/tag/nft/update',
            method: 'post',
            data: data
        })
    },
}