<template>
	<div class="mt-2">
        <!-- 区块链 -->
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn aria-label="menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none ml-5 r-m-l-2" height="100%" v-bind="props">
                    <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                    <v-icon end icon="mdi:mdi-menu-down" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="changeBlockchainCondition(null)">
                    <v-list-item-title>
                        <Blockchain text all-chains></Blockchain>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                    <v-list-item-title>
                        <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-divider class="ml-5 r-m-l-2" width="250" :class="responsive == 'pc' ? 'w-250' :'w-345'"></v-divider>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain','responsive']),
    },
    watch:{

    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
    }
}
</script>
<style scoped>

</style>