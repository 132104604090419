<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-5 r-m-l-2 text-primaryGrey">
                    <h4>Trending Originators</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 ml-5 r-m-l-0 mt-1 mb-5"></v-divider>
                <v-card class="ml-5 r-m-l-2" elevation="0"  v-for="originator in originators" :key="originator.id" color="transparent">
                    <div class="mt-5">
                        <UserAvatar :avatar="originator.avatar" :username="originator.username" :name="originator.name"></UserAvatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import UserAPI from '@/api/user.js';
export default {
    data(){
        return {
            originators: []
        }
    },
    components: {  },
    created(){
        this.getTrendingArtists();
    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 查询流行的艺术家列表
        async getTrendingArtists() {
            let res = await UserAPI.getTrendingArtists();
            let data = res.data;
            if(data.success) {
                this.originators = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>