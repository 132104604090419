<template>
	<div>
        <v-snackbar v-if="responsive == 'pc'" max-height="48" v-model="cookiesDialog" bottom :light="darkTheme==0" :dark="darkTheme==1" :rounded="0" timeout="-1" min-width="101%">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" id="cookie-notification">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <div id="scrolling-text" class="body-p text-primaryGrey" @mouseover="pauseScroll()" @mouseout="resumeScroll()">
                                    We value your involvement at untrading.org. However, we strongly oppose wash trading and manipulation. Please review our Terms of Service: 
                                    <span class='gradient-underline-hover gradient-underline'>
                                        <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="del-underline pointer">https://docs.untrading.org/terms-of-service</a>.
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-snackbar>
        <v-snackbar class="mr-12" v-if="responsive == 'mobile'" max-height="130" v-model="cookiesDialog" bottom :light="darkTheme==0" :dark="darkTheme==1" :rounded="0" timeout="-1">
            <div id="cookie-notification">
                <div id="scrolling-text" class="fs-14 text-primaryGrey" @mouseover="pauseScroll()" @mouseout="resumeScroll()">
                    We value your involvement at untrading.org. However, we strongly oppose wash trading and manipulation. Please review our Terms of Service: 
                    <span class='gradient-underline-hover gradient-underline'>
                        <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="del-underline pointer">https://docs.untrading.org/terms-of-service</a>.
                    </span>
                </div>
            </div>
        </v-snackbar>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            cookiesDialog: true,
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme", "locale","responsive"])
    },
    watch:{

    },
    methods: {
        // 允许
        allowCookies() {
            localStorage.setItem('untrading-cookies', 1);
            this.cookiesDialog = false;
        },
        pauseScroll() {
            document.getElementById('scrolling-text').style.animationPlayState = 'paused';
        },
        resumeScroll() {
            document.getElementById('scrolling-text').style.animationPlayState = 'running';
        }
    }
}
</script>
<style scoped>
#scrolling-text {
    
    white-space: nowrap;
    overflow: hidden;
    animation: scrollText 20s linear infinite;
    margin-left: 10px;
    margin-right: 20px;
    padding-right: 20px;
}

@keyframes scrollText {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
</style>