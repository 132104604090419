<template>
	<div>
        <!-- ----------------- 公共图像信息 ------------------ -->
        <v-card rounded="0" color="black" :class="responsive == 'pc' ? 'elevation-12 ' : 'elevation-6'">
            <MediaFile :nftId="nftId" overlay></MediaFile>
            <div>
                <v-row no-gutters class="ma-4">
                    <v-col cols="6" class="pr-4">
                        <div class="body-h5 mb-2 text-primaryGrey">
                            Originator
                        </div>
                        <div>
                            <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="body-h5 mb-2 text-primaryGrey">
                            Owner
                        </div>
                        <div>
                            <OwnerAvatar :avatar="nft.owner.avatar" :username="nft.owner.username" :name="nft.owner.name"></OwnerAvatar>
                        </div>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end" v-if="showDetails">
                        <v-btn color="black" variant="plain" size="32" class="text-primaryGrey" aria-label="Chevron up" :icon="show ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'" @click="show = !show"></v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <div v-if="!show" class="d-flex justify-space-between mx-4 pb-4">
                    <!-- 价格 -->
                    <div>
                        <div class="body-h5 text-primaryGrey">
                            {{ nft.price > 0 ? 'Current price' : 'Unlisted' }}
                        </div>
                        <div class="body-text-btn mt-6 text-primaryGrey">
                            {{ nft.price > 0 ? $tools.formatNumber(nft.price) : '--' }} {{ nft.paymentToken }}
                        </div>
                    </div>
                    <!-- unwrap 请求 -->
                    <div class="d-flex align-end">
                        <v-menu open-on-hover location="top" v-if="!getUnwrapSignatureLoading && currentUnwrapSignature.status != null">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" aria-label="mdi-account-lock-open-outline" icon="mdi:mdi-account-lock-open-outline" class="text-primaryGrey pointer" size="26"></v-icon>
                            </template>
                            <v-card class="pa-3 text-primaryGrey body-p" rounded="0">
                                <span v-unwrap-signature-status="currentUnwrapSignature.status"></span>
                            </v-card>
                        </v-menu>
                    </div>
                </div>
            </div>
            <!-- ----------------- 展开信息 ------------------ -->
            <v-expand-transition>
                <v-row v-show="show" class="ma-4">
                    <div class="body-text-btn text-primaryGrey">
                        Non-fungible Future Rewards (nFR) Parameters
                    </div>
                    <v-col cols="12" class="my-8 px-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <div class="form-text text-primaryGrey">Total Reward
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }" >
                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.totalReward') }} -->
                                            The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                        </v-card>
                                    </v-menu>
                                </div>
                                <div class="my-2 body-p-small text-primaryGrey">{{ nft.totalRewardRatio }}%</div>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" class="mt-8">
                                <div class="form-text text-primaryGrey">Future Rewards (FR)
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }" >
                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.futureRewards') }} -->
                                            Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                        </v-card>
                                    </v-menu>
                                </div>
                                <div class="my-2 body-p-small text-primaryGrey">{{ 100 - nft.originatorRewardRatio }}%</div>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" class="mt-8">
                                <div class="form-text text-primaryGrey"># of generations
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }" >
                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.ofGenerations') }} -->
                                            The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                        </v-card>
                                    </v-menu>
                                </div>
                                <div class="my-2 body-p-small text-primaryGrey">{{ nft.numberOfGenerations }}</div>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" class="mt-8">
                                <div class="form-text text-primaryGrey">Originator Reward (OR)
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }" >
                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.originatorReward') }} -->
                                            The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                        </v-card>
                                    </v-menu>
                                </div>
                                <div class="my-2 body-p-small text-primaryGrey">{{ nft.originatorRewardRatio }}%</div>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!-- ----------------- 在我自己的 Profile 页面时候显示 OR Split 和 Common ratio，公众页面不显示 ------------------ -->
                        <v-row no-gutters class="mt-8" v-show="showCommonRatio">
                            <v-col cols="12">
                                <div class="form-text text-primaryGrey">OR Split</div>
                                <div class="my-2 body-p-small text-primaryGrey">100/0</div>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" class="mt-8">
                                <div class="form-text text-primaryGrey">Common ratio</div>
                                <div class="my-2 body-p-small text-primaryGrey">{{ $tools.calcCommonRatio(nft.numberOfGenerations) }}</div>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="px-0 d-flex justify-space-between">
                        <div>
                            <div class="body-h5 text-primaryGrey mt-2">
                                {{ nft.price > 0 ? 'Current price' : 'Unlisted' }}
                            </div>
                            <div class="body-text-btn text-primaryGrey mt-6">
                                {{ nft.price > 0 ? $tools.formatNumber(nft.price) : '--' }} {{ nft.paymentToken }}
                            </div>
                        </div>
                        <div class="mt-16 pt-1 mr-2 d-flex align-center">
                            <!-- unwrap 请求 -->
                            <v-menu open-on-hover location="top" v-if="!getUnwrapSignatureLoading && currentUnwrapSignature.status != null">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" aria-label="mdi-account-lock-open-outline" icon="mdi:mdi-account-lock-open-outline" class="text-primaryGrey mr-5 pointer" size="24"></v-icon>
                                </template>
                                <v-card class="pa-3 text-primaryGrey body-p" rounded="0">
                                    <span v-unwrap-signature-status="currentUnwrapSignature.status"></span>
                                </v-card>
                            </v-menu>
                            <!-- 菜单 -->
                            <UnNftMenu :nftId="nftId" :blockchain="nft.blockchain" :isOriginator="isOriginator" :isOwner="isOwner" :isListed="nft.price > 0" 
                                :hidden="nft.hidden" :wrap="nft.contractFunction == 'WRAP'"
                                :hasRequestUnwrapPermission="nft.permission.requestUnwrap" 
                                :hasSignUnwrapPermission="nft.permission.signUnwrap"
                                :hasConfirmUnwrapPermission="nft.permission.confirmUnwrap"
                            ></UnNftMenu>
                        </div>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import NFTAPI from '@/api/nft.js'
import NFTUnwrapAPI from '@/api/nft-unwrap.js';
import MediaFile from "@/components/media/MediaFile.vue";
import UnNftMenu from "@/components/nft/UnNftMenu.vue";
export default {
    data(){
        return {
            // 展开详情
            show: false,
            // 遮罩
            overlay: true,
            // NFT 信息
            nft: {
                user: {},
                owner: {},
                set: {},
                permission: {
                    requestUnwrap: false,
                    signUnwrap: false,
                    confirmUnwrap: false
                }
                // price: null,
                // lister: null,
                // isListed: false,
            },
            // 当前的区块链
            currentBlockchain: {},
            web3: {},
            // 查询 unwrap 签名加载中
            getUnwrapSignatureLoading: false,
            // 当前最新一条签名记录
            currentUnwrapSignature: {},
        }
    },
    props: {
        // NFT 主键
        nftId: { type: String, default: null },
        // 是否展示公比
        showCommonRatio: { type: Boolean, default: false },
        // 是否展示详情
        showDetails: { type: Boolean, default: true },
    },
    components: { MediaFile, UnNftMenu },
    created(){
        
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'blockchains']),
        // 我是发起人吗
        isOriginator(){
            return this.user && this.nft.userId == this.user.id;
        },
        // 我是拥有者吗
        isOwner(){
            return this.user && this.nft.ownerUserId == this.user.id;
        }
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询 NFT
                this.getNFTById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据 NFT 主键查询 NFT
        async getNFTById() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.getNFTById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 查询最新一条签名记录
                this.getUnwrapSignature();
                // 查询owner信息
                // this.ownerOf();
                // 查询上市信息
                // this.getListInfo();
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 查询owner信息
        async ownerOf() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let owner = await NFTContract.methods.ownerOf(this.nft.tokenId).call();
            if(owner) {
                this.isOwner = this.$tools.equalsIgnoreCase(owner, this.user.wallet);
            }
        },
        // 查询上市信息
        async getListInfo() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            // {0: '0', 1: '0x0000000000000000000000000000000000000000', 2: false}
            let listInfo = await NFTContract.methods.getListInfo(this.nft.tokenId).call();
            this.nft.price = this.web3.utils.fromWei(listInfo[0]);
            this.nft.lister = listInfo[1];
            this.nft.isListed = listInfo[2];
            this.$emit('receiveCurrentPrice', this.nft.price);
        },
        // 查询最新一条签名记录
        async getUnwrapSignature() {
            if(this.nft.contractFunction != 'WRAP') {
                return;
            }
            this.getUnwrapSignatureLoading = true;
            let res = await NFTUnwrapAPI.getUnwrapSignature(this.nftId);
            let data = res.data;
            if(data.success) {
                this.currentUnwrapSignature = data.data;
            }
            this.getUnwrapSignatureLoading = false;
        },
    }
}
</script>
<style scoped>

</style>



