import axios from '@/config/api.request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
    // 创建收藏夹
    create(data) {
        return axios.request({
            url: '/collection/create',
            method: 'post',
            data: data
        })
    },
    // 删除收藏夹
    delete(id) {
        return axios.request({
            url: '/collection/delete',
            method: 'delete',
            params: { id: id }
        })
    },
    // 根据主键查询收藏夹
    getCollectionById(id) {
        return axios.request({
            url: '/collection/collection',
            method: 'get',
            params: { id: id }
        })
    },
    // 根据用户名查询所有的收藏夹
    collections(username, sortBy, blockchain) {
        return axios.request({
            url: '/collection/collections',
            method: 'get',
            params: { 
                username: username,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 更新收藏夹头像图片
    updateLogo(data) {
        return axios.request({
            url: '/collection/logo',
            method: 'post',
            data: data
        })
    },
    // 更新收藏夹封面图片
    updateCover(data) {
        return axios.request({
            url: '/collection/cover',
            method: 'post',
            data: data
        })
    },
    // 分页查询收藏夹列表
    getCollectionsByPage(page, size, sortBy, blockchain) {
        return axios.request({
            url: '/collection/collections/page',
            method: 'get',
            params: { 
                page: page,
                size: size,
                sortBy: sortBy,
                blockchain: blockchain
            }
        })
    },
    // 更新收藏夹名称
    editName(data) {
        return axios.request({
            url: '/collection/name',
            method: 'post',
            data: data
        })
    },
    // 从收藏夹中移除 NFTs
    removeNftsFromCollection(nftIds) {
        return axios.request({
            url: '/collection/nfts/remove',
            method: 'get',
            params: { 
                nftIds: nftIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, { indices: false })
            }
        })
    },
    // 移动 NFT 到收藏夹
    moveNftToCollection(nftId, collectionId) {
        return axios.request({
            url: '/collection/nft/move',
            method: 'get',
            params: { 
                nftId: nftId,
                collectionId: collectionId
            }
        })
    },
    // 根据 NFT 主键查询收藏夹
    getCollectionByNftId(nftId) {
        return axios.request({
            url: '/collection/nft/collection',
            method: 'get',
            params: { 
                nftId: nftId
            }
        })
    }
}