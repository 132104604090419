import axios from '@/config/api.request'

export default {
    // 举报作弊
    reportCheating(data) {
        return axios.request({
            url: '/report/cheating',
            method: 'post',
            data: data
        })
    }
}