<template>
	<div>
       <v-row no-gutters>
        <v-card elevation="0" rounded="0" color="transparent">
            <v-row no-gutters>
                <v-col cols="12">
                    <h2 class="body-text-btn text-primaryGrey">
                        Filters
                    </h2>
                </v-col>
                <v-col cols="12" class="mt-4">
                        <!-- 区块链 -->
                        <v-select v-model="blockchain" :items="blockchains" class="body-p-small text-primaryGrey" label="Cryptos" density="compact" variant="outlined">
                    </v-select>
                </v-col>
                <v-col cols="12" class="mt-4">
                    <v-select v-model="symbol" :items="symbols" class="body-p-small text-primaryGrey"  density="compact" variant="outlined"></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <h2 class="body-text-btn text-primaryGrey">
                        Non-fungible Future Rewards (nFR) Parameters
                    </h2>
                </v-col>
                <v-col cols="12" class="pa-0 mt-8">
                    <h3 class="body-h4 text-primaryGrey">Total Reward
                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                            <template v-slot:activator="{ props }" >
                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                            </template>
                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                <!-- {{ $t('common.totalReward') }} -->
                                The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                            </v-card>
                        </v-menu>
                    </h3>
                    <div class="mt-4 px-1">
                        <v-range-slider v-model="totalReward" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="5" max="50" density="compact" hide-details thumb-label></v-range-slider>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-0 mt-6">
                    <h3 class="body-h4 text-primaryGrey">Future Rewards (FR)
                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                            <template v-slot:activator="{ props }" >
                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                            </template>
                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                <!-- {{ $t('common.futureRewards') }} -->
                                Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                            </v-card>
                        </v-menu>
                    </h3>
                    <div class="mt-4 px-1">
                        <v-range-slider v-model="futureReward" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="50" max="95" density="compact" hide-details thumb-label></v-range-slider>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-0 mt-6">
                    <h3 class="body-h4 text-primaryGrey"># of generations
                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                            <template v-slot:activator="{ props }" >
                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                            </template>
                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                <!-- {{ $t('common.ofGenerations') }} -->
                                The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                            </v-card>
                        </v-menu>
                    </h3>
                    <div class="mt-4 px-1">
                        <v-range-slider v-model="ofGeneration" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="5" max="20" density="compact" hide-details thumb-label></v-range-slider>
                    </div>
                </v-col>
            </v-row>
        </v-card>
       </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {
            blockchain: null,
            symbol: null,
            blockchains: ['BTC','ETH'],
            symbols: ['USDC','USDT'],
            totalReward:[5,50],
            futureReward:[50,95],
            ofGeneration:[5,20],
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{
        totalReward: {
            handler(newVal, oldVal) {
                console.log(newVal);
            },
            immediate: true
        }
    },
    methods: {

    }
}
</script>
<style scoped>

</style>