<template>
	<div>
        <v-card max-width="414" elevation="12" class="pb-8" :rounded="0">
            <MediaFile :nftId="nft.id" overlay></MediaFile>
            <v-row no-gutters class="px-8">
                <v-col cols="12" class="px-0 mt-10">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Originator
                            </div>
                            <div v-if="nft.user" class="u-w">
                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Owner
                            </div>
                            <div>
                                <OwnerAvatar :avatar="nft.owner.avatar" :username="nft.owner.username" :name="nft.owner.name"></OwnerAvatar>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <v-col cols="12" class="body-h5 text-primaryGrey">
                    Current price
                </v-col>
                <v-col cols="12" class="sidebar-h2 pt-3 text-primaryGrey">
                    {{ listInfo.isListed ? $tools.formatNumber(listInfo.price) : '--' }} {{ nft.paymentToken }}
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            // NFT 
            nft: {
                user: {},
                owner: {},
                set: {}
            },
        }
    },
    // NFT 主键，上市信息
    props: ['nftId', 'listInfo'],
    components: { MediaFile },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user']),
        // 是我
        isMe(){
            return this.nft.user && this.user && this.nft.user.id == this.user.id;
        }
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据主键查询 NFT
                this.getNFTById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询 NFT
        async getNFTById() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.getNFTById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
    }
}
</script>
<style scoped>

</style>