<template>
	<div>
        <v-container>
            <v-row no-gutters justify="center" align="center">
                <!--  -->
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>