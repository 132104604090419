<template>
	<div class="bg-black mt-16">
        <v-container  v-if="responsive == 'pc'">
            <v-row no-gutters class="pb-10" justify="center">
                <v-col cols="3" class="px-0">
                    <div class="pointer ml-n3">
                        <router-link to="/">
                            <div class="logo" v-show="this.darkTheme == 0" >
                                <logo type="footer" color="light" responsive="pc"></logo>
                            </div>
                            <div class="logo" v-show="this.darkTheme == 1" >
                                <logo type="footer" color="dark" responsive="pc"></logo>   
                            </div>
                        </router-link>
                    </div>
                    <div>
                        <div class="ending-zero-sum body-p-small mt-2">
                            Profit Continues after Selling™
                        </div> 
                        
                        <div class="mt-4 d-flex">
                            <div>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/570b4fc3-a9a5-4171-a286-c39ea70eb300/public" cover width="90" v-show="this.darkTheme == 0" class="mr-6"></v-img>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b74fc7e2-76e6-4886-dab5-4a915d291900/public" cover width="90" v-show="this.darkTheme == 1" class="mr-6"></v-img>
                            </div>
                            <div>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ad711f74-e320-42e4-a346-1473c1566f00/public" cover width="90" v-show="this.darkTheme == 0"></v-img>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8e64e336-d5ef-4fc4-48a7-3fa7eb9f8c00/public" cover width="90" v-show="this.darkTheme == 1"></v-img>
                            </div>
                        </div>
                        <div class="mt-1 d-flex">
                            <div>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/56abb3aa-9120-40b3-efdf-34300a8e1700/public" cover width="90" v-show="this.darkTheme == 0" class="mr-6"></v-img>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f2fd4477-500d-4a8e-a78c-a6b69610ab00/public" cover width="90" v-show="this.darkTheme == 1" class="mr-6"></v-img>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="9" class="pl-16 px-0 pt-10">
                    <v-row no-gutters class="pb-5" justify="space-between">
                        <v-col cols="3" justify="center" class="gradient-underline-hover body-p">
                            <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="text-primaryGrey del-underline">
                                <span class="gradient-text-hover">Terms of Service</span>
                            </a>
                        </v-col>
                        <v-col cols="3" justify="center" class="gradient-underline-hover body-p">
                            <a href="https://docs.untrading.org/privacy-policy" target="_blank" class="text-primaryGrey del-underline">
                                <span class="gradient-text-hover">Privacy Policy</span>
                            </a>
                        </v-col>
                        <v-col cols="3" justify="center" class="gradient-underline-hover body-p">
                            <Theme show-text size="22"></Theme>
                        </v-col>
                        <v-col cols="2">
                            <div class="d-flex">
                                <div>
                                    <a href="https://x.com/untradingOrg" target="_blank" class="del-underline">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public"  aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://t.me/untrading" target="_blank" class="black--text del-underline" aria-label="Telegram">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/88bd42d6-38b2-4775-ebdc-b5d28b927500/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="black--text del-underline" aria-label="Discord">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ac803eba-9354-40bd-3de0-1c6ab1cc6d00/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="body-p-small mt-8 text-primaryGrey px-0">
                    This site is operated by Untrading Technologies Pte Ltd. and services are provided through its wholly-owned subsidiaries (altogether, “Untrading”). Untrading does not give investment advice, endorse or make recommendations with respect to any assets or provide legal or tax advice. Neither Untrading nor any of its officers, directors, agents, or employees makes any warranty, express or implied, of any kind whatsoever related to the adequacy, accuracy, or completeness of any information on this site or the use of information on this site. This site contains external links to third-party content (content hosted on sites unaffiliated with Untrading). As such, Untrading makes no representations or endorsements whatsoever regarding any third-party content/sites that may be accessible directly or indirectly from this site. Untrading services are only directed toward the residents of jurisdictions where such services are permitted. Some services may be limited to residents of certain jurisdictions, and disclosures may be required in specific jurisdictions. Use of the site is subject to certain risks, including but not limited to those listed here. Assets offered on the platform are not insured by the FDIC, SIPC, or any similar organization. Users must conduct their own due diligence of any digital asset. Participating in digital assets is highly risky and may lead to total loss of funds. By using this site, you explicitly agree to our Terms of Service and Privacy Policy. Untrading and its employees, officers, directors, and affiliates may have interests in assets listed on this site and may also participate in certain offerings using the site (where permitted).
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import Media from '@/components/common/Media';
import Logo from '@/components/common/Logo';
import Theme from '@/components/common/Theme';
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: { Logo, Media, Theme },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme", "locale", "responsive"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.pl-90 {
  padding-left: 90px !important;
}
</style>