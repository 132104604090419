<template>
	<div>
        <h2 class="body-text-btn text-grey04">FAQs</h2>
        <div class="mt-5" v-for="(faq, i) in faqs" :key="`faq-${i}`">
            <div class="fw-b">Q: {{ faq.question }}</div>
            <div class="mt-1">
                A: <span v-html="faq.answer.content"></span>
            </div>
            <ol class="mt-1" v-if="faq.answer.orderedList">
                <li class="ml-8" v-for="(ol, j) in faq.answer.orderedList" :key="`ol-${j}`">
                    <span v-html="ol.content"></span>
                    <ul type="disc" class="mt-1" v-if="ol.unorderedList">
                        <li class="ml-4" v-for="(ul, k) in ol.unorderedList" :key="`ul-${k}`">
                            <span v-html="ul.content"></span>
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {
            faqs: [
                {
                    question: "How do I connect my MetaMask wallet?",
                    answer: {
                        content: "Click on the “Connect Wallet” button and follow the prompts to log in to your MetaMask wallet."
                    }
                },
                { 
                    question: "How do I switch my Metamask wallet to the Arbitrum One blockchain?",
                    answer: {
                        content: "To switch your Metamask wallet to the Arbitrum One blockchain, follow these steps:",
                        orderedList: [
                            { content: "Open your Metamask extension and click on the network dropdown menu at the top left." },
                            { content: "Click on “Select a network.”" },
                            { content: "Select “Arbitrum One;” if Arbitrum One is not available, click on “+ Add network” at the bottom." },
                            { content: "Under “Networks > Add a network,” click “Add” next to Arbitrum One." },
                            { content: "You can also manually add Arbitrum One to your wallet. Click on “Add a network manually” at the bottom of the page." },
                            { 
                                content: "Enter the following details:",
                                unorderedList: [
                                    { content: "Network Name: Arbitrum One" },
                                    { content: "New RPC URL: https://arb1.arbitrum.io/rpc" },
                                    { content: "Chain ID: 42161" },
                                    { content: "Symbol: ETH" },
                                    { content: "Block Explorer URL: https://arbiscan.io/" },
                                ]
                            },
                            { content: "Click “Save” to add the Arbitrum One network to your Metamask." },
                        ]
                    },
                },
                {
                    question: "What is KYC, and why do I need to complete it?",
                    answer: {
                        content: "KYC, or Know Your Customer, is a verification process to ensure the security of the platform and comply with financial regulations. You need to complete KYC to withdraw tokens."
                    }
                },
                {
                    question: "What is the process for completing the KYC verification?",
                    answer: {
                        content: "To complete the KYC verification, you need to visit our "
                        + "<span class='gradient-underline-hover gradient-underline'>"
                        + "<a href='https://forms.gle/cZAek51Xog2pg3kNA' class='text-black01' target='_blank'>KYC portal</a>"
                        + "</span>"
                        + " and follow the instructions provided. You will be required to submit certain personal information and identification documents. Once your submission is reviewed and approved, your KYC status will be updated to “Verified”."
                    }
                },
                {
                    question: "How can I see my vested and locked amounts?",
                    answer: {
                        content: "Once your wallet is connected, you will see a table displaying your Total Available, Vested Amount, Locked Amount, and Vesting Schedule."
                    }
                },
                {
                    question: "What bonuses are available for staking tokens?",
                    answer: {
                        content: "Bonuses vary based on the percentage of tokens you choose to stake or the time period you select for locking up your tokens. Please refer to the staking options for detailed bonus schedules."
                    }
                },
                {
                    question: "Can I claim tokens without staking them?",
                    answer: {
                        content: "Yes, you can claim 100% of your vested tokens without any bonus, or choose to stake a portion for additional rewards."
                    }
                },
                {
                    question: "Can I change my claim option after I have already made a claim?",
                    answer: {
                        content: "No, once you have made a claim using one of the available options, you cannot change your selection. Please carefully review and confirm your claim option before proceeding with the claim."
                    }
                },
                {
                    question: "What happens if I don't claim my vested tokens?",
                    answer: {
                        content: "If you don't claim your vested tokens within the specified claim period, the unclaimed tokens will be forfeited and redistributed as per the UN token distribution policy."
                    }
                },
                {
                    question: "What should I do if I encounter issues with the claims process?",
                    answer: {
                        content: "If you face any difficulties, please contact our support team through the link provided in the footer of the page."
                    }
                },
            ]
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>