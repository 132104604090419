<template>
	<div>
        <div v-show="show">
            <div class="text-primaryGrey mb-3">
                <v-row no-gutters>
                    <v-col cols="auto">
                        <div class="d-flex flex-wrap">
                            <div class="body-text-btn">
                                {{ paymentToken == null ? '--/--' : `${currency}/${paymentToken}` }}
                            </div>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="float-right body-text-btn" justify="end">
                        ${{ $tools.formatNumber(currentPrice) }}
                    </v-col>
                </v-row>
            </div>
            <div :id="id" style="width: 100%; position: relative;" :style="{ height: responsive == 'pc' ? '480px' : '240px' }"></div>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import IoBotsAPI from '@/api/ioBots.js'
export default {
    data(){
        return {
            id: `underlyingPriceLineChart-${+new Date()}`,
            // 是否展示图表
            show: true,
            priceLineChart: null,
            paymentToken: null,
            lastPrice: 0,
            currentPrice: 0,
            option : {
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: [],
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    top: '10%',
                    bottom: '10%',
                    containLabel: true
                },
                yAxis: {
                    type: 'log',
                    axisTick: {
                        show: false,
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        align: "right",
                        formatter: function (value, index) {
                            return value.toFixed(2);
                        }
                    },
                    scale: true
                },
                series: [{
                    data: [],
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        color: '#03DAC5',
                        width: 5
                    },
                }]
            }
        }
    },
    props: {
        // 货币
        currency: {
            type: String,
            default: null
        }
    },
    components: {  },
    created() {
      this.$nextTick(() => {
        this.initFN();
      });
    },
    mounted(){
        
    },
    computed: {

    },
    computed: {
        ...mapGetters(['responsive', 'darkTheme', 'prices'])
    },
    watch:{
        // darkTheme(val) {
        //     // this.option.series[0].lineStyle.color = this.darkTheme == 0 ? '#95c8ff':'#004080';
        //     // 查询货币价格历史图表
        //     this.getPriceHistories();
        // },
        currency: {
            async handler(newVal, oldVal) {
                // 查询货币价格历史图表
                await this.getPriceHistories();
            },
            immediate: false
        },
    },
    methods: {
      // 初始化
      initFN() {
        var that = this;
        // 渲染图表
        this.priceLineChart = this.$echarts.init(document.getElementById(this.id));
        // 填充数据
        this.priceLineChart.setOption(this.option, true);
        this.priceLineChart.on('click', function(series) {

        })
      },
      // 查询货币价格历史图表
      async getPriceHistories() {
        if (this.currency == null) {
            return;
        }
        let res = await IoBotsAPI.getPriceHistories(this.currency);
        let data = res.data;
        if (data.code == 200) {
            let result = data.data;
            this.paymentToken = result.paymentToken;
            let currentDates = [];
            let currentValues = [];
            for (let i = 0, len = result.date.length; i < len; i++) {
                let isHalfYearEnd = this.$tools.isHalfYearEnd(result.date[i]);
                if(isHalfYearEnd) {
                    currentDates.push(result.date[i]);
                    currentValues.push(result.data[i]);
                }
            }
            // 再放入最后一天的数据
            currentDates.push(result.date[result.date.length - 1]);
            this.lastPrice = result.data[result.data.length - 1];
            currentValues.push(this.lastPrice);
            // 在放入当前实时数据
            this.currentPrice = this.prices.filter(P => P.currency == this.currency)[0]?.price || 0;
            if (this.currentPrice != 0) {
                let date = new Date();
                let month = (date.getMonth() + 1).toString().padStart(2, '0');
                let day = date.getDate().toString().padStart(2, '0');
                let todayDate = `${date.getFullYear()}-${month}-${day}`;
                currentDates.push(todayDate);
                currentValues.push(this.currentPrice);
            }
            this.option.xAxis.data = currentDates;
            this.option.series[0].data = currentValues;
            // this.option.series[0].lineStyle.color = this.darkTheme == 0 ? '#95c8ff':'#004080';
        } else {
            // 查询失败不显示图表
            this.show = false;
        }
        // 填充数据
        this.priceLineChart.setOption(this.option, true);
      },
    }
}
</script>
<style lang="scss" scoped>
// .priceLineChart {
//     width: 100%;
//     height: 486px;
// }
</style>