<template>
	<div>
        <!-- 确认信息弹窗 -->
        <v-dialog v-model="confirmUnNftInfoDialog" content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <div class="sidebar-h2 text-primaryGrey">
                            <v-card-title class="d-flex pa-0">
                                <span>Confirm unNFT info</span>
                                <v-spacer></v-spacer>
                                <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="confirmUnNftInfoDialog = false"></v-icon>
                            </v-card-title>
                        </div>
                        <v-col cols="12" class="pa-0">
                            <div class="body-p-small text-primaryGrey">
                                <v-divider class="my-1"></v-divider>
                                    <div class="body-p fw-b text-primaryGrey">unNFT info</div>
                                    <v-divider class="my-1"></v-divider>
                                    <v-row no-gutters>
                                        <v-col cols="6">Name:</v-col>
                                        <v-col cols="6">{{ nft.name }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">External link:</v-col>
                                        <v-col cols="6">{{ nft.externalUrl }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Description:</v-col>
                                        <v-col cols="6">{{ (nft.description && nft.description.length > 90) ? nft.description.substring(0, 90) + '...' : nft.description }}</v-col>
                                    </v-row>
                                    <v-divider class="my-1"></v-divider>
                                    <div class="body-p fw-b text-primaryGrey">Attributes</div>
                                    <v-divider class="my-1"></v-divider>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Department:</v-col>
                                        <v-col cols="6">{{ nft.department }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Rarity:</v-col>
                                        <v-col cols="6">{{ nft.rarity }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Explicit & Sensitive Content:</v-col>
                                        <v-col cols="6">{{ nft.sensitiveContent ? 'Yes' : 'No' }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">License:</v-col>
                                        <v-col cols="6">{{ nft.license }}</v-col>
                                    </v-row>
                                    <v-divider class="my-1"></v-divider>
                                    <div class="body-p fw-b text-primaryGrey">Reward Ratios</div>
                                    <v-divider class="my-1"></v-divider>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Total Reward Ratio:</v-col>
                                        <v-col cols="6">{{ nft.totalRewardRatio + '%' }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Originator Reward Ratio (OR):</v-col>
                                        <v-col cols="6">{{ nft.originatorRewardRatio + '%' }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Number of Generations:</v-col>
                                        <v-col cols="6">{{ nft.numberOfGenerations }}</v-col>
                                    </v-row>
                                    <v-divider class="my-1"></v-divider>
                                    <div class="body-p fw-b text-primaryGrey">Blockchain</div>
                                    <v-divider class="my-1"></v-divider>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6">Blockchain:</v-col>
                                        <v-col cols="6">{{ nft.blockchain }}</v-col>
                                    </v-row>
                            </div>
                            <div class="mt-4 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" @click="confirm(true)" aria-label="Confirm">Confirm</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
export default {
    data(){
        return {
            // 确认信息弹窗
            confirmUnNftInfoDialog: false,
        }
    },
    props: {
        // 是否展示
        show: { type: Boolean, default: false },
        // NFT 信息
        nft: { type: Object, default: {} }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{
        show: {
            handler(n, o) {
                if(n) {
                    this.confirmUnNftInfoDialog = true;
                } else {
                    this.confirmUnNftInfoDialog = false;
                }
            },
            immediate: true
        },
        confirmUnNftInfoDialog: {
            handler(n, o) {
                if(!n) {
                    this.$emit('watchConfirmUnNftInfoDialog', false);
                }
            },
            immediate: true
        }
    },
    methods: {
        // 确认信息
        confirm(confirmed) {
            this.$emit('watchConfirmUnNftInfoDialog', confirmed);
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>