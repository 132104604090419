<template>
	<div>
        <v-row no-gutters>
            <h6 class="body-p-small text-primaryGrey">
                The wallets on this blacklist are not permitted to purchase this unCrypto. Current and previous owners who are still receiving FR payments are blacklisted until the eligible payout window has passed. 
            </h6>
            <v-col cols="12" sm="12" class="mt-8" v-for="(blacklist, i) in blacklists" :key="i">
                <!-- pc -->
                <v-row no-gutters align="center" v-if="responsive == 'pc'">
                    <v-col cols="1">
                        <UserAvatar v-if="blacklist.user" :avatar="blacklist.user.avatar" :username="blacklist.user.username"></UserAvatar>
                    </v-col>
                    <v-col cols="3" class="text-primaryGrey body-p gradient-text-hover">
                        <Clipboard :text="blacklist.user.wallet" v-if="blacklist.user">
                            {{ $tools.privacyField(blacklist.user.wallet) }}
                        </Clipboard>
                    </v-col>
                    <v-col cols="8" class="text-primaryGrey body-p" v-if="blacklist.inFR">
                        <span>Currently inside the Flow Sliding Window (w)</span>
                    </v-col>
                    <v-col cols="5" class="text-primaryGrey body-p" v-if="!blacklist.inFR">
                        <span>{{ blacklist.reason }}</span>
                    </v-col>
                    <v-col cols="3" class="text-right" v-if="!blacklist.inFR">
                        <a class="del-underline gradient-left-red-purple-100" v-if="blacklist.userId == user.id">
                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small" width="100" height="33" @click="showDisputeDialog" aria-label="Dispute">Dispute</v-btn>
                        </a>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" v-if="responsive == 'mobile'">
                    <v-col cols="12" sm="6" class="algincenter">
                        <UserAvatar v-if="blacklist.user" :avatar="blacklist.user.avatar" :username="blacklist.user.username" class="mr-6"></UserAvatar>
                        <Clipboard :text="blacklist.user.wallet" v-if="blacklist.user">
                            {{ $tools.privacyField(blacklist.user.wallet) }}
                        </Clipboard>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right mr-4" v-if="!blacklist.inFR">
                        <a class="del-underline gradient-left-red-purple-100" v-if="blacklist.userId == user.id">
                            <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small-b" width="100" height="33" @click="showDisputeDialog" aria-label="Dispute">Dispute</v-btn>
                        </a>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-primaryGrey body-p mt-4" v-if="blacklist.inFR">
                        <span>Currently inside the Flow Sliding Window (w)</span>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-primaryGrey body-p mt-4" v-if="!blacklist.inFR">
                        <span>{{ blacklist.reason }}</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <Dispute :show="disputeDialog" @watchDisputeDialog="watchDisputeDialog"></Dispute>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Dispute from '@/components/nft/Dispute.vue';
import UserAPI from '@/api/user.js';
import CryptoBlacklistAPI from '@/api/crypto-blacklist.js';
export default {
    data(){
        return {
            // 申诉弹窗
            disputeDialog: false,
            // 黑名单集合
            blacklists: []
        }
    },
    props: {
        // 主键
        cryptoId: {
            type: String,
            default: null
        }
    },
    components: { Dispute },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'responsive']),
    },
    watch:{
        cryptoId: {
            handler(newVal, oldVal) {
                // 根据加密货币主键查询黑名单集合
                this.getBlacklistByCryptoId();
            },
            immediate: true
        },
    },
    methods: {
        // 打开申诉弹窗
        async showDisputeDialog() {
            // 是否已经认证登录
            let res = await UserAPI.authorization();
            let data = res.data;
            if(data.success) {
                this.disputeDialog = true;
            }
            // 未登录则会自动重定向到登录页面
        },
         // 监听争议
         watchDisputeDialog(show) {
            this.disputeDialog = show;
        },
        // 根据加密货币主键查询黑名单集合
        async getBlacklistByCryptoId() {
            if(this.cryptoId == null) {
                return;
            }
            let res = await CryptoBlacklistAPI.getBlacklistByCryptoId(this.cryptoId);
            let data = res.data;
            if(data.success) {
                this.blacklists = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>