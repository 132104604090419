<template>
	<div v-for="i in length" :key="i" class="py-8">
        <div class="mt-2 d-flex flex-nowrap">
            <div><v-avatar class="bg-lightGray"></v-avatar></div>
            <div class="ml-5">
                <div>
                    <v-card class="bg-lightGray" width="300" height="20" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-1">
                    <v-card class="bg-lightGray" width="100" height="20" rounded="0" elevation="0"></v-card>
                </div>
           </div>
        </div>
        <v-card class="bg-lightGray mt-5" rounded="0" elevation="0">
            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public" class="w-100" :aspect-ratio="1" cover></v-img>
        </v-card>
        <v-card class="bg-lightGray mt-2 w-100" height="40" rounded="0" elevation="0"></v-card>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>