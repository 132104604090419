<template>
    <div>
        <v-bottom-navigation class="bottom-navigation-bg navigation-b" v-if="path">
          <v-btn value="Home" :to="items.link"  v-for="(items,index) in data" :key="index">
            <v-icon size="24" class="bottom-navigation-text">{{items.icon}}</v-icon>
            <span class="bottomNavigation-mobile mt-1 bottom-navigation-text">{{items.title}}</span>
          </v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script>
export default {
   name:'',
   props:['pathData','pathState'],
   components: {
   },
   data() {
      return {
        data:[{
          icon: 'mdi mdi-home',
          title: 'Home',
          link: '/'
        },{
          icon: 'mdi mdi-trending-up',
          title: 'Market',
          link: '/uncryptos'
        },{
          icon: 'mdi mdi-plus-thick',
          title: 'Wrap',
          link: '/uncrypto/wrap'
        },{
          icon: 'mdi mdi-view-dashboard-outline',
          title: 'Dashboard',
          link: '/dashboard'
        },{
          icon: 'mdi mdi-cog',
          title: 'Settings',
          link: '/settings'
        }],
        path:true,
      }
   },
   watch:{
      $route: {
          handler(n, o) {
              this.pathData.forEach(element => {
                this.path=(n.path==element);
                this.path = this.pathState ? this.path : !this.path
              });
          },
          immediate: true
      },
    },
   created(){
   },
   computed:{
   },
   mounted(){
   },
   methods:{
   },
}
</script>
<style lang="scss" scoped>
.navigation-b{
  position:fixed;
  bottom: 0;
}
</style>
