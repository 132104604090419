import axios from '@/config/api.request'

export default {
    // 搜索 NFTs
    searchNFTs(text, page, size) {
        return axios.request({
            url: '/search/search/nfts',
            method: 'get',
            params: { text: text, page: page, size: size }
        })
    },
    // 搜索加密货币
    searchCryptos(page, size, sortBy, text, blockchain, wrappedCurrency, quoteCurrency, totalRewardRatios, futureRewardRatios, numberOfGenerations) {
        return axios.request({
            url: '/search/search/cryptos',
            method: 'get',
            params: { page, size, sortBy, text, blockchain, wrappedCurrency, quoteCurrency, totalRewardRatios, futureRewardRatios, numberOfGenerations }
        })
    },
}