<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="6">
                <div class="body-h4 ml-2 text-primaryGrey">
                    <h4>Newsletter</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 ml-5 r-m-l-0 mt-1 mb-5"></v-divider>
                <v-card class="ml-2 mt-16" elevation="0">
                    <div class="body-p text-primaryGrey">
                        Stay in the loop on un-class crypto assets and NFTs
                    </div>
                    <div class="mt-9 text-primaryGrey">
                        <v-text-field clearable style="width:245px;" label="Enter your email" variant="outlined" hide-details density="comfortable"></v-text-field>
                    </div>
                    <div class="mt-9 px-1 pb-1">
                        <a class="gradient-left-red-purple-245 del-underline">
                            <v-btn :rounded="0" elevation="2" width="245" height="48" class="body-p-small-b text-none text-primaryGrey" aria-label="subscribe">Subscribe</v-btn>
                        </a>
                    </div>
                </v-card>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>