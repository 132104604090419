<template>
	<div class="bg-lightGray align-center">
        <v-container class="h-100" align="center">
            <!-- ----------------------------- Back -------------------------------- -->
            <v-card max-width="582" rounded="0" elevation="0" color="transparent" class="pt-4" align="start">
                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3" :class="responsive != 'pc' ? 'ml-2' : ''" @click="$router.back(-1)"></v-icon>
            </v-card>
            <!-- ----------------------------- WRAP -------------------------------- -->
            <v-card v-if="action == 'WRAP'" max-width="1400" rounded="0" elevation="0" color="transparent" class="mx-auto">
                <v-card max-width="604" elevation="0" color="transparent" class="py-0 px-3 black-box" :rounded="0">
                    <v-row no-gutters class="py-4 px-8 bg-black">
                        <v-col cols="8" class="text-left d-flex">
                            <h3 class="body-h4 text-primary mr-6">
                                <a href="/uncrypto/wrap" aria-label="Wrap" class="del-underline gradient-underline-hover">
                                    <a class="text-primary gradient-text-hover">Wrap</a>
                                </a>
                            </h3>
                            <h3 class="body-h4">
                                <router-link to="/uncryptos" aria-label="unCryptos" class="del-underline gradient-underline-hover">
                                    <a class="text-primaryGrey gradient-text-hover">Buy</a>
                                </router-link>
                            </h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right">
                             <!-- 区块链 -->
                             <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-btn aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                        <Blockchain :blockchain="blockchain" size="18" text></Blockchain>
                                        <v-icon aria-label="mdi-menu-down" end icon="mdi:mdi-menu-down" />
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="blockchain in supportedBlockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain)">
                                        <v-list-item-title>
                                            <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <div class="bg-666666 elevation-show">
                        <v-row no-gutters class="px-10 py-10">
                            <v-col cols="12" class="d-flex justify-space-between align-center">
                                <v-text-field v-model="crypto.size" :hide-details="true" type="number" placeholder="0" :step="$tools.getTickStep(selectedCryptoBase?.cryptoWrapped?.sizeDecimals)" :min="selectedCryptoBase?.minSize != null ? selectedCryptoBase?.minSize : 0" class="wrap-input-font" variant="plain"></v-text-field>
                                <div>
                                    <div class="select-btn">
                                        <v-select :hide-details="true" v-model="selectedCryptoBase" :items="cryptoBases" item-title="currency" return-object placeholder="Select token" rounded="0" base-color="white" bg-color="aaaaaa" variant="outlined">
                                            <template v-slot:item="{ props, item }">
                                                <v-list-item v-bind="props">
                                                    <!-- <template v-slot:prepend="avatar">
                                                        <UserAvatar :avatar="item?.userReferralCode?.user?.avatar" :username="item?.userReferralCode?.user?.username" :size="40" class="mr-4"></UserAvatar>
                                                    </template> -->
                                                    <template v-slot:title>
                                                        <span class="mr-2">{{ item.raw.currency }}</span>
                                                        <span class="mr-2">({{ item.raw.name }})</span>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="text-left mt-1 body-p-small">
                                        <span>Original token</span>
                                    </div>
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12">
                                <div class="mb-5"></div>
                                <div class="body-p-small text-cccccc text-left" v-if="selectedCryptoBase?.minSize > 0">
                                    <span class="text-white">Min Size: {{ $tools.formatNumber(selectedCryptoBase?.minSize, -1) }}</span>
                                </div>
                                <div class="body-p-small text-cccccc text-left" v-if="token">
                                    <span class="text-white">Balance: {{ $tools.formatNumber(cryptoBaseBalance, selectedCryptoBase?.cryptoWrapped?.sizeDecimals) }}</span>
                                    <span class="ml-10 pointer" :class="(crypto.size != 0 && (crypto.size == (cryptoBaseBalance * 0.25).toFixed(selectedCryptoBase?.cryptoWrapped?.sizeDecimals))) ? 'text-primary fw-b' : ''" @click="crypto.size = cryptoBaseBalance * 0.25">25%</span>
                                    <span class="ml-3 pointer" :class="(crypto.size != 0 && (crypto.size == (cryptoBaseBalance * 0.5).toFixed(selectedCryptoBase?.cryptoWrapped?.sizeDecimals))) ? 'text-primary fw-b' : ''" @click="crypto.size = cryptoBaseBalance * 0.5">50%</span>
                                    <span class="ml-3 pointer" :class="(crypto.size != 0 && (crypto.size == (cryptoBaseBalance * 0.75).toFixed(selectedCryptoBase?.cryptoWrapped?.sizeDecimals))) ? 'text-primary fw-b' : ''" @click="crypto.size = cryptoBaseBalance * 0.75">75%</span>
                                    <span class="ml-3 pointer" :class="(crypto.size != 0 && (crypto.size == (cryptoBaseBalance * 1).toFixed(selectedCryptoBase?.cryptoWrapped?.sizeDecimals))) ? 'text-primary fw-b' : ''" @click="crypto.size = cryptoBaseBalance">Max</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- <div class="d-flex align-center">
                        <v-icon color="primary" size="42" icon="mdi mdi-chevron-down"></v-icon>
                        <v-divider class="border-opacity-50" color="white"></v-divider>
                    </div> -->
                    <div class="bg-black02 elevation-show bottom-box">
                        <v-icon color="primary" size="80" class="down-icon" icon="mdi mdi-chevron-down"></v-icon>
                        <v-row no-gutters class="px-10 py-10" >
                            <v-col cols="12" class="d-flex justify-space-between align-center">
                                <span :class="responsive == 'pc' ? 'fs-24' : 'fs-14'">
                                    Paired with
                                </span>
                                <div>
                                    <div class="select-btn">
                                        <v-select :hide-details="true" v-model="selectedCryptoQuote" :items="cryptoQuotes" item-title="currency" return-object placeholder="Select token" rounded="0" base-color="white" bg-color="666666" variant="outlined">
                                            <template v-slot:item="{ props, item }">
                                                <v-list-item v-bind="props">
                                                    <!-- <template v-slot:prepend="avatar">
                                                        <UserAvatar :avatar="item?.userReferralCode?.user?.avatar" :username="item?.userReferralCode?.user?.username" :size="40" class="mr-4"></UserAvatar>
                                                    </template> -->
                                                    <template v-slot:title>
                                                        <span class="mr-2">{{ item.raw.currency }}</span>
                                                        <span class="mr-2">({{ item.raw.name }})</span>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="text-left mt-1 body-p-small">
                                        <span>Payment token</span>
                                    </div>
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" class="my-10 text-left">
                                <div class="fs-48">
                                    {{ $tools.formatNumber(crypto.size || 0, selectedCryptoBase?.cryptoWrapped?.sizeDecimals)  }}
                                </div>
                                <div class="fs-30">
                                    {{ crypto.wrappedCurrency || '--' }}/{{ crypto.quoteCurrency || '--' }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row no-gutters class="mt-16 px-10 text-left">
                        <v-col cols="12">
                            <h3 class="body-h4">
                                Total Reward Ratio <span class="ml-1 text-red">*</span>
                            </h3>
                            <div class="mt-2 body-p-small mb-5">
                                The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unCrypto. Recommended 35%.
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey gradient-text-hover pointer" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <div>
                                            Once created, the Total Reward Ratio (f) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must wrap a new unCrypto.
                                        </div>
                                        <div class="mt-2">
                                            You may find yourself unable to sell your unCrypto if this value is too low or too high. 
                                        </div>
                                    </v-card>
                                </v-menu>
                            </div>
                            <v-text-field v-model="crypto.totalRewardRatio" label="Total Reward Ratio" type="number" density="comfortable" min="5" max="50" placeholder="Between 5% to 50%" variant="outlined" hide-details :reverse="Number(crypto.totalRewardRatio) > 0">
                                <template v-slot:append-inner>%</template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-10">
                            <h3 class="body-h4">
                                Originator Reward Ratio (OR)<span class="ml-1 text-red">*</span>
                            </h3>
                            <div class="mt-2 body-p-small mb-5">
                                The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split). Recommended 40%.
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey gradient-text-hover pointer" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <div>
                                            The Originators' Token (o-token) may be viewed and managed on the Dashboard. Each unCrypto consists of 100 o-tokens, 70 of which are sent to the originator(s) and 30 to untrading.org (For a limited time, all o-tokens designed for untrading.org are waived).
                                        </div>
                                        <div class="mt-2">
                                            On your Dashboard, you can transfer o-tokens to other creators or originators.
                                        </div>
                                    </v-card>
                                </v-menu>
                            </div>
                            <v-text-field v-model="crypto.originatorRewardRatio" label="Originator Reward Ratio (OR)" density="comfortable" type="number" min="5" max="50" placeholder="Between 5% to 50%" variant="outlined" hide-details :reverse="Number(crypto.originatorRewardRatio) > 0">
                                <template v-slot:append-inner>%</template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-10">
                            <h3 class="body-h4">
                                Number of Generations <span class="ml-1 text-red">*</span>
                            </h3>
                            <div class="mt-2 body-p-small mb-5">
                                The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit. Recommended 10.
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey gradient-text-hover pointer" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <div>
                                            Once created, the Number of Generation (w) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must wrap a new unCrypto.
                                        </div>
                                        <div class="mt-2">
                                            You may find yourself unable to sell your unCrypto if this value is too low or too high. 
                                        </div>
                                    </v-card>
                                </v-menu>
                            </div>
                            <v-text-field v-model="crypto.numberOfGenerations" label="Number of Generations" density="comfortable" type="number" min="5" max="20" placeholder="Between 5 to 20" variant="outlined" hide-details :reverse="Number(crypto.numberOfGenerations) > 0"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card max-width="588" elevation="0" class="py-10" :class="responsive == 'pc' ? 'px-11' : 'px-13'" color="transparent" align="start">
                    <div class="gradient-left-red-purple-block">
                        <!-- 连接钱包 -->
                        <router-link v-if="!token" :to="'/connectwallet?redirectUrl=' + currentUrl" class="del-underline">
                            <v-btn rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-label="Wrap">Connect Wallet</v-btn>
                        </router-link>
                        <!-- 选择代币 -->
                        <v-btn v-else-if="crypto.wrappedCurrency == null || crypto.quoteCurrency == null " rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-label="Select a token to wrap" disabled>Select a token to wrap</v-btn>
                        <!-- 无效数量 -->
                        <v-btn v-else-if="crypto.size <= 0" rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-label="Invalid size" disabled>Invalid size</v-btn>
                        <!-- 余额不足 -->
                        <v-btn v-else-if="crypto.size > cryptoBaseBalance" rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-label="Insufficient balance" disabled>Insufficient balance</v-btn>
                        <!-- 最小数量不足 -->
                        <v-btn v-else-if="crypto.size < selectedCryptoBase?.minSize" rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-label="Insufficient min size" disabled>Insufficient min size</v-btn>
                        <!-- 封装 -->
                        <v-btn v-else rounded="0" :loading="wrapLoading" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" :disabled="isDisabledWrapButton" aria-label="Wrap" @click="getAllowance">Wrap</v-btn>
                    </div>
                    <div>
                        <h6 class="body-p-small mt-5" v-if="crypto.wrappedCurrency">
                            Once the transaction is confirmed, the {{ crypto.wrappedCurrency }} will be sent to your wallet instantly. 
                        </h6>
                    </div>
                </v-card>
            </v-card>
            <!-- ----------------------------- WRAPPED -------------------------------- -->
            <v-card v-if="action == 'WRAPPED'" max-width="1400" rounded="0" elevation="0" color="transparent" class="mx-auto">
                <v-card max-width="604" color="transparent" elevation="0" class="px-3" :rounded="0">
                    <v-row no-gutters class="py-4 px-8 bg-black">
                        <v-col cols="8" class="text-left d-flex">
                            <h3 class="body-h4 text-primary mr-6">
                                <a href="/uncrypto/wrap" aria-label="Wrap" class="del-underline gradient-underline-hover">
                                    <a class="text-primary gradient-text-hover">Wrap</a>
                                </a>
                            </h3>
                            <h3 class="body-h4">
                                <router-link to="/uncryptos" aria-label="unCryptos" class="del-underline gradient-underline-hover">
                                    <a class="text-primaryGrey gradient-text-hover">Buy</a>
                                </router-link>
                            </h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right body-p text-primaryGrey">
                            <Blockchain :blockchain="unCrypto.blockchain" size="24" text></Blockchain>
                        </v-col>
                    </v-row>
                    <!-- <v-divider class="my-7"></v-divider> -->
                    <!-- unCrypto 信息 -->
                    <div class="elevation-show bg-666666 py-6 px-8">
                        <div class="text-white">
                            <v-row no-gutters>
                                <v-col cols="12" class="text-left mt-2">
                                    <div>
                                        {{ unCrypto.wrappedCurrency }} #{{ $tools.formatNumber(tokenId, 0) }} wrapped successfully!
                                    </div>
                                    <div class="wrap-h1 mt-4">
                                        {{ $tools.formatNumber(unCrypto.size || 0, unCrypto?.cryptoWrapped?.sizeDecimals)  }}
                                    </div>
                                    <!-- <div class="body-p-small">
                                        Balance: {{ $tools.formatNumber(cryptoWrappedBalance, -1) }}
                                    </div> -->
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" class="mt-4 mb-8 text-left">
                                    <!-- <div>
                                        <v-icon icon="icon-eth" class="mb-6"></v-icon>
                                        <span class="hero-h3 ml-2">{{ unCrypto.wrappedCurrency }}</span>
                                    </div> -->
                                    <div class="span-large">
                                        <span>{{ unCrypto.wrappedCurrency }}/{{ unCrypto.quoteCurrency }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <v-divider class="border-opacity-50" color="white"></v-divider>
                        <!-- nFR 信息 -->
                        <v-row no-gutters justify="space-between" class="mt-7">
                            <h3 class="text-white text-left body-h5">Non-fungible Future Rewards (nFR) Parameters</h3>
                            <v-col cols="12" class="px-0">
                                <v-row no-gutters>
                                    <v-col cols="6" align="start">
                                        <TotalRewardValue :value="unCrypto.totalRewardRatio" text-color="text-white"></TotalRewardValue>
                                    </v-col>
                                    <v-col cols="6" align="start">
                                        <FutureRewardValue :value="100 - unCrypto.originatorRewardRatio" text-color="text-white"></FutureRewardValue>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" align="start">
                                        <OriginatorRewardValue :value="unCrypto.originatorRewardRatio" text-color="text-white"></OriginatorRewardValue>
                                    </v-col>
                                    <v-col cols="6" align="start">
                                        <GenerationValue :value="unCrypto.numberOfGenerations" text-color="text-white"></GenerationValue>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
                <v-card max-width="588" elevation="0" class="px-1 py-10" color="transparent" align="start">
                    <div class="gradient-left-red-purple-block">
                        <router-link :to="`/uncrypto/list/${cryptoId}`" class="del-underline">
                            <v-btn rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-labelledby="Sell">Sell</v-btn>
                        </router-link>
                    </div>
                    <div class="mt-10 gradient-left-red-purple-block">
                        <router-link :to="`/profile/${user.username}`" class="del-underline">
                            <v-btn rounded="0" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-labelledby="Sell">Go to my profile</v-btn>
                        </router-link>
                    </div>
                </v-card>
            </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose" @approveTransactionClose="approveTransactionClose"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose" @approveTransactionClose="approveTransactionClose"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import CryptoAPI from '@/api/crypto.js';
import CryptoBaseAPI from '@/api/crypto-base.js';
import CryptoQuoteAPI from '@/api/crypto-quote.js';
export default {
    data(){
        return {
            // 当前操作
            action: 'WRAP',
            // 当前页面链接
            currentUrl: window.location.href,
            // 加密货币基准资产对象集合
            cryptoBases: [],
            // 选中的加密货币基准资产对象
            selectedCryptoBase: null,
            // 加密货币基准资产余额
            cryptoBaseBalance: 0,
            // 加密货币封装资产余额
            cryptoWrappedBalance: 0,
            // 加密货币报价资产对象集合
            cryptoQuotes: [],
            // 选中的加密货币报价资产对象
            selectedCryptoQuote: null,
            // 加密货币对象参数
            crypto:{
                blockchain: null,
                wrappedCurrency: null,
                quoteCurrency: null,
                size: 0,
                totalRewardRatio: null,
                originatorRewardRatio: null,
                numberOfGenerations: null,
                transactionHash: null
            },
            // 当前的区块链
            currentBlockchain: {},
            // 封装加载中
            wrapLoading: false,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 批准交易哈希
            approveTransactionHash: null,
            // 封装成功的代币主键
            tokenId: 0,
            // 加密货币主键
            cryptoId: null,
            // 封装后的加密货币
            unCrypto: {}
        }
    },
    components: {  },
    created(){
        // 监听
        this.$bus.on('callGetApproveTransactionReceipt', data => {
            if (data.type == "CRYPTO_WRAP") {
                this.callGetApproveTransactionReceipt(data.data);
            }
        })
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "CRYPTO_WRAP") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        // 查询封装权限
        this.wrapPermission();
        // 查询全部加密货币基准资产
        this.getCryptoBases();
        // 查询全部加密货币报价资产
        this.getCryptoQuotes();
        // Test
        // this.action = 'WRAPPED',
        // this.cryptoId = "1726927927220109314";
        // this.getCryptoById();
        // this.getTransactionReceipt();
    },
    computed: {
        ...mapGetters(['token', 'user', 'walletType', 'blockchains', 'blockchain']),
        // 加密货币支持的区块链
        supportedBlockchains() {
            return this.blockchains.filter(b => b.isSupportCreateCrypto);
        },
        // 禁用封装按钮
        isDisabledWrapButton() {
            let totalRewardRatio = Number(this.crypto.totalRewardRatio);
            if(totalRewardRatio < 5 || totalRewardRatio > 50) {
                return true;
            }
            let originatorRewardRatio = Number(this.crypto.originatorRewardRatio);
            if(originatorRewardRatio < 5 || originatorRewardRatio > 50) {
                return true;
            }
            let numberOfGenerations = Number(this.crypto.numberOfGenerations);
            if(numberOfGenerations < 5 || numberOfGenerations > 20) {
                return true;
            }
            return false;
        },
    },
    watch:{
        // 监听数量改变
        'crypto.size': {
            handler(n, o) {
                if(n != null && n != "") {
                    if(this.selectedCryptoBase?.cryptoWrapped?.sizeDecimals) {
                        this.crypto.size = Number(Number(n).toFixed(this.selectedCryptoBase?.cryptoWrapped?.sizeDecimals));
                    } else {
                        this.crypto.size = Number(n);
                    }
                } else {
                    this.crypto.size = 0;
                }
            }
        },
        // 监听区块链
        blockchain: {
            handler(n, o) {
                if(n) {
                    this.currentBlockchain = this.blockchains.filter(b => b.blockchain == n)[0];  
                } else {
                    this.currentBlockchain = this.blockchains[0];
                }
                // 刷新区块链
                this.changeBlockchainCondition(this.currentBlockchain);
                // 重置选中的加密货币基准资产对象
                this.selectedCryptoBase = null;
                // 重置选中的加密货币报价资产对象
                this.selectedCryptoQuote = null;
                // 查询加密货币基准资产余额
                this.getCryptoBaseBalance();
            },
            immediate: true
        },
        // 监听选中的加密货币基准资产
        selectedCryptoBase: {
            handler:function(newV,oldV) {
                if(newV) {
                    this.crypto.wrappedCurrency = newV.cryptoWrapped.currency;
                    this.crypto.size = Number(Number(this.crypto.size).toFixed(this.selectedCryptoBase.cryptoWrapped.sizeDecimals));
                } else {
                    this.crypto.wrappedCurrency = null;
                }
                // 查询加密货币基准资产余额
                this.getCryptoBaseBalance();
            },
            deep: true
        },
        // 监听选中的加密货币报价资产
        selectedCryptoQuote: {
            handler:function(newV,oldV) {
                if(newV) {
                    this.crypto.quoteCurrency = newV.currency;
                } else {
                    this.crypto.quoteCurrency = null;
                }
            },
            deep: true
        }
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain.blockchain);
            // 当前区块链
            this.currentBlockchain = blockchain;
            // 设置参数
            this.crypto.blockchain = blockchain.blockchain;
            // 查询全部加密货币基准资产
            this.getCryptoBases();
            // 查询全部加密货币报价资产
            this.getCryptoQuotes();
            // this.$nextTick(() => {
            //     // 切换网络
            //     if(this.walletType) {
            //         this.$refs[this.walletType].switchNetwork(this.currentBlockchain.chainId);
            //     }
            // });
        },
        // 查询全部加密货币基准资产
        async getCryptoBases() {
            this.cryptoBases = [];
            let res = await CryptoBaseAPI.getCryptoBases(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.cryptoBases = data.data;
            }
        },
        // 查询全部加密货币报价资产
        async getCryptoQuotes() {
            this.cryptoQuotes = [];
            let res = await CryptoQuoteAPI.getCryptoQuotes(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.cryptoQuotes = data.data;
            }
        },
        // 查询加密货币基准资产余额
        async getCryptoBaseBalance() {
            this.cryptoBaseBalance = 0;
            if(this.selectedCryptoBase) {
                let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                let cryptoBaseContract = new web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.selectedCryptoBase.address);
                let cryptoBaseBalance = await cryptoBaseContract.methods.balanceOf(this.user.wallet).call();
                this.cryptoBaseBalance = this.$web3Utils.fromWei(cryptoBaseBalance, this.selectedCryptoBase.tokenDecimals);
            }
        },
        // 查询加密货币封装资产余额
        async getCryptoWrappedBalance() {
            this.cryptoWrappedBalance = 0;
            if(this.unCrypto) {
                let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                let cryptoWrappedContract = new web3.eth.Contract(this.currentBlockchain.unCryptoAbi, this.unCrypto.contractAddress);
                let cryptoWrappedBalance = await cryptoWrappedContract.methods.balanceOf(this.user.wallet).call();
                this.cryptoWrappedBalance = cryptoWrappedBalance;
            }
        },
        // 判断用户是否有封装权限
        async wrapPermission() {
            let res = await CryptoAPI.wrapPermission();
            let data = res.data;
            if(data.success) {
                // 没有权限时跳转到市场
                if (!data.data) {
                    this.$router.push('/uncryptos');
                    this.$store.dispatch('snackbarMessageHandler', "You do not have permission to wrap unCrypto. But you can explore the market and buy the unCryptos you are interested in.");
                }
            }
        },
        // 查询授权的数量
        async getAllowance() {
            // 加载中
            this.wrapLoading = true;
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            let cryptoWrappedContract = new web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.selectedCryptoBase.address);
            let allowanceWei = await cryptoWrappedContract.methods.allowance(this.user.wallet, this.selectedCryptoBase.cryptoWrapped.address).call();
            let allowance = this.$web3Utils.fromWei(allowanceWei, this.selectedCryptoBase.tokenDecimals);
            // 允许的资金足够则直接封装，不够的话则申请额度
            if (allowance >= this.crypto.size) {
                // 链上封装
                this.wrap();
            } else {
                // 批准
                this.approve();
            }
        },
        // 批准
        async approve() {
            // 加载中
            this.wrapLoading = true;
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 编码参数列表
            try {
                // 合约方法
                let method = web3.eth.abi.encodeFunctionSignature('approve(address,uint256)');
                // 将参数编码
                let sizeWei = this.$web3Utils.toWei(this.crypto.size, this.selectedCryptoBase.tokenDecimals);
                let param = web3.eth.abi.encodeParameters(['address', 'uint256'], [this.selectedCryptoBase.cryptoWrapped.address, sizeWei]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].approve(this.currentBlockchain.blockchain, this.user.wallet, this.selectedCryptoBase.address, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.wrapLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.wrapLoading = false;
            }
        },
        // 封装加密货币
        async wrap() {
            // 加载中
            this.wrapLoading = true;
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 编码参数列表
            try {
                // 合约方法
                let method = web3.eth.abi.encodeFunctionSignature('wrap(address,uint256,address,uint8,uint256,uint256)');
                let sizeWei = this.$web3Utils.toWei(this.crypto.size, this.selectedCryptoBase?.tokenDecimals);
                let totalRewardRatioWei = this.$web3Utils.toWei((Number(this.crypto.totalRewardRatio) / 100), this.selectedCryptoBase?.cryptoWrapped?.tokenDecimals);
                let originatorRewardRatioWei = this.$web3Utils.toWei((Number(this.crypto.originatorRewardRatio) / 100), this.selectedCryptoBase?.cryptoWrapped?.tokenDecimals);
                // 将参数编码
                let param = web3.eth.abi.encodeParameters(['address', 'uint256', 'address', 'uint8', 'uint256', 'uint256'], [this.user.wallet, sizeWei, this.selectedCryptoQuote.address, this.crypto.numberOfGenerations, totalRewardRatioWei, originatorRewardRatioWei]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.crypto.blockchain, this.user.wallet, this.selectedCryptoBase.cryptoWrapped.address, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.wrapLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.wrapLoading = false;
            }
        },
        // 批准交易关闭：成功/失败
        async approveTransactionClose(success, transactionHash) {
            this.approveTransactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 查询批准交易收据
                this.getApproveTransactionReceipt();
            } else {
                // 加载完成
                this.wrapLoading = false;
            }
        },
        // 查询批准交易收据
        getApproveTransactionReceipt() {
            let params = {
                type: "CRYPTO_WRAP",
                blockchain: this.crypto.blockchain,
                transactionHash: this.approveTransactionHash
            }
            this.$bus.emit('emitGetApproveTransactionReceipt', params);
            // // 轮训查询交易
            // this.transactionReceiptInterval = setInterval(() => {
            //     let params = {
            //         type: "CRYPTO_WRAP",
            //         blockchain: this.crypto.blockchain,
            //         transactionHash: this.approveTransactionHash
            //     }
            //     this.$bus.emit('emitGetApproveTransactionReceipt', params);
            // }, 1500)
        },
        // 回调批准交易收据
        callGetApproveTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            console.log(data);
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                if(data.status) {
                    // 链上封装
                    this.wrap();
                } else {
                    // 交易失败
                    this.$store.dispatch('snackbarMessageHandler', 'Transaction Failed!');
                    // 加载完成
                    this.wrapLoading = false;
                }
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询批准交易收据
                    this.getApproveTransactionReceipt();
                }, 3000);
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.crypto.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 封装
                await this.wrapOnUntrading();
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 加载完成
                this.wrapLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt() {
            let params = {
                type: "CRYPTO_WRAP",
                blockchain: this.crypto.blockchain,
                transactionHash: this.crypto.transactionHash
            }
            this.$bus.emit('emitGetTransactionReceipt', params);
            // // 轮训查询交易
            // this.transactionReceiptInterval = setInterval(() => {
            //     let params = {
            //         type: "CRYPTO_WRAP",
            //         blockchain: this.crypto.blockchain,
            //         transactionHash: this.crypto.transactionHash
            //     }
            //     this.$bus.emit('emitGetTransactionReceipt', params);
            // }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            console.log(data);
            if(data) {
                if(data.status) {
                    // 清除定时器
                    clearInterval(this.transactionReceiptInterval);
                    // 解析 tokenId
                    let tokenIdHex = data.logs[0].topics[1];
                    let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                    this.tokenId = web3.utils.hexToNumber(tokenIdHex);
                    // 根据主键查询加密货币
                    this.getCryptoById();
                } else {
                    // 交易失败
                    this.$store.dispatch('snackbarMessageHandler', "Transaction Failed!");
                    // 加载完成
                    this.wrapLoading = false;
                }
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询交易收据
                    this.getTransactionReceipt();
                }, 3000);
            }
        },
        // 封装
        async wrapOnUntrading() {
            let res = await CryptoAPI.wrap(this.crypto);
            let data = res.data;
            if(data.success) {
                this.cryptoId = data.data;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.cryptoId == null) {
                return;
            }
            let res = await CryptoAPI.getCryptoById(this.cryptoId);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
                this.tokenId = this.unCrypto.tokenId;
                // 查询加密货币封装资产余额
                // this.getCryptoWrappedBalance();
                // 加载完成
                this.wrapLoading = false;
                // 交易成功转到下一步
                this.action = 'WRAPPED';
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style lang="scss">
.wrap-input-font .v-input__control .v-field__field .v-field__input {
    font-size: 42px !important;
    padding: 0 !important;
    line-height: 82px !important;
    font-family: "Montserrat", sans-serif !important;
}
</style>
<style scoped lang="scss">
.elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.20),
     5px -5px 10px rgba(0, 0, 0, 0.20),
     0px 0px 0px,
     -5px -5px 10px rgba(0, 0, 0, 0.20),
}
.bottom-box {
    position: relative;
}
.down-icon {
    position: absolute;
    top: -40px;
    left: 20px;
    font-weight: border;
}
.select-btn {
    width: 140px;
}
.select-btn2 {
    width: 140px;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>