<template>
    <div>
        <!-- 测试网弹窗 -->
        <v-dialog v-model="testnetDialog" content-class="v-echarts-dialog" width="500">
            <v-card class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex px-0">
                            <div class="sidebar-h2 text-primaryGrey">Testnet</div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="testnetDialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="body-p text-primaryGrey my-2 px-0">
                            <div>You're currently on the untrading.org test network using the Sepolia testnet, Goerli testnet and Mumbai testnet.</div>
                            <br/>
                            <div>
                                To add Sepolia to your MetaMask, simply show test networks in your wallet settings. You will also need to get some test tokens: 
                                <span class="gradient-underline">
                                    <a href="https://sepoliafaucet.com/" aria-label="Sepolia Faucet" target="_blank" class="gradient-text del-underline">https://sepoliafaucet.com/</a>
                                </span>.
                            </div>
                            <br/>
                            <div>
                                To add Goerli to your MetaMask, simply show test networks in your wallet settings. You will also need to get some test tokens: 
                                <span class="gradient-underline">
                                    <a href="https://goerli-faucet.pk910.de/" aria-label="Goerli Faucet" target="_blank" class="gradient-text del-underline">https://goerli-faucet.pk910.de/</a>
                                </span>.
                            </div>
                            <br/>
                            <div>
                                To add Mumbai to your MetaMask, simply navigate to 
                                <span class="gradient-underline">
                                    <a href="https://mumbai.polygonscan.com/" aria-label="Mumbai" target="_blank" class="gradient-text del-underline">https://mumbai.polygonscan.com/</a>
                                </span> 
                                and click the link in the lower-right corner. You will also need to get some test tokens: 
                                <span class="gradient-underline">
                                    <a href="https://mumbaifaucet.com/"  aria-label="Mumbai Faucet" target="_blank" class="gradient-text del-underline">https://mumbaifaucet.com/</a>
                                </span>.
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 测试网弹窗
            testnetDialog: false,
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        this.loadTestnetDialogTimestamp();
    },
    computed: {
        ...mapGetters([])
    },
    watch:{
        testnetDialog: {
            handler(n, o) {
                if(!n) {
                    // 关闭弹窗时设置过期时间
                    localStorage.setItem('untrading-testnet-expiration-timestamp', +new Date() + 86400000 * 7);
                }
            }
        },
    },
    methods: {
        // 加载测试网弹窗时间戳
        loadTestnetDialogTimestamp() {
            // 测试网判断弹窗时间
            if(this.$config.env != 'prod'){
                let expirationTimestamp = localStorage.getItem('untrading-testnet-expiration-timestamp');
                if(expirationTimestamp == null) {
                    this.testnetDialog = true;
                } else if(+new Date() >= expirationTimestamp) {
                    // 已过期
                    localStorage.removeItem('untrading-testnet-expiration-timestamp');
                    this.testnetDialog = true;
                }
            }
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>