<template>
	<div>
        <v-row no-gutters infinite-scroll-immediate v-infinite-scroll="getWrappedNfrsByPage" :infinite-scroll-disabled="noMoreData">
            <v-col cols="12" class="r-p-x-4">
                <v-row no-gutters class="mb-5">
                    <div class="c-container">
                        <div class="c-card c-grid d-flex flex-column">
                            <div class="c-height st--c-hlMbgQ" v-for="(nfr, i) in nfrs" :key="i">
                                <NFR :nfr="nfr" wrap></NFR>
                            </div>
                            <!-- 加载中骨架 -->
                            <SkeletonUnNFT :length="4" v-if="loading"></SkeletonUnNFT>
                        </div>
                    </div>
                </v-row>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import NFR from '@/components/user/dashboard/nft/NFR.vue';
import NFRAPI from '@/api/nfr.js';
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            // 结果列表
            nfrs: [],
            page: 1,
            size: 10,
        }
    },
    props: {
        sortBy: {
            type: String,
            default: null
        },
        blockchain: {
            type: String,
            default: null
        }
    },
    components: { NFR, SkeletonUnNFT },
    created(){
        // 分页查询NFR列表
        this.getWrappedNfrsByPage();
    },
    mounted(){

    },
    computed: {

    },
    watch:{
        blockchain(n, o) {
            this.changeCondition();
        },
        sortBy(n, o) {
            this.changeCondition();
        }
    },
    methods: {
        // 分页查询NFR列表
        async getWrappedNfrsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await NFRAPI.getWrappedNfrsByPage(this.page, this.size, this.sortBy, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfrs.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.nfrs.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.nfrs.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 改变条件
        changeCondition() {
            // 恢复data定义的所有参数
            Object.assign(this.$data, this.$options.data.call(this));
            // 重新查询数据
            this.getWrappedNfrsByPage();
        },
    }
}
</script>
<style scoped>

</style>