<template>
	<div class="align-center pb-16">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1440" class="mx-auto pt-8">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card elevation="0" color="transparent">
                            <v-row no-gutters >
                                <v-col cols="12" class="ml-4 r-m-l-0">
                                    <h2 class="body-h4 text-primaryGrey">
                                        EXPLORE SETS
                                    </h2>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <!-- 基本信息 -->
                                <v-col cols="12" class="ml-4 r-m-l-0 text-grey04">
                                    <h1 :class="responsive == 'pc' ? 'hero-h1' :'mobile-h1'">CURATED SETS</h1>
                                </v-col>
                                <v-col cols="12" class="ml-4 r-m-l-0 mt-8 text-primaryGrey">
                                    <h6 class="body-p">Artworks selected by originators.</h6>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <!---------------------------- type ---------------------------->
                <v-row no-gutters class="mt-16 mx-4">
                    <v-col cols="12">
                        <v-tabs hide-slider density="compact" height="50" class="fw-b text-primaryGrey">
                            <router-link to="/market">
                                <v-tab value="market" rounded="0" width="200" class="px-0">
                                    <h4>Market</h4>
                                </v-tab>
                            </router-link>
                            <router-link to="/sets">
                                <v-tab value="sets" rounded="0" width="200" class="px-0 bg-primary text-white">
                                    <h4>Sets</h4>
                                </v-tab>
                            </router-link>
                            <router-link to="/collections">
                                <v-tab value="collections" rounded="0" width="200" class="px-0">
                                    <h4>Collections</h4>
                                </v-tab>
                            </router-link>
                        </v-tabs>
                    </v-col>
                    <v-divider class="border-opacity-100" thickness="3" color="primary"></v-divider>
                </v-row>
                <!---------------------------- 过滤条件 ---------------------------->
                <v-row no-gutters class="mt-16 pr-4 r-p-r-0">
                    <v-col cols="12">
                        <v-row no-gutters justify="end">
                            <v-col cols="12">
                                <div class="float-right">
                                    <!-- 区块链 -->
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn aria-label="men-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                                <v-icon end icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="changeBlockchainCondition(null)">
                                                <v-list-item-title>
                                                    <Blockchain text all-chains></Blockchain>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                                <v-list-item-title>
                                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- 排序 -->
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn  aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                                {{ sortCondition }}
                                                <v-icon end aria-label="mdi-menu-down" icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mb-8 mx-4 r-m-x-0"></v-divider>
                <v-row no-gutters v-infinite-scroll="getSetsByPage" :infinite-scroll-disabled="noMoreData">
                    <v-col cols="12">
                        <v-row class="pb-8" v-for="(set, i) in sets" :key="i">
                            <div class="c-container">
                                <v-card color="grey01" max-width="1400" rounded="0" class="pa-10" elevation="12">
                                    <div class="c-content c-grid">
                                        <div class="c-item">
                                            <div class="c-p">
                                            <div class="d-flex c-h-p">
                                                    <div class="c-m-b-auto">
                                                        <v-row align="center" no-gutters>
                                                            <div class="c-m-b-auto">
                                                                <router-link :to="'/set/' + set.id" class="del-underline pointer" v-if="set.logoImage">
                                                                    <v-img :src="set.logoImage" width="144" :aspect-ratio="1" cover></v-img>
                                                                </router-link>
                                                                <router-link :to="'/set/' + set.id" class="del-underline pointer" v-else-if="set.coverImage">
                                                                    <v-img :src="set.coverImage" width="144" :aspect-ratio="1" cover></v-img>
                                                                </router-link>
                                                                <router-link :to="'/set/' + set.id" class="del-underline pointer" v-else>
                                                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public" :lazy-src="lazy-src" width="144" :aspect-ratio="1" cover></v-img>
                                                                </router-link>
                                                            </div>
                                                            <div class="mt-6">
                                                                <div class="body-p-large fw-b">
                                                                    <v-card color="transparent" rounded="0" elevation="0" class="gradient-underline-hover">
                                                                        <router-link :to="'/set/' + set.id" class="del-underline text-white pointer">
                                                                           <h3 class="body-p-large fw-b gradient-text-hover">{{ set.name }}</h3>
                                                                        </router-link>
                                                                    </v-card>
                                                                </div>
                                                                <div class="c-b">
                                                                    <v-card elevation="0" max-width="400" class="pa-3 pl-0" rounded="0" color="transparent">
                                                                        <div class="d-flex flex-nowrap">
                                                                            <UserAvatar :avatar="set.user.avatar" :username="set.user.username" class="mr-2"></UserAvatar>
                                                                            <span class="gradient-underline-hover">
                                                                                <router-link @click.stop :to="'/profile/' + set.user.username" class="del-underline parent gradient-text-hover">
                                                                                    <span class="body-text-btn text-white ellipsis-line">{{ set.user.name }}</span>
                                                                                </router-link>
                                                                            </span>
                                                                        </div>
                                                                    </v-card>
                                                                </div>
                                                                <div class="algincenter">
                                                                    <v-icon icon="mdi mdi-eye-outline" size="18"></v-icon>
                                                                    <span class="body-p ml-2">
                                                                        <count-to :start-val="0" :end-val="set.stat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                                                                    </span>
                                                                    <v-icon v-show="!set.liked" icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer" @click.prevent="like(set)"></v-icon>
                                                                    <v-icon v-show="set.liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.prevent="unlike(set)"></v-icon>
                                                                    <span class="body-p ml-2 mr-6">
                                                                        <count-to :start-val="set.stat.likeCount" :end-val="set.stat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                                                                    </span>
                                                                    <v-icon icon="mdi mdi-format-list-bulleted-square" size="18" class="ml-2"></v-icon>
                                                                    <span class="body-p ml-2 mr-6">
                                                                        {{ set.summary.totalCount }} {{ set.summary.totalCount > 1 ? 'unNFTs' : 'unNFT' }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </v-row>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="c-b c-label c-gap c-grid">
                                            <div class="c-height" v-for="(nft, i) in set.nfts" :key="i">
                                                <UnNft :nftId="nft.id" @click.prevent></UnNft>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </v-row>
                        <!-- 加载中骨架 -->
                        <div v-show="loading">
                            <SkeletonSet :length="1"></SkeletonSet>
                        </div>
                    </v-col>
                </v-row>
                <!-- 无可用数据 -->
                <div v-show="noDataAvailable" class="my-10">
                    <NoDataAvailable></NoDataAvailable>
                </div>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UnNft from '@/components/nft/UnNft';
import SkeletonSet from '@/components/util/skeleton/SkeletonSet.vue';
import NFTAPI from '@/api/nft.js';
import SetAPI from '@/api/set.js';
import SetStatAPI from '@/api/set-stat.js';
import SetLikeAPI from '@/api/set-like.js';
export default {
    data(){
        return {
             // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Sales', 'Lowest Sales', 'Highest Views', 'Lowest Views'],
            sortCondition: 'Newest',
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            // 搜索结果列表
            sets: [],
            page: 1,
            size: 5,
        }
    },
    components: { UnNft, SkeletonSet },
    created(){
        // 监听刷新套装数据
        this.$bus.on('emitRefreshSet', data => {
            this.refresh();
        })
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain','responsive']),
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 分页查询套装列表
        async getSetsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await SetAPI.getSetsByPage(this.page, this.size, this.sortCondition, this.blockchain);
            let data = res.data;
            if(data.success) {
                let records = data.data.records;
                for(let i = 0, len = records.length; i < len; i++) {
                    // 查询统计数据
                    await this.getSetStat(records[i]);
                    // 查询概括数据
                    await this.getSummary(records[i]);
                    // 是否喜欢套装
                    await this.checkLiked(records[i]);
                    // 根据套装主键分页查询 NFT 列表
                    await this.getSetNFTsByPage(records[i]);
                }
                this.sets.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.sets.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.sets.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 查询统计数据
        async getSetStat(set) {
            let res = await SetStatAPI.setStat(set.id);
            let data = res.data;
            let stat = {};
            if(data.success) {
                stat = data.data;
            }
            set.stat = stat;
        },
        // 查询概括数据
        async getSummary(set) {
            let res = await SetStatAPI.summary(set.id);
            let data = res.data;
            let summary = {};
            if(data.success) {
                summary = data.data;
            }
            set.summary = summary;
        },
        // 是否喜欢套装
        async checkLiked(set) {
            let res = await SetLikeAPI.liked(set.id);
            let data = res.data;
            let liked = false;
            if(data.success) {
                liked = data.data;
            }
            set.liked = liked;
        },
        // 喜欢套装
        async like(set) {
            // 前端更新数据
            set.stat.likeCount++;
            set.liked = true;
            // 后端无感刷新
            let res = await SetLikeAPI.like(set.id);
            let data = res.data;
            // 若响应失败则返回状态
            if(!data.success) {
                set.stat.likeCount--;
                set.liked = false;
            }
        },
        // 取消喜欢套装
        async unlike(set) {
            // 前端更新数据
            set.stat.likeCount--;
            set.liked = false;
            // 后端无感刷新
            await SetLikeAPI.unlike(set.id);
        },
        // 根据套装主键分页查询 NFT 列表
        async getSetNFTsByPage(set) {
            let res = await NFTAPI.getSetNFTsByPage(set.id, 1, 3, null);
            let data = res.data;
            let nfts = [];
            if(data.success) {
                nfts = data.data.records;
            }
            set.nfts = nfts;
        },
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
        // 改变排序条件
        changeSortCondition(sortCondition) {
            // 恢复data定义的所有参数
            // Object.assign(this.$data, this.$options.data.call(this));
            // 改变条件
            this.sortCondition = sortCondition;
            // 刷新
            this.refresh();
        },
        // 刷新
        refresh() {
            this.loading = false;
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.sets = [];
            this.page = 1;
            // 重新查询数据
            this.getSetsByPage();
        }
    }
}
</script>
<style scoped>

</style>