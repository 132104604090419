<template>
	<div v-for="i in length" :key="i" class="py-8">
        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="6">
                <v-card class="bg-lightGray mt-5" rounded="0" elevation="0">
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public" :lazySrc="lazySrc" class="w-100" :aspect-ratio="1" cover></v-img>
                </v-card>
                <v-card class="bg-lightGray mt-2 w-100" height="40" rounded="0" elevation="0"></v-card>
                <div class="mt-2 d-flex float-right">
                    <div>
                        <div>
                            <v-card class="bg-lightGray" width="200" height="20" rounded="0" elevation="0"></v-card>
                        </div>
                        <div class="mt-1">
                            <v-card class="bg-lightGray" width="300" height="20" rounded="0" elevation="0"></v-card>
                        </div>
                   </div>
                   <div class="ml-5"><v-avatar class="bg-lightGray"></v-avatar></div>
                </div>
            </v-col>
            <v-col cols="12" sm="5" offset="1">
                <v-card class="bg-lightGray mt-5" rounded="0" elevation="0">
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public" :lazySrc="lazySrc" class="w-100" :aspect-ratio="1" cover></v-img>
                </v-card>
                <div class="d-flex justify-space-between mt-16">
                    <v-card class="bg-lightGray" width="182" height="48" rounded="0" elevation="0"></v-card>
                    <v-card class="bg-lightGray" width="182" height="48" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-9">
                    <v-card class="bg-lightGray" height="48" rounded="0" elevation="0"></v-card>
                </div>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {
            // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>