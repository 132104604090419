import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 获取登录用户推荐码
    getUserReferralCode() {
        if(!token) {
            return { data: { code: 200, success: false } }
        }
        return axios.request({
            url: '/user/referral-code/referral-code',
            method: 'get'
        })
    },
    // 获取推荐的用户数据
    getReferrals() {
        if(!token) {
            return { data: { code: 200, success: false } }
        }
        return axios.request({
            url: '/user/referral-code/referrals',
            method: 'get'
        })
    },
}