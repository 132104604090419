<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-5 r-m-l-2 text-primaryGrey">
                    <h4>Top Collectors</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 ml-5 r-m-l-0 mt-1 mb-5"></v-divider>
                <v-card class="ml-5 r-m-l-2" elevation="0"  v-for="collector in collectors" :key="collector.id" color="transparent">
                    <div class="mt-5">
                        <UserAvatar :avatar="collector.avatar" :username="collector.username" :name="collector.name"></UserAvatar>
                    </div>
                </v-card>
            </v-col> 
        </v-row>
	</div>
</template>
<script>
import UserAPI from '@/api/user.js';
export default {
    data(){
        return {
            collectors: []
        }
    },
    components: {  },
    created(){
        this.getTopCollectors();
    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 查询最佳的收藏家列表
        async getTopCollectors() {
            let res = await UserAPI.getTopCollectors();
            let data = res.data;
            if(data.success) {
                this.collectors = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>