<template>
	<div>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="12" class="align-self-center image-container px-0">
                <v-img  contain width="1080" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8e234c09-8c29-4f38-034c-fab9dfde6300/public"></v-img>
                <v-img  contain class="overlay-image" width="400" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a6c4c05e-b758-4d51-b017-636b98456d00/public"></v-img>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.image-container{
    position: relative;
}
.overlay-image {
  position: absolute;
  top: 100px;
  right: -40px;
}
</style>