<template>
	<div>
        <v-card max-width="500" elevation="0" rounded="0" color="transparent" class="text-primaryGrey">
            <v-row no-gutters>
                <v-col cols="12" sm="6" class="pa-0 mt-10">
                    <h3 class="body-text-btn">
                        Asset Details
                    </h3>
                </v-col>
                <v-divider class="my-4"></v-divider>
                <v-col cols="12" justify="between" class="body-p-small">
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-space-between">
                            <div class="form-text">Name</div>
                            <div>
                                <span class="gradient-underline-hover">
                                    <router-link :to="$tools.getUnNFTUrl(nft.blockchain, nft.id)" class="del-underline gradient-underline gradient-text-hover text-primaryGrey">
                                       {{ nft.name }}
                                    </router-link>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2" v-if="nft.set.id">
                            <div class="form-text">Set</div>
                            <div>
                                <span class="gradient-underline-hover">
                                    <router-link :to="'/set/' + nft.set.id" class="del-underline gradient-underline gradient-text-hover text-primaryGrey">
                                       {{ nft.set.name }}
                                    </router-link>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Contract Address</div>
                            <div>
                                <span class="gradient-underline-hover">
                                    <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/address/' + currentBlockchain.unNFTContract" class="gradient-underline gradient-text-hover text-primaryGrey" target="_blank">
                                        {{ $tools.privacyField(currentBlockchain.unNFTContract) }}
                                    </a>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Token ID</div>
                            <div>
                                <span class="gradient-underline-hover">
                                    <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/token/' + currentBlockchain.unNFTContract + '?a=' + nft.tokenId" class="gradient-underline gradient-text-hover text-primaryGrey" target="_blank">
                                        {{ nft.tokenId }}
                                    </a>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Token Standard</div>
                            <div>ERC721, EIP-5173</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Blockchain</div>
                            <div>{{ nft.blockchain }}</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Metadata</div>
                            <div>
                                <span class="gradient-underline-hover">
                                    <a :href="nft.metadata" class="gradient-underline gradient-text-hover text-primaryGrey" target="_blank">
                                        IPFS
                                    </a>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Created by</div>
                            <div class="d-flex flex-nowrap mt-2">
                                <span class="gradient-underline-hover mr-2">
                                    <router-link @click.stop :to="'/profile/' + nft.user.username" class="del-underline gradient-text-hover parent">
                                        <span class="body-p text-primaryGrey ellipsis-line">{{ nft.user.name  }}</span>
                                    </router-link>
                                </span>
                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :size="20"></UserAvatar>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-space-between mt-2">
                            <div class="form-text">Current owner</div>
                            <div class="d-flex flex-nowrap">
                                <span class="gradient-underline-hover mr-2">
                                    <router-link @click.stop :to="'/profile/' + nft.owner.username" class="del-underline gradient-text-hover">
                                        <span class="body-p text-primaryGrey">{{ nft.owner.name  }}</span>
                                    </router-link>
                                </span>
                                <UserAvatar :avatar="nft.owner.avatar" :username="nft.owner.username" :size="20"></UserAvatar>
                            </div>
                        </v-col>
                        <v-col cols="12" class="mt-16">
                            <NFTPriceLineChart :nftId="nftId" :tokenId="nft.tokenId"></NFTPriceLineChart>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js'
import NFTPriceLineChart from '@/components/charts/NFTPriceLineChart.vue'
export default {
    data(){
        return {
            // NFT 
            nft: {
                user: {},
                owner: {},
                set: {}
            },
            // 当前的区块链
            currentBlockchain: {},
        }
    },
    // NFT 主键
    props: {
        nftId: {
            type: String,
            default: null
        }
    },
    components: { NFTPriceLineChart },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['blockchains'])
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询 NFT
                this.getNFTById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据 NFT 主键查询 NFT
        async getNFTById() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.getNFTById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
    }
}
</script>
<style scoped>

</style>