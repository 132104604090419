<template>
	<div>
        <v-dialog v-model="reportCheatingDialog" content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <!-- REASON -->
                        <div v-if="action == 'REASON'">
                            <v-card-title class="d-flex px-0">
                                <v-icon size="28" class="text-primaryGrey" icon="mdi:mdi-alert-circle"></v-icon>
                                <v-spacer></v-spacer>
                                <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="reportCheatingDialog = false"></v-icon>
                            </v-card-title>
                            <h2 class="sidebar-h2 text-primaryGrey mt-3">Report Cheating</h2>
                            <v-divider class="my-4"></v-divider>
                            <div class="body-p text-primaryGrey">If you believe there’s been a violation of untrading.org’s Terms of Service or Community Guidelines, please complete this report.</div>
                            <div class="body-h7 text-primaryGrey mt-5">Reason</div>
                            <v-card class="pa-2 text-primaryGrey mt-3" rounded="0" elevation="2" color="white02">
                                <div class="d-flex align-center justify-space-between">
                                    <div>Wash trading</div>
                                    <div>
                                        <v-switch v-model="reportCheating.washTrading" color="primary" hide-details inset density="compact"></v-switch>
                                    </div>
                                </div>
                            </v-card>
                            <v-card class="pa-2 text-primaryGrey mt-3" rounded="0" elevation="2" color="white02">
                                <div class="d-flex align-center justify-space-between">
                                    <div>Related-persons trading</div>
                                    <div>
                                        <v-switch v-model="reportCheating.relatedPersonsTrading" color="primary" hide-details inset density="compact"></v-switch>
                                    </div>
                                </div>
                            </v-card>
                            <v-card class="pa-2 text-primaryGrey mt-3" rounded="0" elevation="2" color="white02">
                                <div class="d-flex align-center justify-space-between">
                                    <div>Multiple profiles by the same person</div>
                                    <div class="r-m-r-3">
                                        <v-switch v-model="reportCheating.multipleProfiles" color="primary" hide-details inset density="compact"></v-switch>
                                    </div>
                                </div>
                            </v-card>
                            <v-card class="pa-2 text-primaryGrey mt-3" rounded="0" elevation="2" color="white02">
                                <div class="d-flex align-center justify-space-between">
                                    <div>Other suspicious activity</div>
                                    <div>
                                        <v-switch v-model="reportCheating.otherSuspiciousActivity" color="primary" hide-details inset density="compact"></v-switch>
                                    </div>
                                </div>
                            </v-card>
                            <v-card class="pa-2 text-primaryGrey mt-3" rounded="0" elevation="2" color="white02">
                                <div class="d-flex align-center justify-space-between">
                                    <div>Counterfeit artwork</div>
                                    <div>
                                        <v-switch v-model="reportCheating.counterfeitArtwork" color="primary" hide-details inset density="compact"></v-switch>
                                    </div>
                                </div>
                            </v-card>
                            <v-card class="pa-2 text-primaryGrey mt-3" rounded="0" elevation="2" color="white02">
                                <div class="d-flex align-center justify-space-between">
                                    <div>Content violation</div>
                                    <div>
                                        <v-switch v-model="reportCheating.contentViolation" color="primary" hide-details inset density="compact"></v-switch>
                                    </div>
                                </div>
                            </v-card>
                            <div class="mt-16 mb-5 text-center">
                                 <a class="del-underline mr-8 gradient-left-red-purple-200">
                                    <v-btn aria-label="Continue" rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" @click="action = 'DESCRIPITON'">Continue</v-btn>
                                </a>
                            </div>
                        </div>
                        <!-- DESCRIPITON -->
                        <div v-if="action == 'DESCRIPITON'">
                            <v-icon size="28" class="text-primaryGrey ml-n1 gradient-text-hover" aria-label="chevron-left-circle" @click="action = 'REASON'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                            <h2 class="sidebar-h2 text-primaryGrey mt-3">Report Cheating</h2>
                            <v-divider class="my-4"></v-divider>
                            <div class="body-p text-primaryGrey">untrading.org does not tolerate any form of suspicious activity, Future Rewards hoarding through false transactions, and fake accounts.</div>
                            <!-- ------------- Email ------------- -->
                            <!-- <div class="body-h7 text-primaryGrey mt-5">Email<span class="ml-1 text-red">*</span></div> -->
                            <v-text-field v-model="reportCheating.email" variant="outlined" label="Email *" hide-details density="comfortable" class="mt-5"></v-text-field>
                            <!-- ------------- URL ------------- -->
                            <!-- <div class="body-h7 text-primaryGrey mt-5">URL to report<span class="ml-1 text-red">*</span></div> -->
                            <v-text-field v-model="reportCheating.url" variant="outlined" label="URL to report *" hide-details density="comfortable" class="mt-5"></v-text-field>
                            <!-- ------------- Description ------------- -->
                            <!-- <div class="body-h7 text-primaryGrey mt-5">Description</div> -->
                            <v-textarea clearable v-model="reportCheating.description" variant="outlined" label="Description" hide-details density="comfortable" class="mt-5"></v-textarea>
                            <div class="mt-5">
                                <span class="text-red mr-2">*</span>
                                <span class="body-p text-primaryGrey">Required fields</span>
                            </div>
                            <div class="mt-16 mb-5 text-center">
                                <a class="del-underline gradient-left-red-purple-200">
                                    <v-btn aria-label="Submit report" :loading="submitLoading" rounded="0" elevation="2" width="200" height="52" color="button01" class="text-none text-grey05 body-p-small-b" @click="submit">Submit report</v-btn>
                                </a>
                            </div>
                        </div>
                        <!-- SUCCESS -->
                        <div v-if="action == 'SUCCESS'">
                            <div class="sidebar-h2 text-primaryGrey">Thank you for reporting this!</div>
                            <v-divider class="my-4"></v-divider>
                            <div class="body-p text-primaryGrey">As a result of your notification, we are investigating a possible cheating case. If additional information is needed, we will contact you.</div>
                            <div class="body-p mt-3 text-primaryGrey">Our Discord channel at <a href="https://discord.gg/D5rZdhzVWh" class="del-underline text-primaryGrey gradient-underline" aria-label="Discord" target="_blank"><span class="gradient-text-hover">https://discord.gg/D5rZdhzVWh</span></a> allows you to add further information and track our investigation.</div>
                            <div class="mt-16 mb-5 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn :loading="submitLoading" aria-label="OK" rounded="0" elevation="2" width="100" height="52" color="button01" class="text-none text-grey05 body-p-small-b" @click="complete">OK</v-btn>
                                </a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ReportAPI from '@/api/report.js'
export default {
    data(){
        return {
            // 操作
            action: 'REASON',
            // 举报作弊弹窗
            reportCheatingDialog: false,
            // 举报作弊表单
            reportCheating: {
                email: null,
                url: window.location.href,
                description: null,
                washTrading: false,
                relatedPersonsTrading: false,
                multipleProfiles: false,
                otherSuspiciousActivity: false,
                counterfeitArtwork: false,
                contentViolation: false,
            },
            // 提交加载中
            submitLoading: false,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.reportCheating.url = window.location.href;
            },
            immediate: true
        },
        show: {
            handler(n, o) {
                if(n) {
                    this.reportCheating.email = (this.user ? this.user.email : null);
                    this.reportCheatingDialog = true;
                } else {
                    this.reportCheatingDialog = false;
                }
            },
            immediate: true
        },
        reportCheatingDialog: {
            handler(n, o) {
                if(!n) {
                    this.$emit('watchReportCheatingDialog', false);
                }
            },
            immediate: true
        },
    },
    methods: {
        // 提交
        async submit() {
            this.submitLoading = true;
            let res = await ReportAPI.reportCheating(this.reportCheating);
            let data = res.data;
            if(data.success) {
                this.action = 'SUCCESS';
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
            this.submitLoading = false;
        },
        // 完成
        complete() {
            // 关闭弹窗
            this.$emit('watchReportCheatingDialog', false);
            // 还原默认值
            setTimeout(() => {
                this.action = this.$options.data().action;
                this.reportCheating = this.$options.data().reportCheating;
            }, 500);
        },
        
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>