<template>
	<div>
        <v-container>
            <v-row no-gutters justify="center" align="center">
                <v-col>
                    <div>{{ blockchain }}</div>
                    <div>{{ address }}</div>
                    <div>{{ name }}</div>
                    <div>{{ symbol }}</div>
                    <div>{{ decimals }}</div>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
export default {
    data(){
        return {
            // 区块链
            blockchain: this.$route.params.blockchain,
            // 合约地址
            address: this.$route.params.address,
            name: null,
            symbol: null,
            decimals: 0,
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        this.getName();
        this.getSymbol();
        this.getDecimals();
    },
    computed: {
        ...mapGetters(['blockchains']),
    },
    watch:{

    },
    methods: {
        async getName() {
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain.toLowerCase() == this.blockchain?.toLowerCase())[0];
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            let contract = new web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.address);
            this.name = await contract.methods.name().call();
        },
        async getSymbol() {
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain.toLowerCase() == this.blockchain?.toLowerCase())[0];
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            let contract = new web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.address);
            this.symbol = await contract.methods.symbol().call();
        },
        async getDecimals() {
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain.toLowerCase() == this.blockchain?.toLowerCase())[0];
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            let contract = new web3.eth.Contract(this.currentBlockchain.ERC20StandardAbi, this.address);
            this.decimals = await contract.methods.decimals().call();
        },
        
    }
}
</script>
<style scoped>

</style>