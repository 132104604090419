<template>
	<div>
        <!-- ------------------------ Native Payment Token Rewards ---------------------------- -->
        <div class="mb-5 r-m-x-0">
            <v-row no-gutters class="r-p-r-0">
                <v-col cols="12">
                    <div class="text-primaryGrey body-h4">
                        Native Payment Token Rewards
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- 数据表格 -->
        <v-data-table-server width="100%" fluid :headers="headers" :items="nativeStats" :loading="nativeStatLoading" item-value="name" 
            class="body-p text-primaryGrey" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact">
            <template v-slot:headers>
                <tr class="body-h5">
                    <td class="px-0">unCrypto</td>
                    <td class="px-0">Payment Token</td>
                    <td class="px-0 text-right">TOTAL SALES VALUE</td>
                    <td class="px-0 text-right">TOTAL ORs EARNED</td>
                    <td class="px-0 text-right">TOTAL FRs EARNED</td>
                    <td class="px-0 text-right">TOTAL nFR PAID</td>
                    <td class="px-0 text-right">TOTAL UNCLAIMED ORs</td>
                    <td class="px-0 text-right">TOTAL UNCLAIMED FRs</td>
                </tr>
                <tr>
                    <td :colspan="headers.length" class="px-0 text-center">
                        <v-divider color="primaryGrey" :thickness="3"></v-divider>
                    </td>
                </tr>
            </template>
            <template v-slot:item="{ index, item }">
                <tr>
                    <td class="px-0">{{ item.wrappedCurrency }}</td>
                    <td class="px-0">{{ item.paymentToken }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalSalesValue, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalORsEarned, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalFRsEarned, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalNFRPaid, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">
                        <div class="gradient-left-red-purple-142">
                            <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="Claim ORs" @click="claimORStat = item; claimORDialog = true">
                                {{ $tools.formatNumber(item.totalUnclaimedORs, item.paymentTokenSizeDecimals) }}
                            </v-btn>
                        </div>
                    </td>
                    <td class="px-0 text-right">
                        <div class="gradient-left-red-purple-142">
                            <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="Claim FRs" @click="claimFRStat = item; claimFRDialog = true">
                                {{ $tools.formatNumber(item.totalUnclaimedFRs, item.paymentTokenSizeDecimals) }}
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </template>
            <template v-slot:loading>
                <!-- <tr>
                    <td :colspan="headers.length">
                        <div class="text-center">
                            <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="nativeStatLoading"></v-progress-circular>
                        </div>
                    </td>
                </tr> -->
                <v-skeleton-loader type="table-row@10" color="transparent"></v-skeleton-loader>
            </template>
            <template v-slot:no-data>
                <NoDataAvailable></NoDataAvailable>
            </template>
            <template v-slot:tfoot>
                <tr>
                    <td :colspan="headers.length" class="px-0">
                        <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                    </td>
                </tr>
            </template>
            <template v-slot:bottom>

            </template>
        </v-data-table-server>



        <!-- ------------------------ ERC-20 Payment Token Rewards ---------------------------- -->
        <div class="mt-10 mb-5 r-m-x-0">
            <v-row no-gutters class="mt-8 r-p-r-0">
                <v-col cols="12">
                    <div class="text-primaryGrey body-h4">
                        ERC-20 Payment Token Rewards
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- 数据表格 -->
        <v-data-table-server width="100%" fluid :headers="headers" :items="tokenStats" :loading="tokenStatLoading" item-value="name" 
            class="body-p text-primaryGrey" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact">
            <template v-slot:headers>
                <tr class="body-h5">
                    <td class="px-0">unCrypto</td>
                    <td class="px-0">Payment Token</td>
                    <td class="px-0 text-right">TOTAL SALES VALUE</td>
                    <td class="px-0 text-right">TOTAL REWARDs EARNED</td>
                    <td class="px-0 text-right">TOTAL nFR PAID</td>
                    <td class="px-0 text-right">TOTAL UNCLAIMED REWARDs</td>
                </tr>
                <tr>
                    <td :colspan="headers.length" class="px-0 text-center">
                        <v-divider color="primaryGrey" :thickness="3"></v-divider>
                    </td>
                </tr>
            </template>
            <template v-slot:item="{ index, item }">
                <tr>
                    <td class="px-0">{{ item.wrappedCurrency }}</td>
                    <td class="px-0">{{ item.paymentToken }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalSalesValue, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalRewardsEarned, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">{{ $tools.formatNumber(item.totalNFRPaid, item.paymentTokenSizeDecimals) }}</td>
                    <td class="px-0 text-right">
                        <div class="gradient-left-red-purple-142">
                            <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="Claim Rewards" @click="claimRewardStat = item; claimRewardDialog = true">
                                {{ $tools.formatNumber(item.totalUnclaimedRewards, item.paymentTokenSizeDecimals) }}
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </template>
            <template v-slot:loading>
                <!-- <tr>
                    <td :colspan="headers.length">
                        <div class="text-center">
                            <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="tokenStatLoading"></v-progress-circular>
                        </div>
                    </td>
                </tr> -->
                <v-skeleton-loader type="table-row@10" color="transparent"></v-skeleton-loader>
            </template>
            <template v-slot:no-data>
                <NoDataAvailable></NoDataAvailable>
            </template>
            <template v-slot:tfoot>
                <tr>
                    <td :colspan="headers.length" class="px-0">
                        <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                    </td>
                </tr>
            </template>
            <template v-slot:bottom>

            </template>
        </v-data-table-server>



        <!---------------------------- tab ---------------------------->
        <v-row no-gutters class="mt-10 r-p-x-4">
            <v-col cols="9">
                <v-tabs slider-color="primary" v-model="tab" class="w-90" density="compact">
                    <v-tab value="active" rounded="0" class="text-none px-0 mr-4" @click="$refs.active && $refs.active.changeCondition()">
                        <v-badge :content="nfrStat.active" inline>
                            <h4>Active nFRs</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="completed" rounded="0" class="text-none px-0 mr-4" @click="$refs.completed && $refs.completed.changeCondition()">
                        <v-badge :content="nfrStat.completed" inline>
                            <h4>Completed nFRs</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="wrapped" rounded="0" class="text-none px-0 mr-4" @click="$refs.wrapped && $refs.wrapped.changeCondition()">
                        <v-badge :content="nfrStat.wrapped" inline>
                            <h4>Wrapped nFRs</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="unwrapped" rounded="0" class="text-none px-0" @click="$refs.unwrapped && $refs.unwrapped.changeCondition()">
                        <v-badge :content="nfrStat.unwrapped" inline>
                            <h4>Unwrapped nFRs</h4>
                        </v-badge>
                    </v-tab>
                </v-tabs>
            </v-col>
            <!---------------------------- 过滤条件 ---------------------------->
            <v-col cols="2" offset="1">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn aria-label="menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p float-right" height="100%" v-bind="props">
                            {{ sortCondition }}
                            <v-icon end icon="mdi:mdi-menu-down" />
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                            <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-divider></v-divider>
        </v-row>
        <v-window v-model="tab">
            <!---------------------------- Active ---------------------------->
            <v-window-item value="active">
                <ActiveCryptoNFRs ref="active" :blockchain="blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></ActiveCryptoNFRs>
            </v-window-item>
            <!---------------------------- Completed ---------------------------->
            <v-window-item value="completed">
                <CompletedCryptoNFRs ref="completed" :blockchain="blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></CompletedCryptoNFRs>
            </v-window-item>
            <!---------------------------- Wrapped ---------------------------->
            <v-window-item value="wrapped">
                <WrappedCryptoNFRs ref="wrapped" :blockchain="blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></WrappedCryptoNFRs>
            </v-window-item>
            <!---------------------------- Unwrapped ---------------------------->
            <v-window-item value="unwrapped">
                <UnwrappedCryptoNFRs ref="unwrapped" :blockchain="blockchain" :sortBy="sortCondition" class="st--c-hlMbgQ"></UnwrappedCryptoNFRs>
            </v-window-item>
        </v-window>



        <!-- 领取 OR 弹窗 -->
        <ClaimOR :stat="claimORStat" :show="claimORDialog" @watchClaimORDialog="watchClaimORDialog" @emitGetDashboards="getCryptoNativeDashboards"></ClaimOR>
        <!-- 领取 FR 弹窗 -->
        <ClaimFR :stat="claimFRStat" :show="claimFRDialog" @watchClaimFRDialog="watchClaimFRDialog" @emitGetDashboards="getCryptoNativeDashboards"></ClaimFR>
        <!-- 领取奖励弹窗 -->
        <ClaimReward :stat="claimRewardStat" :show="claimRewardDialog" @watchClaimRewardDialog="watchClaimRewardDialog" @emitGetDashboards="getCryptoTokenDashboards"></ClaimReward>



	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ActiveCryptoNFRs from '@/components/user/dashboard/crypto/ActiveCryptoNFRs.vue';
import CompletedCryptoNFRs from '@/components/user/dashboard/crypto/CompletedCryptoNFRs.vue';
import WrappedCryptoNFRs from '@/components/user/dashboard/crypto/WrappedCryptoNFRs.vue';
import UnwrappedCryptoNFRs from '@/components/user/dashboard/crypto/UnwrappedCryptoNFRs.vue';
import ClaimOR from '@/components/user/dashboard/crypto/ClaimOR.vue';
import ClaimFR from '@/components/user/dashboard/crypto/ClaimFR.vue';
import ClaimReward from '@/components/user/dashboard/crypto/ClaimReward.vue';
import UserAPI from '@/api/user.js';
import CryptoNFRAPI from '@/api/crypto-nfr.js';
export default {
    data(){
        return {
            // 标签
            tab: this.$route.query?.tab?.toLowerCase(),
            // 表头
            headers: [{},{},{},{},{},{},{},{},{},{}],
            // 本地代币统计加载中
            nativeStatLoading: false,
            // 本地代币统计集合
            nativeStats: [],
            // ERC20 代币统计加载中
            tokenStatLoading: false,
            // ERC20 代币统计集合
            tokenStats: [],
            // 排序条件
            sortConditions: ['Newest', 'Oldest'],
            sortCondition: 'Newest',
            // NFR 统计
            nfrStat: {
                active: 0,
                completed: 0,
                wrapped: 0,
                unwrapped: 0
            },
            // 领取 OR 弹窗
            claimORDialog: false,
            // 领取 OR 统计
            claimORStat: {},
            // 领取 FR 弹窗
            claimFRDialog: false,
            // 领取 FR 统计
            claimFRStat: {},
            // 领取奖励弹窗
            claimRewardDialog: false,
            // 领取奖励统计
            claimRewardStat: {},
        }
    },
    components: { ActiveCryptoNFRs, CompletedCryptoNFRs, WrappedCryptoNFRs, UnwrappedCryptoNFRs, ClaimOR, ClaimFR, ClaimReward },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive', 'blockchain']),
    },
    watch:{
        // 监听区块链
        blockchain: {
            handler(n, o) {
                // 刷新
                this.changeBlockchainCondition();
            },
            immediate: true
        }
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition() {
            // 查询用户仪表盘加密货币本地代币统计数据
            this.getCryptoNativeDashboards();
            // 查询用户仪表盘加密货币 ERC20 代币统计数据
            this.getCryptoTokenDashboards();
            // 查询加密货币NFR统计数据
            this.getNfrStat();
        },
        // 改变条件
        changeSortCondition(sortCondition) {
            this.sortCondition = sortCondition;
        },
        // 查询用户仪表盘加密货币本地代币统计数据
        async getCryptoNativeDashboards() {
            this.nativeStats = [];
            this.nativeStatLoading = true;
            let res = await UserAPI.getCryptoNativeDashboards(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nativeStats = data.data;
            }
            this.nativeStatLoading = false;
        },
        // 查询用户仪表盘加密货币 ERC20 代币统计数据
        async getCryptoTokenDashboards() {
            this.tokenStats = [];
            this.tokenStatLoading = true;
            let res = await UserAPI.getCryptoTokenDashboards(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.tokenStats = data.data;
            }
            this.tokenStatLoading = false;
        },
        // 查询加密货币NFR统计数据
        async getNfrStat() {
            let res = await CryptoNFRAPI.getNfrStat(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfrStat = data.data;
            }
        },
        // 监听 OR 奖励弹窗
        watchClaimORDialog(show) {
            this.claimORDialog = show;
        },
        // 监听领取 FR 弹窗
        watchClaimFRDialog(show) {
            this.claimFRDialog = show;
        },
        // 监听领取奖励弹窗
        watchClaimRewardDialog(show) {
            this.claimRewardDialog = show;
        },
    }
}
</script>
<style scoped>
>>>.v-table__wrapper {
    min-width: 1200px;
}   
</style>