<template>
	<div>
        <!-- ----------------------- 不要遮罩层，可以展示视频 ------------------------ -->
        <div v-if="!overlay">
            <!-- ----------------------- 允许全屏 ------------------------ -->
            <div v-if="allowFullscreen" @click="handleFullScreen()" :class="isFullScreen ? 'zoom-out' : 'zoom-in'">
                <v-img v-if="fileType == 'image'" :id="nft.file" :src="fileUrl" :lazy-src="lazySrc" class="bg-lightGray" aspect-ratio="1" :cover="!isFullScreen">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular indeterminate color="primaryGrey"></v-progress-circular>
                        </div>
                    </template>
                </v-img>
                <MediaVideo v-if="fileType == 'video'" :src="nft.file"></MediaVideo>
            </div>
            <!-- ----------------------- 不允许全屏，跳转详情页面 ------------------------ -->
            <div v-if="!allowFullscreen">
                <router-link :to="$tools.getUnNFTUrl(nft.blockchain, nftId)" class="del-underline">
                    <v-img v-if="fileType == 'image'" :id="nft.file" :src="getThumbnail(nft.file)" :lazy-src="lazySrc" class="bg-lightGray" aspect-ratio="1" cover>
                        <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height">
                                <v-progress-circular indeterminate color="primaryGrey"></v-progress-circular>
                            </div>
                        </template>
                    </v-img>
                    <MediaVideo v-if="fileType == 'video'" :src="nft.file"></MediaVideo>
                </router-link>
            </div>
        </div>
        <!-- ----------------------- 需要遮罩层，不展示视频，视频显示预览封面图 ------------------------ -->
        <div v-if="overlay">
            <v-hover v-slot="{ isHovering, props }">
                <router-link :to="$tools.getUnNFTUrl(nft.blockchain, nftId)" class="del-underline">
                    <v-img :src="fileUrl" :lazy-src="lazySrc" aspect-ratio="1" cover v-bind="props" class="bg-lightGray" :class="[nft.sensitiveContent ? 'blur-picture' : '']">
                        <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height">
                                <v-progress-circular indeterminate color="primaryGrey"></v-progress-circular>
                            </div>
                        </template>
                        <div class="artwork-thumbnail__icon icon-size-16" v-if="fileType == 'video'">
                            <v-icon icon="mdi mdi-video" size="20"></v-icon>
                        </div>
                        <v-expand-transition>
                            <div v-show="isHovering" class="pa-4 bg-darkgray v-card--reveal" style="height:100%;">
                                <div class="ml-4">
                                    <router-link :to="'/set/' + nft.set.id" class="del-underline" v-show="nft.set.id">
                                        <v-card color="transparent" rounded="0" elevation="0">
                                            <div class="text-white pt-9 parent">
                                                <SetAvatar :avatar="nft.set.logoImage" :setId="nft.setId"></SetAvatar>
                                                <span class="body-text-btn ellipsis-line ml-4">{{ nft.set.name ? nft.set.name : '--' }}</span>
                                            </div>
                                        </v-card>
                                    </router-link>
                                </div>
                                <v-card elevation="0" color="transparent" class="absolute-bottom-30 ml-4">
                                    <div class="parent">
                                        <div class="nft-title text-white ellipsis-line">
                                            {{ nft.name }}
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </v-expand-transition>
                    </v-img>
                </router-link>
            </v-hover>
        </div>
    </div>
</template>
<script>
import NFTAPI from '@/api/nft.js';
import MediaVideo from '@/components/media/MediaVideo.vue';
export default {
    data(){
        return {
            // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
            // 加载中
            loading: false,
            // NFT 对象
            nft: {
                file: '',
                set: {},
                media: {}
            },
            // 文件类型：[image, video]，默认为图片
            fileType: 'image',
            // 文件链接，可以直接是原图片，也可以是视频封面图
            fileUrl: '',
            // 全屏
            isFullScreen: false,
        }
    },
    props: {
        // NFT 主键
        nftId: { type: String, default: null },
        // 遮罩层：视频显示预览封面图
        overlay: { type: Boolean, default: false },
        // 允许全屏：true - 可以全屏；false - 不允许全屏
        allowFullscreen: { type: Boolean, default: false },
    },
    components: { MediaVideo },
    created(){
        // 监听刷新套装数据
        this.$bus.on('emitRefreshSet', data => {
            this.getNFTMediaById();
        })
    },
    mounted(){

    },
    computed: {

    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据主键查询 NFT 媒体文件
                this.getNFTMediaById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询 NFT 媒体文件
        async getNFTMediaById() {
            if(this.nftId == null) {
                return;
            }
            this.loading = true;
            let res = await NFTAPI.getNFTMediaById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                let mimeTypes = this.nft.media.mimeType.split('/');
                this.fileType = mimeTypes[0];
                if(this.fileType == 'video') {
                    // 获取视频封面图片
                    this.getVideoPosterUrl(this.nft.file);
                } else {
                    // 图片直接加载完成
                    this.fileUrl = this.getThumbnail(this.nft.file);
                    this.loading = false;
                }
            }
        },
        // 获取视频封面图片
        getVideoPosterUrl(url) {
            // Cloudflare 的视频链接直接请求缩略图
            if(!this.allowFullscreen && url && url.indexOf('/watch') > -1) {
                // 视频封面图片
                this.fileUrl = url.replace('/watch', '/thumbnails/thumbnail.jpg?width=800&height=800');
                return;
            }
            // 创建视频元素
            let video = document.createElement("video");
            // 处理跨域
            video.setAttribute('crossOrigin', 'anonymous');
            video.setAttribute('src', url);
            // 设置默认1000px
            video.currentTime = 200;
            video.addEventListener('loadeddata', () => {
                video.setAttribute('crossOrigin', 'anonymous'); // 处理跨域
                let canvas = document.createElement("canvas"),
                // canvas的尺寸和图片一样
                width = video.videoWidth, 
                height = video.videoHeight;
                canvas.width = width;
                canvas.height = height;
                // 绘制canvas
                canvas.getContext("2d").drawImage(video, 0, 0, width, height);
                // 转换为base64
                let dataURL = canvas.toDataURL('image/jpeg');
                // 视频封面图片
                this.fileUrl = dataURL;
                // 加载完成
                this.loading = false;
            });
        },
        // 全屏预览
        handleFullScreen() {
            const isFullScreen = document.webkitIsFullScreen || document.mozFullScreen || false;
            const fullScreenPreview = document.getElementById(this.nft.file);
            if (isFullScreen) {
                this.fileUrl = this.getThumbnail(this.nft.file);
                document.exitFullscreen();
            } else {
                this.fileUrl = this.nft.file;
                fullScreenPreview.requestFullscreen();
            }
            this.isFullScreen = !isFullScreen;
        },
        // 获取缩略图
        getThumbnail(fileUrl) {
            // Cloudflare 的图片直接请求缩略图
            if(fileUrl && fileUrl.indexOf('/public') > -1) {
                return fileUrl.replace('/public', '/w=800,h=800,fit=cover,gravity=auto');
            }
            return fileUrl;
        }
    }
}
</script>
<style scoped>

</style>