import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 关注
    follow(data) {
        return axios.request({
            url: '/follow/follow',
            method: 'post',
            data: data
        })
    },
    // 取关
    unfollow(data) {
        return axios.request({
            url: '/follow/unfollow',
            method: 'post',
            data: data
        })
    },
    // 查询当前用户是否已关注对方
    followed(data) {
        if(!token) {
            return { data: { success: false } }
        }
        return axios.request({
            url: '/follow/followed',
            method: 'post',
            data: data
        })
    },
    // 根据用户名分页查询关注用户列表
    getFollowing(username, page, size) {
        return axios.request({
            url: '/follow/following',
            method: 'get',
            params: { 
                username: username,
                page: page,
                size: size
            }
        })
    },
    // 根据用户名分页查询粉丝用户列表
    getFollowers(username, page, size) {
        return axios.request({
            url: '/follow/followers',
            method: 'get',
            params: { 
                username: username,
                page: page,
                size: size
            }
        })
    },
}