const UsernameDirective = {
    // 在绑定元素的 attribute 前
    // 或事件监听器应用前调用
    created(el, binding, vnode, prevVnode) {
        let v = "";
        // 数组2个参数：[username, wallet]
        let valueArray = binding.value;
        if(valueArray != null && valueArray.length > 0) {
            if(valueArray.length == 1) {
                v = '@' + valueArray[0];
            } else {
                if(valueArray[0] == valueArray[1]) {
                    // 是钱包地址
                    if(valueArray[0].length > 10) {
                        v = '@' + valueArray[0].substring(0, 6) + "..." + valueArray[0].substring(valueArray[0].length - 4);
                    } else {
                        v = '@' + valueArray[0];
                    }
                } else {
                    // 是用户名
                    v = '@' + valueArray[0];
                }
            }
        }
        el.innerHTML = v;
    },
    // 在元素被插入到 DOM 前调用
    beforeMount(el, binding, vnode, prevVnode) {},
    // 在绑定元素的父组件
    // 及他自己的所有子节点都挂载完成后调用
    mounted(el, binding, vnode, prevVnode) {},
    // 绑定元素的父组件更新前调用
    beforeUpdate(el, binding, vnode, prevVnode) {},
    // 在绑定元素的父组件
    // 及他自己的所有子节点都更新后调用
    updated(el, binding, vnode, prevVnode) {},
    // 绑定元素的父组件卸载前调用
    beforeUnmount(el, binding, vnode, prevVnode) {},
    // 绑定元素的父组件卸载后调用
    unmounted(el, binding, vnode, prevVnode) {}
}

export default UsernameDirective
