<template>
	<div class="align-center pb-16" :class="isMe ? 'bg-lightGray' : 'bg-f2f'">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1440" class="mx-auto pt-8">
                <v-row no-gutters>
                    <v-col cols="12" v-if="responsive == 'pc'" class="pl-4">
                        <v-card elevation="0" color="transparent">
                            <v-row no-gutters>
                                <v-col cols="10">
                                    <v-card elevation="0" color="transparent">
                                        <v-row no-gutters >
                                            <v-col cols="4" class="ml-4">
                                                <v-icon size="24" class="text-primaryGrey gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <div class="body-h4 text-primaryGrey">
                                                    <h2>SET</h2>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pt-8" v-if="set.id != null">
                                            <!-- 基本信息 -->
                                            <v-col cols="12" class="ml-3 d-flex align-center">
                                                <h1 class="hero-h1 text-grey04">{{ set.name }}</h1>
                                                <span v-if="isMe">
                                                    <v-icon v-if="!isEditName" icon="fa-solid fa-pencil" class="pointer ml-5" size="24" @click="startEditName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-check" class="pointer ml-5" size="24" @click="editName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-close" class="pointer ml-5" size="24" @click="cancelEditName"></v-icon>
                                                </span>
                                            </v-col>
                                            <v-col cols="10" class="ml-3 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-text-field v-model="set.name" label="Name" variant="outlined" hide-details density="comfortable"></v-text-field>
                                            </v-col>
                                            <v-col cols="10" class="ml-3 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-textarea v-model="set.description" label="Description" variant="outlined" hint="" persistent-counter clearable :counter="1000"></v-textarea>
                                            </v-col>
                                            <v-col cols="11" class="body-p ml-4 mt-5 text-primaryGrey" v-if="!isEditName">
                                                <h5 class="pre-wrap" v-html="set.description"></h5>
                                            </v-col>
                                            <v-col cols="12" class="mt-8 ml-4">
                                                <v-card max-width="800" min-height="130" elevation="0" color="transparent" :rounded="0" class="d-flex justify-space-between">
                                                    <div class="body-text-btn">
                                                        <div class="text-primaryGrey">Blockchain</div>
                                                        <div class="text-primaryGrey">
                                                            <Blockchain :blockchain="set.blockchain" size="24" text></Blockchain>
                                                        </div>
                                                    </div>
                                                    <div class="body-text-btn" v-if="set.category">
                                                        <div class="text-primaryGrey">Category</div>
                                                        <div class="text-primaryGrey">{{ set.category }}</div>
                                                    </div>
                                                    <div class="body-text-btn">
                                                        <div class="text-primaryGrey">Collection of</div>
                                                        <div class="text-primaryGrey">{{ $tools.formatNumber(summary.collectionCount, 0) }}</div>
                                                    </div>
                                                    <div class="body-text-btn pointer gradient-text-hover" @click="ownersDialog = true">
                                                        <div class="text-primaryGrey">Owned by</div>
                                                        <div class="text-primaryGrey">{{ $tools.formatNumber(summary.ownerCount, 0) }}</div>
                                                    </div>
                                                    <div class="body-text-btn">
                                                        <div class="text-primaryGrey">Floor Price</div>
                                                        <div class="text-primaryGrey">{{ summary.floorPrice > 0 ? $tools.formatNumber(summary.floorPrice) : '--' }} {{ set.paymentToken }}</div>
                                                    </div>
                                                    <div class="body-text-btn">
                                                        <div class="text-primaryGrey">Total Sales</div>
                                                        <div class="text-primaryGrey">{{ summary.totalSales > 0 ? $tools.formatNumber(summary.totalSales) : '--' }} {{ set.paymentToken }}</div>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="2" class="pr-4 mt-2">
                                    <div class="float-right">
                                        <v-img :src="set.logoImage" width="176" height="176" cover></v-img>
                                        <div class="mt-9 float-right mr-n6">
                                            <v-icon icon="mdi mdi-eye-outline" class="text-primaryGrey" size="18"></v-icon>
                                            <span class="body-p-small ml-2 text-primaryGrey">
                                                <count-to :start-val="0" :end-val="setStat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                                            </span>
                                            <v-icon v-if="!liked"  icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer text-primaryGrey" @click.stop="like()"></v-icon>
                                            <v-icon v-if="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike()"></v-icon>
                                            <span class="body-p-small ml-2 mr-6 text-primaryGrey">
                                                <count-to :start-val="setStat.likeCount" :end-val="setStat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                                            </span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" v-if="responsive == 'mobile'">
                        <v-card elevation="0" color="transparent">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card elevation="0" color="transparent">
                                        <v-row no-gutters >
                                            <v-col cols="4" class="ml-2">
                                                <v-icon size="24" class="text-primaryGrey gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <div class="body-h4 text-primaryGrey">
                                                    <span>SET</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="pt-8">
                                            <v-col cols="11" class="mt-2 ml-2">
                                                <div>
                                                    <v-img :src="set.logoImage" width="176" height="176" cover></v-img>
                                                    <div class="mt-9">
                                                        <v-icon icon="mdi mdi-eye-outline" class="text-primaryGrey" size="18"></v-icon>
                                                        <span class="body-p-small ml-2 text-primaryGrey">
                                                            <count-to :start-val="0" :end-val="setStat.viewCount" :duration="4000" :decimals="0" separator=","></count-to>
                                                        </span>
                                                        <v-icon v-if="!liked"  icon="mdi mdi-heart-outline" size="18" class="ml-10 pointer text-primaryGrey" @click.stop="like()"></v-icon>
                                                        <v-icon v-if="liked" icon="mdi mdi-heart" size="18" class="ml-10 pointer" color="red" @click.stop="unlike()"></v-icon>
                                                        <span class="body-p-small ml-2 mr-6 text-primaryGrey">
                                                            <count-to :start-val="setStat.likeCount" :end-val="setStat.likeCount" :duration="1" :decimals="0" separator=","></count-to>
                                                        </span>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <!-- 基本信息 -->
                                            <v-col cols="11" class="sub-page-h1 ml-2 text-primaryGrey mt-8">
                                                {{ set.name }}
                                                <span v-if="isMe">
                                                    <v-icon v-if="!isEditName" icon="fa-solid fa-pencil" class="pointer ml-5" size="24" @click="startEditName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-check" class="pointer ml-5" size="24" @click="editName"></v-icon>
                                                    <v-icon v-if="isEditName" icon="fa-solid fa-close" class="pointer ml-5" size="24" @click="cancelEditName"></v-icon>
                                                </span>
                                            </v-col>
                                            <v-col cols="11" class="pl-2 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-text-field v-model="set.name" label="Set Name" variant="outlined" hide-details density="comfortable"></v-text-field>
                                            </v-col>
                                            <v-col cols="11" class="pl-2 mt-5 text-primaryGrey" v-if="isEditName">
                                                <v-textarea v-model="set.description" label="Description" variant="outlined" hint="" persistent-counter clearable :counter="1000"></v-textarea>
                                            </v-col>
                                            <v-col cols="11" class="body-p pl-2 mt-5 text-primaryGrey" v-if="!isEditName">
                                                <p class="pre-wrap" v-html="set.description"></p>
                                            </v-col>
                                            <v-col cols="12" class="mt-8 ml-2">
                                                <v-row no-gutters>
                                                    <v-col cols="6" sm="3">
                                                        <div class="body-text-btn">
                                                            <div class="text-primaryGrey">Blockchain</div>
                                                            <div class="text-primaryGrey">
                                                                <Blockchain :blockchain="set.blockchain" size="24" text></Blockchain>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6" sm="3">
                                                        <div class="body-text-btn" v-if="set.category">
                                                            <div class="text-primaryGrey">Category</div>
                                                            <div class="text-primaryGrey">{{ set.category }}</div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-8">
                                                    <v-col cols="6" sm="3">
                                                        <div class="body-text-btn">
                                                            <div class="text-primaryGrey">Collection of</div>
                                                            <div class="text-primaryGrey">{{ $tools.formatNumber(summary.collectionCount, 0) }}</div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6" sm="3">
                                                        <div class="body-text-btn pointer gradient-text-hover" @click="ownersDialog = true">
                                                            <div class="text-primaryGrey">Owned by</div>
                                                            <div class="text-primaryGrey">{{ $tools.formatNumber(summary.ownerCount, 0) }}</div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-8">
                                                    <v-col cols="6" sm="3">
                                                        <div class="body-text-btn">
                                                            <div class="text-primaryGrey">Floor Price</div>
                                                            <div class="text-primaryGrey">{{ summary.floorPrice > 0 ? $tools.formatNumber(summary.floorPrice) : '--' }} {{ set.paymentToken }}</div>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6" sm="3">
                                                        <div class="body-text-btn">
                                                            <div class="text-primaryGrey">Total Sales</div>
                                                            <div class="text-primaryGrey">{{ summary.totalSales > 0 ? $tools.formatNumber(summary.totalSales) : '--' }} {{ set.paymentToken }}</div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- <v-row no-gutters>
                        <v-col cols="12" class="fs-18">
                            <UserAvatar v-if="set.user" :avatar="set.user.avatar" :username="set.user.username" ></UserAvatar>
                            <span class="ml-3">{{ set.user.name }}</span>
                        </v-col>
                        <v-col cols="6" class="text-right">
                            <router-link to="/unnft/mint" class="del-underline">
                                <v-btn size="x-large" :rounded="0" height="66" class="text-none gradient-left-red-purple text-white">Add artworks to your set</v-btn>
                            </router-link>
                        </v-col>
                    </v-row> -->
                    <!-- NFT -->
                    <v-row no-gutters class="mt-9 pl-4 r-p-l-1">
                        <v-card class="w-100" elevation="0" color="transparent">
                            <v-row no-gutters>
                                <v-col cols="9" class="pl-4 r-p-l-1">
                                    <v-tabs slider-color="primary" v-model="tab" density="compact">
                                        <v-tab value="UnNFTs" class="px-0 text-primaryGrey">
                                            <v-badge :content="summary.totalCount" inline>
                                                <h4>UnNFTs</h4>
                                            </v-badge>
                                        </v-tab>
                                        <!-- <v-tab value="Descriptions" class="px-0 ml-4 text-primaryGrey">Descriptions</v-tab> -->
                                        <!-- <v-tab value="Activity" class="px-0 ml-4 text-primaryGrey">Activity</v-tab> -->
                                        <!-- <v-select v-model="sortCondition" :items="sortConditions" variant="plain" class=""></v-select> -->
                                    </v-tabs>
                                    </v-col>
                                    <v-col cols="2" offset="1" class="pr-2 r-p-r-4">
                                        <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn aria-label="menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p float-right" height="100%" v-bind="props">
                                                {{ sortCondition }}
                                                <v-icon end icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-divider class="mr-7 ml-4"></v-divider>
                            <v-card-text class="px-0 pt-3">
                                <v-window v-model="tab">
                                    <v-window-item value="UnNFTs">
                                        <v-row no-gutters>
                                            <v-col class="d-flex justify-end mr-5 mb-5" v-if="isMe">
                                                <router-link :to="'/unnft/mint?setId=' + setId" class="del-underline">
                                                    <v-menu open-on-hover location="top">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn v-bind="props" aria-label="mdi-image-plus-outline" :rounded="1" icon size="medium" variant="text" color="teal-darken-4">
                                                                <v-icon icon="mdi:mdi-image-plus-outline" size="36" class="text-primary"></v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card class="pa-3 text-primaryGrey" rounded="0">Create unNFT</v-card>
                                                    </v-menu>
                                                </router-link>
                                                <router-link :to="'/unnft/wrap?setId=' + setId" class="del-underline ml-3">
                                                    <v-menu open-on-hover location="top">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn v-bind="props" aria-label="image-refresh-outline" :rounded="1" icon size="medium" variant="text" color="teal-darken-4">
                                                                <v-icon icon="mdi:mdi-image-refresh-outline" size="36" class="text-primary"></v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card class="pa-3 text-primaryGrey" rounded="0">Wrap NFT</v-card>
                                                    </v-menu>
                                                </router-link>
                                            </v-col>
                                            <v-col cols="12" class="mb-5" :class="isMe ? '' : 'mt-14'" v-infinite-scroll="getSetNFTsByPage" :infinite-scroll-disabled="noMoreData">
                                                <v-row no-gutters class="flex-grow-1">
                                                    <div class="c-widths">
                                                        <div class="c-card c-grid d-flex flex-column">
                                                            <div class="c-height" v-for="(nft, i) in nfts" :key="i">
                                                                <UnNft :nftId="nft.id" @receiveCurrentPrice="receiveCurrentPrice"></UnNft>
                                                            </div>
                                                            <!-- 加载中骨架 -->
                                                            <SkeletonUnNFT :length="4" v-if="loading"></SkeletonUnNFT>
                                                        </div>
                                                    </div>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-window-item>
                                    <v-window-item value="Descriptions">
                                        <div class="body-p mt-10 text-primaryGrey">
                                            <p class="pre-wrap" v-html="set.description"></p>
                                        </div>
                                    </v-window-item>
                                    <v-window-item value="Activity">

                                    </v-window-item>
                                </v-window>
                            </v-card-text>
                        </v-card>
                    </v-row>
                </v-row>
           </v-card>
        </v-container>
        <!-- 套装中NFT的拥有者 -->
        <SetUnNftOwners :setId="setId" :show="ownersDialog" @closeOwnersDialog="closeOwnersDialog"></SetUnNftOwners>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SetAPI from '@/api/set.js';
import SetStatAPI from '@/api/set-stat.js';
import SetLikeAPI from '@/api/set-like.js';
import NFTAPI from '@/api/nft.js';
import UnNft from '@/components/nft/UnNft';
import SetUnNftOwners from '@/components/set/SetUnNftOwners';
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {
            // 套装主键
            setId: this.$route.params.id,
            // 套装对象
            set: {
                user: {}
            },
            // 标签
            tab: null,
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Price', 'Lowest Price', 'Highest Views', 'Lowest Views'],
            sortCondition: 'Newest',
            // 当前套装的NFT集合
            nfts: [],
            // 套装统计数据
            setStat: {
                viewCount: 0,
                likeCount: 0
            },
            // 套装概括数据
            summary: {},
            // 是否喜欢
            liked: false,
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            page: 1,
            size: 10,
            // 套装中NFT的拥有者用户弹窗
            ownersDialog: false,
            // 是否编辑名称
            isEditName: false,
            // 旧的套装信息
            oldSet: {
                name: null,
                description: null
            },
            // NFT 价格集合
            nftPrices: []
        }
    },
    components: { UnNft, SetUnNftOwners, SkeletonUnNFT },
    created(){
        this.getSetById();
        // 监听刷新套装数据
        this.$bus.on('emitRefreshSet', data => {
            this.getSetById();
        })
    },
    mounted(){
        this.view();
    },
    computed: {
        ...mapGetters(['user', 'responsive']),
        // 当前用户是自己
        isMe(){
            return this.set.user && this.set && this.set.user.id == this.user.id;
        }
    },
    watch:{
        setId: {
            handler(newVal, oldVal) {
                // 根据主键查询套装
                this.getSetById();
                // 查询统计数据
                this.getSetStat();
                // 查询概括数据
                this.getSummary();
                // 是否喜欢套装
                this.checkLiked();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询套装
        async getSetById() {
            let res = await SetAPI.getSetById(this.setId);
            let data = res.data;
            if(data.success) {
                // 更新对象
                this.set = data.data;
                // 更新标题
                this.$route.meta.name = this.set.name
            } else {
                // this.$store.dispatch('snackbarMessageHandler', data.message);;
                // 错误则导航到404
                this.$router.push('/404');
            }
        },
        // 根据套装主键查询所有 NFT 列表
        async getSetNFTs() {
            let res = await NFTAPI.getSetNFTs(this.setId);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data;
            }
        },
        // 根据套装主键分页查询 NFT 列表
        async getSetNFTsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await NFTAPI.getSetNFTsByPage(this.setId, this.page, this.size, this.sortCondition);
            let data = res.data;
            if(data.success) {
                this.summary.totalCount = data.data.total;
                this.nfts.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.nfts.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.nfts.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 查询概括数据
        async getSummary() {
            let res = await SetStatAPI.summary(this.setId);
            let data = res.data;
            if(data.success) {
                this.summary = data.data;
            }
        },
        // 浏览
        async view() {
            await SetStatAPI.view(this.setId);
        },
        // 查询统计数据
        async getSetStat() {
            let res = await SetStatAPI.setStat(this.setId);
            let data = res.data;
            if(data.success) {
                this.setStat = data.data;
            }
        },
        // 是否喜欢套装
        async checkLiked() {
            let res = await SetLikeAPI.liked(this.setId);
            let data = res.data;
            if(data.success) {
                this.liked = data.data;
            }
        },
        // 喜欢套装
        async like() {
            // 前端更新数据
            this.setStat.likeCount++;
            this.liked = true;
            // 后端无感刷新
            let res = await SetLikeAPI.like(this.setId);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getSetStat();
                // 是否喜欢套装
                this.checkLiked();
            } else {
                // 若响应失败则返回状态
                this.setStat.likeCount--;
                this.liked = false;
            }
        },
        // 取消喜欢套装
        async unlike() {
            // 前端更新数据
            this.setStat.likeCount--;
            this.liked = false;
            // 后端无感刷新
            let res = await SetLikeAPI.unlike(this.setId);
            let data = res.data;
            if(data.success) {
                // 查询统计数据
                this.getSetStat();
                // 是否喜欢套装
                this.checkLiked();
            }
        },
        // 改变条件
        changeSortCondition(sortCondition) {
            // 改变条件
            this.sortCondition = sortCondition;
            // 重置数据
            this.page = 1;
            this.nfts = [];
            this.noDataAvailable = false;
            this.noMoreData = false;
            // 重新查询数据
            this.getSetNFTsByPage();
        },
        // 关闭关注用户弹窗
        closeOwnersDialog(show) {
            this.ownersDialog = show;
        },
        // 开始编辑名称
        startEditName() {
            this.oldSet.name = this.set.name;
            this.oldSet.description = this.set.description;
            this.isEditName = true;
        },
        // 更新套装名称
        async editName() {
            let params = {
                setId: this.set.id,
                name: this.set.name,
                description: this.set.description
            };
            let res = await SetAPI.editName(params);
            let data = res.data;
            if(data.success) {
                this.isEditName = false;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 取消编辑名称
        cancelEditName() {
            this.set.name = this.oldSet.name;
            this.set.description = this.oldSet.description;
            this.isEditName = false;
        },
        // 接收每一个NFT的当前价
        receiveCurrentPrice(price) {
            if(price && Number(price) > 0) {
                this.nftPrices.push(Number(price));
            }
            this.summary.floorPrice = Math.min(...this.nftPrices);
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>