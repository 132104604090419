import axios from '@/config/api.request'

export default {
  // 通过 Twitter 验证账户
  verifyViaTwitter(data) {
    return axios.request({
        url: '/social/verify/twitter',
        method: 'post',
        data: data
    })
  },
  // 编辑登录用户社交信息
  update(data) {
    return axios.request({
        url: '/social/update',
        method: 'post',
        data: data
    })
  },
  // 同步 Twitter 个人资料
  syncTwitterProfile() {
    return axios.request({
        url: '/social/profile/sync/twitter',
        method: 'get'
    })
  },
}