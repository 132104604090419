<template>
	<div>
        <v-card max-width="414" min-height="500" rounded="0" elevation="2" class="pb-16" color="black">
            <div v-if="fileType == 'image'">
                <v-img :src="nft.file" height="414px" v-if="nft.file" cover></v-img>
            </div>
            <div v-else-if="fileType == 'video'">
                <MediaVideo :src="nft.file"></MediaVideo>
            </div>
            <div v-else>
                <v-img aspect-ratio="1" cover class="bg-lightGray"></v-img>
            </div>
            <!-- <el-skeleton animated v-if="!nft.file">
                <template #template>
                    <el-skeleton-item variant="image" class="w-100" style="height: 414px" />
                </template>
            </el-skeleton> -->
            <v-row no-gutters class="pa-8">
                <v-col cols="12">
                    <span class="nft-title text-primaryGrey">{{ nft.name }}</span>
                </v-col>
                <v-col cols="12" class="mt-6">
                    <v-row no-gutters>
                        <v-col cols="5">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Originator
                            </div>
                            <div v-if="nft.user" class="u-w">
                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                            </div>
                        </v-col>
                        <v-col cols="5" class="ml-4">
                            <div class="body-h5 mb-2 text-primaryGrey">
                                Set
                            </div>
                            <div class="u-w">
                                <SetAvatar :avatar="nft.set.logoImage" :setId="nft.setId" :name="nft.set.name ? nft.set.name : '--'"></SetAvatar>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <v-col cols="12" >
                    <div class="body-text-btn text-primaryGrey">Non-fungible Future Rewards (nFR) Parameters</div>
                </v-col>
                <v-col cols="12" class="mt-16">
                    <v-row no-gutters>
                        <v-col cols="12" md="5" sm="6">
                            <div class="form-text text-primaryGrey">Total Reward
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.totalReward') }} -->
                                        The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.totalRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" class="ml-6 r-m-l-0 r-m-t-8">
                            <div class="form-text text-primaryGrey">Future Rewards (FR)
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.futureRewards') }} -->
                                        Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ 100 - nft.originatorRewardRatio }}%</div>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="12" md="9" sm="6">
                            <div class="form-text text-primaryGrey">Originator Reward (OR)
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.originatorReward') }} -->
                                        The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.originatorRewardRatio }}%</div>
                            <v-divider class="w-50"></v-divider>
                        </v-col>
                         <v-col cols="5" class="mt-8">
                            <div class="form-text text-primaryGrey">OR Split</div>
                            <div class="my-2 body-p-small text-primaryGrey">100/0</div>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="9" class="mt-8">
                            <div class="form-text text-primaryGrey"># of generations
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        <!-- {{ $t('common.ofGenerations') }} -->
                                         The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ nft.numberOfGenerations }}</div>
                            <v-divider class="w-50"></v-divider>
                        </v-col>
                        <v-col cols="10" class="mt-8">
                            <div class="form-text text-primaryGrey">Common ratio of successive</div>
                            <div class="my-2 body-p-small text-primaryGrey">{{ $tools.calcCommonRatio(nft.numberOfGenerations) }}</div>
                            <v-divider class="w-50"></v-divider>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<script>
import NFTAPI from '@/api/nft.js';
import MediaVideo from '@/components/media/MediaVideo.vue';
export default {
    data(){
        return {
            nft: {
                set: {},
                media: {}
            },
            // 文件类型：[image, video]，默认为图片
            fileType: 'image',
        }
    },
    props: ['nftId'],
    components: { MediaVideo },
    created(){
        // 监听刷新套装数据
        this.$bus.on('emitRefreshSet', data => {
            this.getNFTById();
        })
    },
    mounted(){


    },
    computed: {

    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询 NFT
                this.getNFTById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据 NFT 主键查询 NFT
        async getNFTById() {
            if(this.nftId == null) {
                return;
            }
            let res = await NFTAPI.getNFTById(this.nftId);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                let mimeTypes = this.nft.media.mimeType.split('/');
                this.fileType = mimeTypes[0];
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        }
    }
}
</script>
<style scoped>

</style>