import { createRouter, createWebHistory } from 'vue-router'
import Page404 from '@/views/common/Page404'
// Home
import NFTs from '@/views/home/NFTs'
import Home from '@/views/home/Home'
import HomeV2 from '@/views/home/v2/HomeV2.vue'
import Search from '@/views/home/Search'
import Market from '@/views/home/Market'
import Sets from '@/views/home/Sets'
import Collections from '@/views/home/Collections'
// Wallet
import ConnectWallet from '@/views/wallet/ConnectWallet'
// User
import Profile from '@/views/user/Profile'
import ProfileEdit from '@/views/user/ProfileEdit'
import Dashboard from '@/views/user/Dashboard'
import Notifications from '@/views/user/Notifications'
import Settings from '@/views/user/Settings'
import VerifyViaTwitter from '@/views/user/VerifyViaTwitter'
import UserRewards from '@/views/user/UserRewards'
import UserRewardsKingdom from '@/views/user/UserRewardsKingdom'
import UserReferrals from '@/views/user/UserReferrals'
// NFT
import UnNFT from '@/views/nft/UnNFT'
import NFTImport from '@/views/nft/Import'
import NFTMint from '@/views/nft/Mint'
import NFTWrap from '@/views/nft/Wrap'
import NFTBuy from '@/views/nft/Buy'
import NFTList from '@/views/nft/List'
import NFTUnwrap from '@/views/nft/Unwrap'
import NFTTransfer from '@/views/nft/Transfer'
// Set
import Set from '@/views/set/Set'
import SetCreate from '@/views/set/Create'
// Collection
import Collection from '@/views/collection/Collection'
import CollectionCreate from '@/views/collection/Create'
// NFT Asset
import NFTOriginatorRewards from '@/views/asset/nft/OriginatorRewards'
import NFTFutureRewards from '@/views/asset/nft/FutureRewards'
import NFTNonFungibleFutureRewardsPayouts from '@/views/asset/nft/NonFungibleFutureRewardsPayouts'
// Crypto Asset
import CryptoOriginatorRewards from '@/views/asset/crypto/OriginatorRewards'
import CryptoFutureRewards from '@/views/asset/crypto/FutureRewards'
import CryptoNonFungibleFutureRewardsPayouts from '@/views/asset/crypto/NonFungibleFutureRewardsPayouts'
// Crypto
import UnCrypto from '@/views/crypto/UnCrypto'
import UnCryptos from '@/views/crypto/UnCryptos'
import UnCryptosSearch from '@/views/crypto/UnCryptosSearch'
import UnCryptosSearchResults from '@/views/crypto/UnCryptosSearchResults'
import CryptoBuy from '@/views/crypto/Buy'
import CryptoWrap from '@/views/crypto/Wrap'
import CryptoList from '@/views/crypto/List'
import CryptoUnwrap from '@/views/crypto/Unwrap'
// ERC20
import ERC20 from '@/template/ERC20'
// UN
import UN from '@/views/un/UN'

const routes = [
    
    // Home
    { path: '/:pathMatch(.*)', name:'Page404', component: Page404, 
        meta: { 
            title: '404 - Page Not Found | Profit Continues after Selling.', 
            description: 'Oops! Looks like you\'ve reached a page that doesn\'t exist. Explore untrading\'s Provenance Value Amplification (PVA) unNFT and unCrypto investing platform and discover a new world of transparent and inclusive crypto and DeFi opportunities. | DeFi investing, more for you is also more for me™'
        } 
    },
    { path: '/', name: 'Home', component: HomeV2, 
        meta: { 
            title: 'Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            keepAlive: true 
        } 
    },
    { path: '/unnfts', name: 'NFTs', component: NFTs, 
        meta: { 
            title: 'Discover unNFTs | Profit Continues after Selling.', 
            description: 'Explore the power of commission-free trading on untrading.org. With our cutting-edge Provenance Value Amplification (PVA) technology, users can benefit from future price increases, even after selling tokens, and enjoy a transparent and user-centric experience. | DeFi investing, more for you is also more for me™', 
            keepAlive: true 
        } 
    },
    { path: '/unnfts/search', name: 'Search', component: Search, 
        meta: { 
            title: 'Discover unNFTs | Profit Continues after Selling.', 
            description: 'Explore a diverse range of unNFTs on untrading\'s Search page. Embrace the power of Provenance Value Amplification (PVA) framework, where token owners continue to benefit from future price increases, even after selling their unNFTs. | DeFi investing, more for you is also more for me™', 
            keepAlive: true 
        } 
    },
    { path: '/market', name: 'Market', component: Market, 
        meta: { 
            title: 'unNFTs Market | Profit Continues after Selling.', 
            description: 'Discover the untrading Market on untrading.org. Leverage the innovative Provenance Value Amplification (PVA) framework to benefit from future price increases, even after selling tokens, and enjoy a fair and transparent crypto trading experience without fees. | DeFi investing, more for you is also more for me™',
            keepAlive: true 
        } 
    },
    { path: '/sets', name: 'Sets', component: Sets, 
        meta: { 
            title: 'Unique Sets on untrading: Unlock the Potential of unNFTs', 
            description: 'Discover exclusive unNFT Sets on untrading.org. Harness the power of commission-free unNFT trading with the Provenance Value Amplification (PVA) technology, and dive into a world of limitless possibilities for crypto asset ownership. | DeFi investing, more for you is also more for me™',
            keepAlive: true 
        } 
    },
    { path: '/collections', name: 'Collections', component: Collections, 
        meta: { 
            title: 'Unique Collections on untrading: Unlock the Potential of unNFTs', 
            description: 'Discover exclusive unNFT collections on untrading.org. Harness the power of commission-free unNFT trading with the Provenance Value Amplification (PVA) technology, and dive into a world of limitless possibilities for crypto asset ownership. | DeFi investing, more for you is also more for me™',
            keepAlive: true 
        } 
    },
	// Wallet
    { path: '/connectwallet', name: 'ConnectWallet', component: ConnectWallet, 
        meta: { 
            title: 'Connect Wallet | Profit Continues after Selling.',
            description: 'Seamlessly connect your wallet to untrading.org and experience the true power of commission-free trading. Join us now and explore the world of decentralized finance with transparency and freedom. Our cutting-edge Provenance Value Amplification (PVA) technology empowers crypto investors like never before, allowing you to benefit from future price increases even after selling tokens. | DeFi investing, more for you is also more for me™'
        } 
    },
	// User
    { path: '/profile/:username', name: 'Profile', component: Profile, 
        meta: { 
            title: 'User Profile | untrading: ', 
            description: 'Discover the public profile of a user on untrading\'s Provenance Value Amplification (PVA) crypto platform. Explore their unNFT collection, crypto token transaction history, and connect with the untrading community. | DeFi investing, more for you is also more for me™',
            keepAlive: true 
        } 
    },
    { path: '/edit', name: 'ProfileEdit', component: ProfileEdit, 
        meta: { 
            title: 'Edit Your Profile | Profit Continues after Selling.', 
            description: 'Customize your profile on untrading.org and open the door to endless opportunities. Embrace the future of commission-free trading and leverage our cutting-edge Provenance Value Amplification (PVA) technology to benefit from future price increases, even after selling tokens. | DeFi investing, more for you is also more for me™',
            authorization: true 
        } 
    },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, 
        meta: { 
            title: 'Dashboard | Profit Continues after Selling.', 
            description: 'Access your personalized all-in-one dashboard on untrading\'s Provenance Value Amplification (PVA) crypto platform. Monitor your crypto holdings, track performance, and make informed investment decisions. | DeFi investing, more for you is also more for me™',
            authorization: true, 
            verify: true, 
            keepAlive: true 
        } 
    },
    { path: '/notifications', name: 'Notifications', component: Notifications, 
        meta: { 
            title: 'Notifications | Profit Continues after Selling.', 
            description: 'Never miss a beat with our powerful notification system on untrading.org. Take control of your investments and stay informed at every crucial moment. With our cutting-edge Provenance Value Amplification (PVA) technology, you can receive alerts on potential future rewards, even after selling tokens. | DeFi investing, more for you is also more for me™',
            authorization: false 
        } 
    },
    { path: '/settings', name: 'Settings', component: Settings, 
        meta: { 
            title: 'Account Settings | Profit Continues after Selling.', 
            description: 'Manage your account settings on untrading\'s Provenance Value Amplification (PVA) NFT trading platform. Customize your preferences, security, and notification options. | DeFi investing, more for you is also more for me™',
            authorization: true 
        } 
    },
    { path: '/verify/twitter', name: 'VerifyViaTwitter', component: VerifyViaTwitter, 
        meta: { 
            title: 'Verify Via X | Profit Continues after Selling.', 
            description: 'Experience swift verification with X on untrading.org. Seamlessly link your account to enjoy instant access to our DeFi app. With our cutting-edge Provenance Value Amplification (PVA) technology, you\'ll benefit from future rewards, even after token sales. Join us now for a transparent and user-centric crypto investing journey. | DeFi investing, more for you is also more for me™',
            authorization: true 
        } 
    },
    { path: '/user/rewards', name: 'UserRewards', component: UserRewards, 
        meta: { 
            title: 'User Rewards | Profit Continues after Selling.', 
            description: 'Stay informed about your well-earned rewards! Access your detailed user rewards list effortlessly on untrading.org. With our innovative Provenance Value Amplification (PVA) technology, you can enjoy commission-free trading and still benefit from future price increases, even after selling tokens. Our user-centric platform ensures a fair and transparent crypto trading experience. Check your rewards now and embrace the world of DeFi investing, where more for you is also more for me™.',
            authorization: true 
        } 
    },
    { path: '/kingdom/rewards', name: 'UserRewardsKingdom', component: UserRewardsKingdom, 
        meta: { 
            title: 'Kingdom Rewards | Profit Continues after Selling.', 
            description: 'Stay informed about your well-earned rewards! Access your detailed user rewards list effortlessly on untrading.org. With our innovative Provenance Value Amplification (PVA) technology, you can enjoy commission-free trading and still benefit from future price increases, even after selling tokens. Our user-centric platform ensures a fair and transparent crypto trading experience. Check your rewards now and embrace the world of DeFi investing, where more for you is also more for me™.',
            authorization: true 
        } 
    },
    { path: '/user/referrals', name: 'UserReferrals', component: UserReferrals, 
        meta: { 
            title: 'User Referrals | Profit Continues after Selling.', 
            description: 'Become an investment leader with Untrading! Build your community from a simple street gang to a prominent House and even a Kingdom. Watch your influence (and title) rise – from Knight to King.',
            keepAlive: true,
            authorization: true 
        } 
    },
    // NFT
    { path: '/unnft/:blockchain/:id', name: 'UnNFT', component: UnNFT, 
        meta: { 
            title: 'unNFT Details | untrading: ', 
            description: 'Explore the details of an unNFT on untrading. Embrace the power of Provenance Value Amplification (PVA) investing, where token owners continue to benefit from future price increases, even after selling their unNFTs. | DeFi investing, more for you is also more for me™'
        } 
    },
    { path: '/unnft/import', name: 'NFTImport', component: NFTImport, 
        meta: { 
            title: 'Import Your unNFT | Profit Continues after Selling.', 
            description: 'Import an unNFTs on untrading\'s Provenance Value Amplification (PVA) NFT cryptos platform. | DeFi investing, more for you is also more for me™',
            authorization: true, 
            verify: true 
        } 
    },
    { path: '/unnft/mint', name: 'NFTMint', component: NFTMint, 
        meta: { 
            title: 'Mint Your unNFT | Profit Continues after Selling.', 
            description: 'Mintand and create unNFTs on untrading\'s Provenance Value Amplification (PVA) NFT cryptos platform. | DeFi investing, more for you is also more for me™',
            authorization: true, 
            verify: true 
        } 
    },
    { path: '/unnft/wrap', name: 'NFTWrap', component: NFTWrap, 
        meta: { 
            title: 'Wrap Your Existing NFT and Create an unNFT | Profit Continues after Selling.', 
            description: 'Wrap an NFT you own and upgrade it into an unNFT on untrading\'s Provenance Value Amplification (PVA) NFT cryptos platform. | DeFi investing, more for you is also more for me™',
            authorization: true, 
            verify: true 
        } 
    },
    { path: '/unnft/buy/:id', name: 'NFTBuy', component: NFTBuy, 
        meta: { 
            title: 'Buy Now | Profit Continues after Selling.', 
            description: 'Seize the opportunity to buy now and hold unNFT. Plus, don\'t miss the chance to list your unNFT on untrading and unlock additional future rewards. With our cutting-edge Provenance Value Amplification (PVA) technology, you can enjoy commission-free trading and benefit from potential price increases, even after selling tokens. | DeFi investing, more for you is also more for me™.',
            authorization: true, 
            verify: true 
        } 
    },
    { path: '/unnft/list/:id', name: 'NFTList', component: NFTList, 
        meta: { 
            title: 'List on untrading | Profit Continues after Selling.', 
            description: 'Don\'t let your unNFT sit idle in your wallet any longer. Take action now and list it on untrading.org.  At untrading, we revolutionize crypto investing with our cutting-edge Provenance Value Amplification (PVA) technology. This means that even after selling your unNFT, you can still benefit from any future price increases. | DeFi investing, more for you is also more for me™.',
            authorization: true, 
            verify: true 
        } 
    },
    { path: '/unnft/unwrap/:id', name: 'NFTUnwrap', component: NFTUnwrap, 
        meta: { 
            title: 'Unwrap an unNFT | Profit Continues after Selling.', 
            description: 'Whether it\'s a prized artwork, a rare collectible, or an exclusive digital asset, our cutting-edge technology allows you to wrap and explore the true value of your NFTs like never before. At untrading, we\'ve harnessed the potential of Provenance Value Amplification (PVA) to create a one-of-a-kind experience. Join us at untrading.org and revolutionize your NFT experience. | DeFi investing, more for you is also more for me™.',
            authorization: true, 
            verify: true 
        } 
    },
    { path: '/unnft/transfer/:id', name: 'NFTTransfer', component: NFTTransfer, 
        meta: { 
            title: 'Transfer an unNFT | Profit Continues after Selling.', 
            description: 'Experience the future of digital asset transfer with untrading\'s innovative unNFT platform. Effortlessly move and manage your prized NFTs from one digital wallet to another, regardless of where they originated. At untrading, we\'ve harnessed the power of Provenance Value Amplification (PVA) to create a revolutionary platform. Join us at untrading.org and revolutionize your NFT experience. DeFi investing, more for you is also more for me™.',
            authorization: true, 
            verify: true 
        } 
    },
    // Set
    { path: '/set/:id', name: 'Set', component: Set, 
        meta: { 
            title: 'Set Details | untrading: ', 
            description: 'Explore the details of a Set on untrading. Embrace the power of Provenance Value Amplification (PVA) investing, where token owners continue to benefit from future price increases, even after selling their unNFTs. | DeFi investing, more for you is also more for me™'
        } 
    },
    { path: '/set/create', name: 'SetCreate', component: SetCreate, 
        meta: { 
            title: 'Create A Unique Set | Profit Continues after Selling.', 
            description: 'Create A Unique Set on untrading\'s Provenance Value Amplification (PVA) NFT cryptos platform. | DeFi investing, more for you is also more for me™',
            authorization: true, 
            verify: true 
        } 
    },
    // Collection
    { path: '/collection/:id', name: 'Collection', component: Collection, 
        meta: { 
            title: 'Collection Details | untrading: ', 
            description: 'Explore the details of a Collection on untrading. Embrace the power of Provenance Value Amplification (PVA) investing, where token owners continue to benefit from future price increases, even after selling their unNFTs. | DeFi investing, more for you is also more for me™'
        } 
    },
    { path: '/collection/create', name: 'CollectionCreate', component: CollectionCreate, 
        meta: { 
            title: 'Create A Unique Collection | Profit Continues after Selling.', 
            description: 'Create A Unique Collection) on untrading\'s Provenance Value Amplification (PVA) NFT cryptos platform. | DeFi investing, more for you is also more for me™',
            authorization: true, 
            verify: true 
        } 
    },
    // unnft Asset
    { path: '/dashboard/asset/unnft/ors/received/:nftId', name: 'NFTOriginatorRewards', component: NFTOriginatorRewards, 
        meta: { 
            title: 'ORs Payments | Profit Continues after Selling.', 
            description: 'Experience the cutting-edge ORs Payments feature on the unNFT platform. Embrace the future of seamless transactions with our innovative payment solution, empowering you with efficient and secure crypto payments. Say goodbye to traditional barriers and hello to a user-centric experience that revolutionizes the way you transact with cryptocurrencies. | DeFi investing, more for you is also more for me™.',
            authorization: true 
        } 
    },
    { path: '/dashboard/asset/unnft/frs/received/:nftId', name: 'NFTFutureRewards', component: NFTFutureRewards, 
        meta: { 
            title: 'FRs Payments | Profit Continues after Selling.', 
            description: 'Experience the cutting-edge FRs Payments feature on the unNFT platform. Embrace the future of seamless transactions with our innovative payment solution, empowering you with efficient and secure crypto payments. Say goodbye to traditional barriers and hello to a user-centric experience that revolutionizes the way you transact with cryptocurrencies. | DeFi investing, more for you is also more for me™.',
            authorization: true 
        } 
    },
    { path: '/dashboard/asset/unnft/nfrs/paid/:nftId', name: 'NFTNonFungibleFutureRewardsPayouts', component: NFTNonFungibleFutureRewardsPayouts, 
        meta: { 
            title: 'nFRs Payouts | Profit Continues after Selling.', 
            description: 'Experience the cutting-edge nFRs Payouts feature on the unNFT platform. Embrace the future of seamless transactions with our innovative payment solution, empowering you with efficient and secure crypto payments. Say goodbye to traditional barriers and hello to a user-centric experience that revolutionizes the way you transact with cryptocurrencies. | DeFi investing, more for you is also more for me™.',
            authorization: true 
        } 
    },
    // uncrypto Asset
    { path: '/dashboard/asset/uncrypto/ors/received/:cryptoId', name: 'CryptoOriginatorRewards', component: CryptoOriginatorRewards, 
        meta: { 
            title: 'ORs Payments | Profit Continues after Selling.', 
            description: 'Experience the cutting-edge ORs Payments feature on the unCrypto platform. Embrace the future of seamless transactions with our innovative payment solution, empowering you with efficient and secure crypto payments. Say goodbye to traditional barriers and hello to a user-centric experience that revolutionizes the way you transact with cryptocurrencies. | DeFi investing, more for you is also more for me™.',
            authorization: true 
        } 
    },
    { path: '/dashboard/asset/uncrypto/frs/received/:cryptoId', name: 'CryptoFutureRewards', component: CryptoFutureRewards, 
        meta: { 
            title: 'FRs Payments | Profit Continues after Selling.', 
            description: 'Experience the cutting-edge FRs Payments feature on the unCrypto platform. Embrace the future of seamless transactions with our innovative payment solution, empowering you with efficient and secure crypto payments. Say goodbye to traditional barriers and hello to a user-centric experience that revolutionizes the way you transact with cryptocurrencies. | DeFi investing, more for you is also more for me™.',
            authorization: true 
        } 
    },
    { path: '/dashboard/asset/uncrypto/nfrs/paid/:cryptoId', name: 'CryptoNonFungibleFutureRewardsPayouts', component: CryptoNonFungibleFutureRewardsPayouts, 
        meta: { 
            title: 'nFRs Payouts | Profit Continues after Selling.', 
            description: 'Experience the cutting-edge nFRs Payouts feature on the unCrypto platform. Embrace the future of seamless transactions with our innovative payment solution, empowering you with efficient and secure crypto payments. Say goodbye to traditional barriers and hello to a user-centric experience that revolutionizes the way you transact with cryptocurrencies. | DeFi investing, more for you is also more for me™.',
            authorization: true 
        } 
    },
    // unCrypto
    { path: '/uncryptos', name: 'UnCryptos', component: UnCryptos, 
        meta: { 
            title: 'unCryptos Market | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            keepAlive: true 
        } 
    },
    {
        path: '/uncryptos/search', name:'UnCryptosSearch', component: UnCryptosSearch,
        meta: { 
            title: 'Discover unCryptos | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            keepAlive: true 
        } 
    },
    { path: '/uncryptos/search/results', name: 'UnCryptosSearchResults', component: UnCryptosSearchResults, 
        meta: { 
            title: 'Discover unCryptos | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
        } 
    },
    { path: '/uncrypto/:wrappedCurrency/:id', name: 'UnCrypto', component: UnCrypto, 
        meta: { 
            title: 'unCrypto Details | untrading: ', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
        } 
    },
    { path: '/uncrypto/buy/:id', name: 'CryptoBuy', component: CryptoBuy, 
        meta: { 
            title: 'Buy Now | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            authorization: true, 
            verify: true 
        }  
    },
    { path: '/uncrypto/wrap', name: 'CryptoWrap', component: CryptoWrap, 
        meta: { 
            title: 'Wrap Your Crypto | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
        }  
    },
    { path: '/uncrypto/list/:id', name: 'CryptoList', component: CryptoList, 
        meta: { 
            title: 'List on untrading | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            authorization: true, 
            verify: true 
        }  
    },
    { path: '/uncrypto/unwrap/:id', name: 'CryptoUnwrap', component: CryptoUnwrap, 
        meta: { 
            title: 'Unwrap an unCrypto | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            authorization: true, 
            verify: true 
        }  
    },
    // ERC20
    { path: '/erc20/:blockchain/:address', name: 'ERC20', component: ERC20, 
        meta: { 
            title: 'ERC20 | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.', 
            authorization: false, 
            verify: false 
        }  
    },
    // UN
    { path: '/un', name: 'UN', component: UN, 
        meta: { 
            title: 'Claim Your UN Tokens | Profit Continues after Selling.', 
            description: 'Unlock the future with Untrading. Imagine earning future price increase rewards from assets you no longer own.'
        } 
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const HOME_PAGE_NAME = 'Home'
const CONNECT_WALLET_PAGE_NAME = 'ConnectWallet'
const VERIFY_PAGE_NAME = 'VerifyViaTwitter'

// 判断登录权限
router.beforeEach((to, from, next) => {
    // 路由时关闭所有 v-menu 组件
    const menus = document.querySelectorAll('.v-menu')
    menus.forEach(menu => {
        menu.style.display = 'none'
    })
    // 当前是登录页，还要去登录页，则拒绝
    if(from.name === CONNECT_WALLET_PAGE_NAME && to.name === CONNECT_WALLET_PAGE_NAME) {
        return;
    }
    // 获取认证令牌
    const token = localStorage.getItem("untrading-token");
    // 已经登录，并且还要去登录页
    if(token) {
        // 已经登录，从别的页面跳转到登录页，有来源页则返回原页面，没有来源页则跳转到首页
        if(to.name === CONNECT_WALLET_PAGE_NAME) {
            if(from.name) {
                next({ name: from.name })
            } else {
                next({ name: HOME_PAGE_NAME })
            }
        }
        // 要去的是需要验证的页面
        if(to.meta.verify) {
            const user = JSON.parse(localStorage.getItem('untrading-user'));
            if(!user.verified) {
                // 登录的是“未验证”的用户，则跳转到验证页
                next({ name: VERIFY_PAGE_NAME })
            } else {
                // 否则直接通行
                next();
            }
        }
    }
    // 未登录，且去的是需要认证的页面，则跳转到登录页
    if(!token && to.meta.authorization) {
        // 跳转到登录页
        next({
            name: CONNECT_WALLET_PAGE_NAME,
            query: {
                redirectUrl: window.location.origin + to.fullPath,
            }
        });
    } else {
        // 否则直接通行
        next();
    }
})

router.afterEach(to => {
    window.scrollTo(0, 0)

    document.title = to.meta.title;
    
    document.querySelector('link[rel="canonical"]').href = location.href;

    document.querySelector('meta[name="title"]').content = to.meta.title;
    document.querySelector('meta[name="description"]').content = to.meta.description;

    document.querySelector('meta[property="og:title"]').content = to.meta.title;
    document.querySelector('meta[property="og:description"]').content = to.meta.description;
    document.querySelector('meta[property="og:url"]').content = location.href;

    document.querySelector('meta[property="twitter:title"]').content = to.meta.title;
    document.querySelector('meta[property="twitter:description"]').content = to.meta.description;
    document.querySelector('meta[property="twitter:url"]').content = location.href;

})

export default router
