<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 r-m-l-0 ml-5 text-primaryGrey">
                    Featured Releases
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 r-m-l-0 mt-1 mb-10"></v-divider>
                <v-carousel v-model="carouselIndex" :continuous="false" :show-arrows="false" interval="5000" :height="height" hide-delimiter-background color="d7d" delimiter-icon="mdi:mdi-circle" class="carousel-item-active">
                    <v-carousel-item v-for="nft in nfts" :key="nft.id">
                        <v-card id="featured-releases-card" class="ml-5 r-m-l-0" rounded="0" elevation="0" color="transparent">
                            <MediaFile :nftId="nft.id" overlay class="r-m-r-1"></MediaFile>
                            <div class="mt-6 body-h4 gradient-underline-hover">
                                <router-link :to="$tools.getUnNFTUrl(nft.blockchain, nft.id)" class="del-underline">
                                    <span class="text-primaryGrey gradient-text-hover pointer">{{ nft.name }}</span>
                                </router-link>
                            </div>
                            <div class="mt-6" v-if="nft.user">
                                <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                            </div>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            nfts: [],
            // 轮播图的下标
            carouselIndex: 0,
            // 默认高度，根据数据动态计算
            // 544 高 = 484 内容 + 10 间距 + 50 轮播
            height: 544
        }
    },
    components: { MediaFile },
    created(){
        this.getFeatured();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchain'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.getFeatured();
        }
    },
    methods: {
        // 查询精选的 NFT 列表
        async getFeatured() {
            let res = await NFTAPI.getFeatured(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data;
            }
            setTimeout(() => {
                let featuredReleasesCard = document.getElementById('featured-releases-card');
                if(featuredReleasesCard) {
                    let contentHeight = featuredReleasesCard.height || featuredReleasesCard.clientHeight || featuredReleasesCard.offsetHeight || featuredReleasesCard.scrollHeight || this.height;
                    this.height = contentHeight + 10 + 50;
                }
            }, 1)
        }
    }
}
</script>
<style scoped>

</style>