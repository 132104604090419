import axios from '@/config/api.request'

export default {
    // 记录一次访问
    visit() {
        return axios.request({
            url: '/visit/visit',
            method: 'get'
        })
    }
}