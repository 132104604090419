<template>
	<div class="mt-10">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-5 r-m-l-0 text-primaryGrey">
                    <h4>Top Flows</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 r-m-l-0 mt-1 mb-10"></v-divider>
                <div v-if="loading">
                    <SkeletonCard :height="380"></SkeletonCard>
                </div>
                <div v-if="!loading">
                    <v-carousel v-model="carouselIndex" :continuous="false" :show-arrows="false" interval="5000" :height="height" hide-delimiter-background color="d7d" delimiter-icon="mdi:mdi-circle" class="carousel-item-active">
                        <v-carousel-item v-for="nft in nfts" :key="nft.id">
                            <v-card id="top-flows-card" class="ml-5 r-m-l-0" rounded="0" elevation="0" color="transparent">
                                <MediaFile :nftId="nft.id" overlay class="r-m-r-1"></MediaFile>
                                <div class="mt-6 body-h4 gradient-underline-hover">
                                    <router-link :to="$tools.getUnNFTUrl(nft.blockchain, nft.id)" class="del-underline">
                                        <span class="text-primaryGrey gradient-text-hover pointer">{{ nft.name }}</span>
                                    </router-link>
                                </div>
                                <div class="mt-6" v-if="nft.user">
                                    <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                                </div>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js';
import UserAPI from '@/api/user.js';
import MediaFile from '@/components/media/MediaFile.vue';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard';
export default {
    data(){
        return {
            loading: false,
            nfts: [],
            // 轮播图的下标
            carouselIndex: 0,
            // 默认高度，根据数据动态计算
            // 544 高 = 484 内容 + 10 间距 + 50 轮播
            height: 544
        }
    },
    components: { MediaFile, SkeletonCard },
    created(){
        this.getTopFlows();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchain'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.getTopFlows();
        }
    },
    methods: {
        // 查询 Flows 最高的 NFT 列表
        async getTopFlows() {
            this.loading = true;
            this.nfts = [];
            let res = await NFTAPI.getTopFlows(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data;
            }
            this.loading = false;
            setTimeout(() => {
                let topFlowsCard = document.getElementById('top-flows-card');
                if(topFlowsCard) {
                    let contentHeight = topFlowsCard.height || topFlowsCard.clientHeight || topFlowsCard.offsetHeight || topFlowsCard.scrollHeight || this.height;
                    this.height = contentHeight + 10 + 50;
                }
            }, 1)
        },
        // 分页查询 NFT 列表
        async getNFTsByPage() {
            let res = await NFTAPI.getNFTsByPage(1, 3, null, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data.records;
                for(let i = 0, len = this.nfts.length; i < len; i++) {
                    let userRes = await UserAPI.getSimpleUserById(this.nfts[i].userId);
                    let userData = userRes.data;
                    if(userData.success) {
                        this.nfts[i].user = userData.data;
                    }
                }
            }
        },
    }
}
</script>
<style scoped>

</style>