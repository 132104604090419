<template>
	<div>
        <v-menu location="top right">
            <template v-slot:activator="{ props }">
                <v-icon icon="fa-solid fa-ellipsis" color="primaryGrey" v-bind="props"></v-icon>
            </template>                    
            <v-list>

                <!-- --------------- List --------------- -->
                <router-link :to="'/unnft/list/' + nftId" class="del-underline" v-if="isOwner && !isListed">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>List</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="isOwner && !isListed"></v-divider>

                <!-- --------------- Change price --------------- -->
                <router-link :to="'/unnft/list/' + nftId" class="del-underline" v-if="isOwner && isListed">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>Change price</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="isOwner && isListed"></v-divider>

                <!-- --------------- Unlist --------------- -->
                <router-link :to="'/unnft/list/' + nftId" class="del-underline" v-if="isOwner && isListed">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>Unlist</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="isOwner && isListed"></v-divider>

                <!-- --------------- Transfer --------------- -->
                <router-link :to="'/unnft/transfer/' + nftId" class="del-underline" v-if="isOwner">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>Transfer</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="isOwner"></v-divider>

                <!-- --------------- Make Set picture --------------- -->
                <v-list-item class="form-text text-primaryGrey pointer" @click="makeSetPicture()" v-if="isOriginator">
                    <v-list-item-title>Make Set picture</v-list-item-title>
                </v-list-item>
                <v-divider v-if="isOriginator"></v-divider>

                <!-- --------------- Set --------------- -->
                <v-list-item class="form-text text-primaryGrey" v-if="isOriginator">
                    <v-list-item-title>
                        <UnNftSetMenu :nftId="nftId"></UnNftSetMenu>
                    </v-list-item-title>
                </v-list-item>
                <v-divider v-if="isOriginator"></v-divider>

                <!-- --------------- Collection --------------- -->
                <v-list-item class="form-text text-primaryGrey" v-if="isOwner">
                    <v-list-item-title>
                        <UnNftCollectionMenu :nftId="nftId"></UnNftCollectionMenu>
                    </v-list-item-title>
                </v-list-item>
                <v-divider v-if="isOwner"></v-divider>

                <!-- --------------- Unwrap --------------- -->
                <router-link :to="'/unnft/unwrap/' + nftId" class="del-underline" v-if="wrap && (isOwner || isOriginator)">
                    <v-list-item class="form-text text-primaryGrey">
                        <v-list-item-title>Unwrap</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="wrap && (isOwner || isOriginator)"></v-divider>

                <!-- --------------- Copy link --------------- -->
                <v-list-item class="form-text text-primaryGrey">
                    <v-list-item-title>
                        <Clipboard :text="$config.domain + $tools.getUnNFTUrl(blockchain, nftId)">Copy link</Clipboard>
                    </v-list-item-title>
                </v-list-item>

            </v-list>
        </v-menu>
	</div>
</template>
<script>
import NFTAPI from '@/api/nft.js';
import UnNftSetMenu from '@/components/nft/UnNftSetMenu.vue';
import UnNftCollectionMenu from '@/components/nft/UnNftCollectionMenu.vue';
export default {
    data(){
        return {

        }
    },
    props: {
        // NFT 主键
        nftId: {
            type: String,
            default: null
        },
        // 区块链
        blockchain: {
            type: String,
            default: null
        },
        // 我是发起人吗
        isOriginator: {
            type: Boolean,
            default: false
        },
        // 我是拥有者吗
        isOwner: {
            type: Boolean,
            default: false
        },
        // 是否已上市
        isListed: {
            type: Boolean,
            default: false
        },
        // 是否隐藏
        hidden: {
            type: Boolean,
            default: false
        },
        // 是否封装
        wrap: {
            type: Boolean,
            default: false
        },
        // 是否有请求解封权限
        hasRequestUnwrapPermission: {
            type: Boolean,
            default: false
        },
        // 是否有签名解封权限
        hasSignUnwrapPermission: {
            type: Boolean,
            default: false
        },
        // 是否有确认解封权限
        hasConfirmUnwrapPermission: {
            type: Boolean,
            default: false
        },
    },
    components: { UnNftSetMenu, UnNftCollectionMenu },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 设置为套装头像
        async makeSetPicture() {
            let params = {
                nftId: this.nftId
            }
            let res = await NFTAPI.makeSetLogo(params);
            let data = res.data;
            this.$store.dispatch('snackbarMessageHandler', data.message);
            if(data.success) {
                // 通知刷新套装数据
                this.$bus.emit('emitRefreshSet', null);
            }
        },
        // 隐藏
        async makeHidden() {
            let res = null;
            if (this.hidden) {
                res = await NFTAPI.unhide(this.nftId);
            } else {
                res = await NFTAPI.hide(this.nftId);
            }
            let data = res.data;
            if(data.success) {
                // 通知刷新数据
                this.$bus.emit('', null);
            }
        }
    }
}
</script>
<style scoped>

</style>