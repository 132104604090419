import axios from '@/config/api.request'

export default {
    // 提交申诉
    dispute(data) {
        return axios.request({
            url: '/dispute/dispute',
            method: 'post',
            data: data,
        })
    }
}