<template>
	<v-row class="pb-8" v-for="i in length" :key="i">
        <div class="c-container">
            <v-card color="grey01" max-width="1400" rounded="0" elevation="12" class="pa-10">
                <div class="c-content c-grid">
                    <div class="c-item">
                        <div class="c-p">
                           <div class="d-flex c-h-p">
                                <div class="c-m-b-auto">
                                    <v-row align="center" no-gutters>
                                        <div class="c-m-b-auto">
                                            <v-card class="bg-lightGray" rounded="0" elevation="0">
                                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public" :lazySrc="lazySrc" width="144" :aspect-ratio="1" cover></v-img>
                                            </v-card>
                                            <v-card class="bg-lightGray mt-2 w-100" height="40" rounded="0" elevation="0"></v-card>
                                            <div class="mt-2 d-flex align-center">
                                                <div>
                                                    <v-avatar class="bg-lightGray"></v-avatar>
                                                </div>
                                                <div class="ml-2">
                                                    <v-card class="bg-lightGray" width="100" height="30" rounded="0" elevation="0"></v-card>
                                                </div>
                                            </div>
                                        </div>
                                    </v-row>
                                </div>
                           </div>
                        </div>
                    </div>
                    <div class="c-b c-label c-gap c-grid">
                        <SkeletonUnNFT :length="3"></SkeletonUnNFT>
                    </div>
                </div>
            </v-card>
        </div>
	</v-row>
</template>
<script>
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
export default {
    data(){
        return {
            // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        }
    },
    components: { SkeletonUnNFT },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>