<template>
	<div>
        <v-container fluid class="mt-100">
            <v-row no-gutters justify="start">
                <v-col cols="12" sm="6" md="6">
                <v-img cover :aspect-ratio="1"  src="https://kingdoms.untrading.org/media/posts/11/responsive/Golden-Crown-Alex-Style-2xl.webp"></v-img>
              </v-col>
              <v-col cols="12" sm="6" md="5" class="pa-0 pl-16">
                    <h4 class="sidebar-h2 mb-4 mt-8">Your Community, Your Rules</h4>
                    <div class="body-p text-primaryGrey">Build and nurture your own community of like-minded investors. Set custom rules for asset management and profit-sharing within your kingdom.</div>
                    <h4 class="sidebar-h2 mt-16 mb-4">Investing Meetings Gaming</h4>
                    <div class="body-p text-primaryGrey">We've turned investing and exchanging into a social and competitive experience. With leaderboards, your kingdom can compete for its role at the top. </div>
                    <h4 class="sidebar-h2 mt-16 mb-4">From Knight to King</h4>
                    <div class="body-p text-primaryGrey">Start your journey as a humble knight and climb the ranks to become a powerful king. As you grow, so does your influence and earning potential.</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-100">
                <v-col cols="12" sm="6" md="5" offset="1" class="pl-4">
                    <h4 class="sidebar-h2 mb-4 mt-8">Cooperative Wealth Building</h4>
                    <div class="body-p text-primaryGrey">Success is a team effort. Work together with your community members to maximize returns and share in the prosperity.</div>
                    <h4 class="sidebar-h2 mt-16 mb-4">Seamless Integration</h4>
                    <div class="body-p text-primaryGrey">Experience the perfect blend of cutting-edge finance, gamification, and socialization. Untrading isn't just a platform; it's a new way of thinking about wealth creation. </div>
                    <div class="del-underline gradient-underline-hover text-primary sidebar-h2 mt-16">
                        <a href="https://kingdoms.untrading.org" target="_blank" class="body-p text-primary gradient-underline">
                            More <span class="mdi mdi-arrow-right-thin"></span>
                        </a>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="pa-0 pl-16">
                    <v-img cover :aspect-ratio="1"  src="https://kingdoms.untrading.org/media/posts/11/responsive/Investment-Leader-Summit-2xl.webp"></v-img>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>