<template>
	<div>
        <v-container fluid class="px-0" >
            <v-row no-gutters>
               <v-col cols="6" class="bg-primary align-center d-flex justify-end">
                   <v-card width="500" color="transparent" elevation="0" class="py-16">
                    <v-row no-gutters justify="center">
                        <v-col cols="12" sm="6" class="text-center px-0"><h2>Game Theory</h2></v-col>
                    </v-row>
                   </v-card>
               </v-col>
               <v-col cols="6" class="bg-background">
                   <v-card width="500" height="500" color="transparent" elevation="0" class="d-flex align-center body-p text-primaryGrey">
                        <v-row no-gutters justify="right">
                            <v-col cols="10" sm="10" class="px-0 mx-auto">
                                <ul>
                                    <li>Cooperative strategies triumph in high-stakes game theory</li>
                                    <li>Our framework allows sellers to profit from future price increases</li>
                                    <li>Buyers acquire assets with additional built-in growth potential</li>
                                    <li>The ecosystem thrives through aligned incentives</li>
                                </ul>
                            </v-col>
                        </v-row>
                   </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="bg-background align-start d-flex justify-end">
                   <v-card width="500" height="500" color="transparent" variant="flat" elevation="0" class="d-flex align-center body-p text-primaryGrey">
                        <v-row no-gutters justify="center">
                            <v-col cols="10" sm="10" class="px-0">
                                <ul>
                                    <li>Receive rewards from future price increases, even after selling</li>
                                    <li>Rewards allocated based on historical ownership and contribution</li>
                                    <li>Seamless, trustless distribution of rewards through blockchain technology</li>
                                    <li>Applicable to cryptocurrencies, NFTs, and tokenized real-world assets</li>
                                    <li>As originator of an asset, you get to decide the reward parameters and how long the cycle will continue </li>
                                </ul>
                            </v-col>
                        </v-row>
                   </v-card>
               </v-col>
                <v-col cols="6" class="bg-primary align-center d-flex">
                    <v-card width="500" color="transparent" variant="flat" elevation="0">
                        <v-row no-gutters justify="center">
                            <v-col cols="12" sm="6" class="px-0 text-center"><h2>Future Rewards</h2></v-col>
                        </v-row>
                    </v-card>
               </v-col>
            </v-row>
            <v-row no-gutters>
               <v-col cols="6" class="bg-primary align-center d-flex justify-end">
                   <v-card width="500" color="transparent" elevation="0" class="py-16">
                        <v-row no-gutters justify="center">
                            <v-col cols="12" sm="8" class="text-center px-0"><h2>No Fee, <br>No Conflict</h2></v-col>
                        </v-row>
                   </v-card>
               </v-col>
               <v-col cols="6" class="bg-background">
                   <v-card width="500" height="500" color="transparent" elevation="0" class="d-flex align-center body-p text-primaryGrey">
                        <v-row no-gutters justify="center">
                            <v-col cols="10" sm="10" class="px-0 mx-auto">
                                <ul>
                                    <li >If you don't make money, no one else should either</li>
                                    <li>Trade freely without hidden fees eating into your profits</li>
                                    <li>Aligned incentives: Everyone's success, including ours, is directly tied to yours</li>
                                    <li>Share only after you have a realized profit</li>
                                </ul>
                            </v-col>
                        </v-row>
                   </v-card>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
</style>