<template>
    <div>
        <div class="body-p-medium fw-b mb-5 text-primaryGrey">Step {{ active + 1 }} Of {{ steps.length }}</div>
        <el-steps direction="vertical" :active="active" process-status="process-text" finish-status="finish-text" :space="50">
            <el-step v-for="(step, i) in steps" :title="step.title" :key="i" />
        </el-steps>
    </div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    props: {
        action: {
            type: String,
            default: null
        },
        steps: {
            type: Array,
            default: []
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        
    },
    computed: {
        active() {
            let currentSteps = this.steps.filter(step => step.codes.indexOf(this.action) > -1);
            return currentSteps.length > 0 ? this.steps.indexOf(currentSteps[0]) : 0;
        }
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>