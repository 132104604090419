import axios from '@/config/api.request'

export default {
    // 验证邀请码
    verifyInvitationCode(data) {
        return axios.request({
            url: '/pre-register/invitation/verify',
            method: 'post',
            data: data
        })
    },
    // 查询预注册用户
    getPreRegisterUser(id) {
        return axios.request({
            url: '/pre-register/user',
            method: 'get',
            params: { id: id }
        })
    }
}