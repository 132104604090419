import axios from '@/config/api.request'

export default {
  // 上传文件到 IPFS
  uploadFile(formData) {
    return axios.request({
        url: '/ipfs/upload/file',
        headers: {'Content-Type': 'multipart/form-data'},
        method: 'post',
        data: formData
    })
  },
  // 上传 URL 到 IPFS
  uploadUrl(data) {
    return axios.request({
        url: '/ipfs/upload/url',
        method: 'post',
        data: data
    })
  },
  // 上传元数据到 IPFS
  uploadJson(metadata) {
    return axios.request({
        url: '/ipfs/upload/json',
        method: 'post',
        data: metadata
    })
  },
  // 删除文件【目前只能删除 CF 文件，暂时无法删除 IPFS 文件】
  delete(cid) {
    return axios.request({
        url: '/ipfs/delete',
        method: 'delete',
        params: { cid: cid }
    })
  },
}