<template>
	<div>
        <v-container fluid class="px-0 mt-16">
            <v-row no-gutters justify="start" align="center">
                <v-col cols="12" sm="6" md="5"  class="pa-0 pr-16 mr-4">
                    <h4 class="qc-h2 fw-b mt-8">Mint your original NFTs</h4>
                    <div class="body-p text-primaryGrey mb-16 mt-8">As a revolutionary platform, Untrading combines a decentralized exchange (DEX) with an innovative NFT marketplace, compliant with the additional Future Rewards protocol.</div>
                    <!-- <v-img cover :aspect-ratio="1"  src="https://kingdoms.untrading.org/media/posts/11/responsive/Golden-Crown-Alex-Style-2xl.webp"></v-img> -->
                </v-col>
                <v-col cols="12" sm="6" md="5" class="pa-0 pl-16 ml-16">
                    <h4 class="qc-h2 fw-b mt-8">Or Wrap these white-listed collections</h4>
                    <div class="body-p text-primaryGrey mb-16 mt-8">Unlock the full potential of your digital collectibles while maintaining their security. With Untrading, your NFTs work harder for you.</div>
                    <!-- <v-img cover :aspect-ratio="1"  src="https://kingdoms.untrading.org/media/posts/11/responsive/Golden-Crown-Alex-Style-2xl.webp"></v-img> -->
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>