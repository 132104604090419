<template>
    <span v-if="token != null">
        <v-avatar icon size="30" class="pointer mb-2">
            <v-icon v-if="!user.avatar" color="primary" icon="mdi:mdi-account-circle-outline" size="30"></v-icon>
            <v-img v-if="user.avatar" :src="user.avatar" cover height="30"></v-img>
            <v-menu activator="parent" width="220" transition="slide-y-transition">
                <v-list bg-color="black">
                    <v-list-item>
                        <v-list-item-title class="text-primaryGrey">
                            <Clipboard :text="user.wallet">
                                {{ $tools.privacyField(user.wallet) }}
                            </Clipboard>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- 用户 -->
                    <v-list-item v-for="(menu, index) in userMenus" :key="index" :value="index">
                        <router-link :to="menu.to" class="del-underline text-primaryGrey">
                            <v-list-item-title class="gradient-underline-hover">
                                <v-icon :icon="menu.icon" aria-label="menu" class="text-primaryGrey"></v-icon>
                                <a class="ml-3 gradient-text-hover text-primaryGrey">{{ menu.title }}</a>
                            </v-list-item-title>
                        </router-link>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- Crypto -->
                    <div class="ml-2 my-3 text-primaryGrey body-h4">CRYPTOS</div>
                    <v-list-item>
                        <a href="/uncrypto/wrap" class="del-underline" :class="cryptoWrapPermission ? 'text-primaryGrey' : 'text-lightGray pointer-none'">
                            <v-list-item-title class="gradient-underline-hover">
                                <v-icon icon="mdi:mdi-plus-thick" aria-label="mdi:mdi-plus-thick" :class="cryptoWrapPermission ? 'text-primaryGrey' : 'text-lightGray pointer-none'"></v-icon>
                                <a class="ml-3 gradient-text-hover" :class="cryptoWrapPermission ? 'text-primaryGrey' : 'text-lightGray pointer-none'">Wrap Crypto</a>
                            </v-list-item-title>
                        </a>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- NFT -->
                    <div class="ml-2 my-3 text-primaryGrey body-h4">NFTS</div>
                    <v-list-item v-for="(menu, index) in nftMenus" :key="index" :value="index">
                        <router-link :to="menu.to" class="del-underline text-primaryGrey">
                            <v-list-item-title class="gradient-underline-hover">
                                <v-icon :icon="menu.icon" class="text-primaryGrey"></v-icon>
                                <a class="ml-3 gradient-text-hover text-primaryGrey">{{ menu.title }}</a>
                            </v-list-item-title>
                        </router-link>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- 系统 -->
                    <v-list-item v-for="(menu, index) in systemMenus" :key="index" :value="index">
                        <router-link :to="menu.to" class="del-underline text-primaryGrey">
                            <v-list-item-title class="gradient-underline-hover">
                                <v-icon :icon="menu.icon" class="text-primaryGrey"></v-icon>
                                <a class="ml-3 gradient-text-hover text-primaryGrey">{{ menu.title }}</a>
                            </v-list-item-title>
                        </router-link>
                    </v-list-item>
                    <!-- 退出 -->
                    <v-list-item>
                        <v-list-item-title class="gradient-underline-hover pointer" @click="logout">
                            <v-icon icon="mdi:mdi-logout-variant" class="text-primaryGrey"></v-icon>
                            <a class="ml-3 gradient-text-hover text-primaryGrey">Log Out</a>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-avatar>
    </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['token', 'user', 'cryptoWrapPermission']),
        userMenus(){ 
            return [
                { title: 'Profile', icon: 'mdi:mdi-account', to: '/profile/' + this.user.username },
                { title: 'Dashboard', icon: 'mdi:mdi-view-dashboard-outline', to: '/dashboard' },
            ]
        },
        nftMenus() {
            return [
                { title: 'Create Set', icon: 'mdi:mdi-image-multiple-outline', to: '/set/create' },
                { title: 'Import unNFT', icon: 'mdi:mdi-image-check-outline', to: '/unnft/import' },
                { title: 'Create unNFT', icon: 'mdi:mdi-image-plus-outline', to: '/unnft/mint' },
                { title: 'Wrap NFT', icon: 'mdi:mdi-image-refresh-outline', to: '/unnft/wrap' },
                { title: 'Create Collection', icon: 'mdi:mdi-file-multiple-outline', to: '/collection/create' },
            ]
        },
        systemMenus(){ 
            return [
                { title: 'Settings', icon: 'mdi:mdi-cog', to: '/settings' },
                { title: 'Notifications', icon: 'mdi:mdi-bell-outline', to: '/notifications' },
            ]
        },
    },
    watch:{

    },
    methods: {
        logout(){
            this.$store.commit("tokenHandler", null);
            this.$router.push("/");
            setTimeout(() => location.reload(), 100);
            
        }
    }
}
</script>
<style scoped>

</style>