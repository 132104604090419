import axios from '@/config/api.request'

export default {
    // 根据 CID 查询媒体
    getMediaByCid(cid) {
        return axios.request({
            url: '/media/media/cid',
            method: 'get',
            params: { cid: cid }
        })
    },
    // 根据文件链接查询媒体
    getMediaByFile(fileUrl) {
        return axios.request({
            url: '/media/media/file',
            method: 'get',
            params: { fileUrl: fileUrl }
        })
    },
    // 根据文件链接更新尺寸
    updateDimension(data) {
        return axios.request({
            url: '/media/dimension/update',
            method: 'put',
            data: data
        })
    },
}