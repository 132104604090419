<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 r-m-l-2 ml-5 text-primaryGrey">
                    <h4>Featured Releases</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 ml-5 r-m-l-0 mt-1 mb-5"></v-divider>
                <v-card class="ml-5 r-m-l-2 mt-10" rounded="0" elevation="0" v-for="nft in nfts" :key="nft.id" color="transparent">
                    <MediaFile :nftId="nft.id" overlay class="r-m-r-2"></MediaFile>
                    <div class="mt-6 body-h4 gradient-underline-hover">
                        <router-link :to="$tools.getUnNFTUrl(nft.blockchain, nft.id)" class="del-underline">
                            <span class="text-primaryGrey gradient-text-hover pointer">{{ nft.name }}</span>
                        </router-link>
                    </div>
                    <div class="mt-6" v-if="nft.user">
                        <UserAvatar :avatar="nft.user.avatar" :username="nft.user.username" :name="nft.user.name"></UserAvatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            nfts: []
        }
    },
    components: { MediaFile },
    created(){
        this.getFeatured();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchain'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.getFeatured();
        }
    },
    methods: {
        // 查询精选的 NFT 列表
        async getFeatured() {
            let res = await NFTAPI.getFeatured(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfts = data.data;
            }

        }
    }
}
</script>
<style scoped>

</style>