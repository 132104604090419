<template>
	<div>
        <div style="width: 100%; height: 486px; position: relative;" id="priceLineChart"></div>
	</div>
</template>
<script>
import ForwardAPI from '@/api/forward.js'
import { mapGetters } from "vuex";
export default {
    data(){
        return {
          priceLineChart: null,
          option : {
            xAxis: {
                type: 'category',
                axisTick: {
                    show: false
                },
                splitLine:{
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                data: []
            },
            grid: {
                left: '0%',
                right: '0%',
                containLabel: true
            },
            yAxis: {
                type: 'log',
                axisTick: {
                    show: false,
                },
                splitLine:{
                    show: false
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                scale: true
            },
            series: [{
                    data: [],
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        color: '#95c8ff',
                        width: 5
                    },
                }]
            }
        }
    },
    components: {  },
    created() {
      this.$nextTick(() => {
        this.initFN();
      });
    },
    mounted(){
        this.getPriceLineChart();
    },
    computed: {

    },
    computed: {
        ...mapGetters(['darkTheme'])
    },
    watch:{
        darkTheme(val) {
            this.option.series[0].lineStyle.color = this.darkTheme == 0 ? '#95c8ff':'#004080';
            this.getPriceLineChart();
        },
    },
    methods: {
      // 初始化
      initFN() {
        var that = this;
        // 渲染图表
        this.priceLineChart = this.$echarts.init(document.getElementById("priceLineChart"));
        // 填充数据
        this.priceLineChart.setOption(this.option, true);
        this.priceLineChart.on('click', function(series) {

        })
      },
      // 获取价格折线图数据
      async getPriceLineChart() {
        let res = await ForwardAPI.forwardGet('https://iobots.pro/iobots/cmc/public/chart/line?currency=ETH');
        let data = res.data;
        if (data.code == 200) {
          let result = data.result;
          let currentDates = [];
          let currentValues = [];
          for (let i = 0, len = result.dates.length; i < len; i++) {
            let isHalfYearEnd = this.$tools.isHalfYearEnd(result.dates[i]);
            if(isHalfYearEnd) {
                currentDates.push(result.dates[i]);
                currentValues.push(result.values[i]);
            }
          }
          // 再放入最后一天的数据
          currentDates.push(result.dates[result.dates.length - 1]);
          currentValues.push(result.values[result.values.length - 1]);
          // this.option.xAxis.data = result.dates.slice(result.dates.length-30,result.dates.length);
          // this.option.series[0].data = result.values.slice(result.values.length-30,result.values.length);
          this.option.xAxis.data = currentDates;
          this.option.series[0].data = currentValues;
          this.$emit('receiveCurrentPrice', result.values[result.values.length - 1]);
          this.option.series[0].lineStyle.color = this.darkTheme == 0 ? '#95c8ff':'#004080';
        }
        // 填充数据
        this.priceLineChart.setOption(this.option, true);
      },
    }
}
</script>
<style lang="scss" scoped>
// .priceLineChart {
//     width: 100%;
//     height: 486px;
// }
</style>