/**
 * BigNumber - https://mikemcl.github.io/bignumber.js
 */

import BigNumber from "bignumber.js";

export default {
    /**
     * @description 从 wei 单位转换
     * @date 2023/12/06
     * @author Louis
     * @param {数字} number 
     * @param {小数位} decimals 
     */
    fromWei(number, decimals) {
        let n = new BigNumber(number || 0);
        let weiFactor = new BigNumber(10).exponentiatedBy(new BigNumber(decimals || 18));
        return Number(n.dividedBy(weiFactor).toString(10));
    },
    /**
     * @description 把单位转换为 wei
     * @date 2023/12/06
     * @author Louis
     * @param {数字} number 
     * @param {小数位} decimals 
     */
    toWei(number, decimals) {
        let n = new BigNumber(number || 0);
        let weiFactor = new BigNumber(10).exponentiatedBy(new BigNumber(decimals || 18));
        return n.multipliedBy(weiFactor).toString(10);
    }
}
