import axios from '@/config/api.request'

export default {
    // 根据套装主键查询统计数据
    setStat(setId) {
        return axios.request({
            url: '/set/stat/stat',
            method: 'get',
            params: { setId: setId }
        })
    },
    // 浏览套装
    view(setId) {
        return axios.request({
            url: '/set/stat/view',
            method: 'get',
            params: { setId: setId }
        })
    },
    // 根据套装主键查询概括数据
    summary(setId) {
        return axios.request({
            url: '/set/stat/summary',
            method: 'get',
            params: { setId: setId }
        })
    }
}