<template>
	<div class="mt-100">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <div class="body-h4 ml-5 r-m-l-0 text-primaryGrey">
                    <h4>Top Collectors</h4>
                </div>
                <v-divider thickness="3" color="bg-grey06" class="border-opacity-50 r-m-l-0 mt-1 mb-10"></v-divider>
                <!-- 96 高 = 36 内容 + 10 间距 + 50 轮播 -->
                <v-carousel v-model="carouselIndex" v-show="!loading" :continuous="false" :show-arrows="false" interval="5000" height="96" hide-delimiter-background color="d7d" delimiter-icon="mdi:mdi-circle" class="carousel-item-active">
                    <v-carousel-item v-for="collector in collectors" :key="collector.id">
                        <v-card class="ml-5 r-m-l-0" elevation="0"   color="transparent">
                            <div>
                                <UserAvatar :avatar="collector.avatar" :username="collector.username" :name="collector.name"></UserAvatar>
                            </div>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
            </v-col> 
        </v-row>
	</div>
</template>
<script>
import UserAPI from '@/api/user.js';
export default {
    data(){
        return {
            collectors: [],
            // 轮播图的下标
            carouselIndex: 0,
        }
    },
    components: {  },
    created(){
        this.getTopCollectors();
    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 查询最佳的收藏家列表
        async getTopCollectors() {
            let res = await UserAPI.getTopCollectors();
            let data = res.data;
            if(data.success) {
                this.collectors = data.data;
            }
        }
    }
}
</script>
<style scoped>

</style>