<template>
	<div>
        <v-card color="transparent" elevation="0" rounded="0" class="mx-auto pt-8">
            <div v-if="loading">
                <SkeletonUnCryptos bg-color="bg-primaryGrey"></SkeletonUnCryptos>
            </div>
            <div v-else-if="!loading && cryptoWrappedSummaries.length == 0">
                <NoDataAvailable></NoDataAvailable>
            </div>
            <div v-else v-for="(summary, i) in cryptoWrappedSummaries" :key="summary.currency">
                <!-- 摘要信息 -->
                <v-card elevation="0" rounded="0" color="transparent" align="left" class="pb-7 mx-auto">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-card elevation="0" rounded="0" color="transparent">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <h1 class="sub-page-h1 gradient-underline-hover">
                                            <Blockchain :blockchain="summary.blockchain"></Blockchain>
                                            <a :href="`${$tools.getBlockchainExplorerUrl(summary.blockchain)}/address/${summary.address}`" :aria-label="summary.currency"
                                                class="del-underline text-primaryGrey gradient-text-hover" :class="summary.blockchain ? 'pointer' : 'pointer-none'" target="_blank">
                                                <span>{{ summary.name }} ({{ summary.currency }})</span>
                                            </a>
                                        </h1>
                                    </v-col>
                                </v-row>
                                <!-- <v-row no-gutters >
                                    <v-col cols="4">
                                        <h4 class="body-h4 text-primaryGrey">
                                            {{ $tools.formatNumber(summary.totalCrypto, 0) }} Total
                                        </h4>
                                    </v-col>
                                </v-row> -->
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- 表格 -->
                <v-card color="transparent" rounded="0" elevation="0" class="mx-auto">
                    <div v-for="cryptoSymbolSummary in summary.cryptoSymbolSummaries" :key="cryptoSymbolSummary.symbol" class="mb-16">
                        <div class="mb-8 r-m-x-0">
                            <v-divider></v-divider>
                            <v-row no-gutters class="mt-8 r-p-r-0">
                                <v-col cols="6">
                                    <div class="text-primaryGrey body-h4">
                                        {{ cryptoSymbolSummary.symbol }}
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="float-right text-primaryGrey body-p">
                                        {{ $tools.formatNumber(cryptoSymbolSummary.total, 0) }} results
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- 数据表格 -->
                        <v-data-table-server width="100%" fluid :headers="headers" :items="cryptoSymbolSummary.results" :items-length="cryptoSymbolSummary.total" :page="cryptoSymbolSummary.page" :items-per-page="cryptoSymbolSummary.size" :loading="cryptoSymbolSummary.loading" item-value="name" 
                            class="body-p text-primaryGrey" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact"
                            @update:page="updatePage($event, cryptoSymbolSummary)" @update:itemsPerPage="updateSize($event, cryptoSymbolSummary)">
                            <template v-slot:headers>
                                <tr class="body-h5">
                                    <td class="px-0">ID</td>
                                    <td class="px-0" width="150">Originator</td>
                                    <td class="px-0" width="150">Owner</td>
                                    <td class="px-0 text-right" width="80">Gen
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                            </v-card>
                                        </v-menu>
                                    </td>
                                    <td class="px-0 text-right" width="80">Flow
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                The Length of Flow is calculated as the sum of all prior owners along with the present holder of this token, starting from the time it was initially minted.
                                            </v-card>
                                        </v-menu>
                                    </td>
                                    <td class="px-0 text-right">TOTAL ORs RECEIVED</td>
                                    <td class="px-0 text-right">TOTAL FRs RECEIVED</td>
                                    <td class="px-0 text-right">TOTAL nFR PAYOUTS</td>
                                </tr>
                                <tr>
                                    <td colspan="10" class="px-0 text-center">
                                        <v-divider color="primaryGrey" :thickness="3"></v-divider>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:item="{ index, item }">
                                <tr>
                                    <td class="px-0 gradient-underline-hover">
                                        <router-link :to="`/uncrypto/${item.crypto?.wrappedCurrency?.toLowerCase()}/${item.crypto?.id}`" class="del-underline gradient-text-hover">
                                            {{ item.crypto?.tokenId }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <UserAvatar :avatar="item.crypto?.user?.avatar" :username="item.crypto?.user?.username" :name="item.crypto?.user?.name" :size="24"></UserAvatar>
                                    </td>
                                    <td class="px-0">
                                        <UserAvatar :avatar="item.crypto?.owner?.avatar" :username="item.crypto?.owner?.username" :name="item.crypto?.owner?.name" :size="24"></UserAvatar>
                                    </td>
                                    <td class="px-0 text-right">{{ item.crypto?.numberOfGenerations }}</td>
                                    <td class="px-0 text-right">{{ item.crypto?.maxOwnerNo }}</td>
                                    <td class="px-0 text-right">
                                        <router-link :to="`/dashboard/asset/uncrypto/ors/received/${item.crypto?.id}`" class="del-underline gradient-left-red-purple-142">
                                            <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="TOTAL ORs RECEIVED">
                                                {{ $tools.formatNumber(item.totalORsReceived?.amount, item.crypto?.cryptoQuote?.sizeDecimals) }}
                                            </v-btn>
                                        </router-link>
                                    </td>
                                    <td class="px-0 text-right">
                                        <router-link :to="`/dashboard/asset/uncrypto/frs/received/${item.crypto?.id}`" class="del-underline gradient-left-red-purple-142">
                                            <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="TOTAL FRs RECEIVED">
                                                {{ $tools.formatNumber(item.totalFRsReceived?.amount, item.crypto?.cryptoQuote?.sizeDecimals) }}
                                            </v-btn>
                                        </router-link>
                                    </td>
                                    <td class="px-0 text-right">
                                        <router-link :to="`/dashboard/asset/uncrypto/nfrs/paid/${item.crypto?.id}`" class="del-underline gradient-left-red-purple-142" v-if="!item.totalNFRsPayouts?.invalid">
                                            <v-btn :rounded="0" color="button01" class="text-none text-grey05 body-p-small-b" width="142" height="32" aria-label="TOTAL nFRS PAID">
                                                {{ $tools.formatNumber(item.totalNFRsPayouts?.amount, item.crypto?.cryptoQuote?.sizeDecimals) }}
                                            </v-btn>
                                        </router-link>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:loading>
                                <!-- <tr>
                                    <td colspan="10">
                                        <div class="text-center">
                                            <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="cryptoSymbolSummary.loading"></v-progress-circular>
                                        </div>
                                    </td>
                                </tr> -->
                                <v-skeleton-loader :type="`table-row@${cryptoSymbolSummary.size}`" color="transparent"></v-skeleton-loader>
                            </template>
                            <template v-slot:no-data>
                                <NoDataAvailable></NoDataAvailable>
                            </template>
                            <template v-slot:tfoot>
                                <tr>
                                    <td colspan="10" class="px-0">
                                        <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:bottom>
                                <v-data-table-footer key="title" :items-per-page-options="perPageOptions"></v-data-table-footer>
                            </template>
                        </v-data-table-server>
                    </div>
                </v-card>
            </div>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SkeletonUnCryptos from '@/components/util/skeleton/SkeletonUnCryptos.vue';
import CryptoAPI from '@/api/crypto.js';
import CryptoNFRAPI from '@/api/crypto-nfr.js';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 表头
            headers: [{},{},{},{},{},{},{},{},{},{}],
            // 每页数量配置
            perPageOptions: [
                { title: '10', value: 10 },
                { title: '20', value: 20 },
                { title: '50', value: 50 },
                { title: '100', value: 100 }
            ],
            // 加密货币封装资产摘要信息
            cryptoWrappedSummaries: [],
        }
    },
    props: {
        sortBy: {
            type: String,
            default: null
        },
        blockchain: {
            type: String,
            default: null
        }
    },
    components: { SkeletonUnCryptos },
    created(){

    },
    mounted(){
        // 查询加密货币用户仪表盘摘要信息
        this.getCryptoDashboardSummaries();
    },
    computed: {
        ...mapGetters(['responsive']),
    },
    watch:{
        blockchain(n, o) {
            this.changeCondition();
        },
        sortBy(n, o) {
            this.changeCondition();
        }
    },
    methods: {
        // 改变条件
        changeCondition() {
            // 恢复data定义的所有参数
            Object.assign(this.$data, this.$options.data.call(this));
            // 重新查询数据
            this.getCryptoDashboardSummaries();
        },
        // 查询加密货币用户仪表盘摘要信息
        async getCryptoDashboardSummaries() {
            this.loading = true;
            this.cryptoWrappedSummaries = [];
            let res = await CryptoAPI.getCryptoDashboardSummaries(this.blockchain);
            let data = res.data;
            if(data.success) {
                this.cryptoWrappedSummaries = data.data;
                this.cryptoWrappedSummaries.forEach(summary => {
                    summary.cryptoSymbolSummaries.forEach(cryptoSymbolSummary => {
                        cryptoSymbolSummary.loading = false;
                        cryptoSymbolSummary.total = 0;
                        cryptoSymbolSummary.page = 1;
                        cryptoSymbolSummary.size = 10;
                        cryptoSymbolSummary.results = [];
                        // 分页查询完成的加密货币NFR列表
                        this.getCompletedNfrsByPage(cryptoSymbolSummary);
                    });
                });
            }
            this.loading = false;
        },
        // 分页查询完成的加密货币NFR列表
        async getCompletedNfrsByPage(cryptoSymbolSummary) {
            cryptoSymbolSummary.loading = true;
            // cryptoSymbolSummary.total = 0;
            cryptoSymbolSummary.results = [];
            let res = await CryptoNFRAPI.getCompletedNfrsByPage(cryptoSymbolSummary.page, cryptoSymbolSummary.size, this.sortCondition, this.blockchain, cryptoSymbolSummary.wrappedCurrency, cryptoSymbolSummary.quoteCurrency);
            let data = res.data;
            if(data.success) {
                cryptoSymbolSummary.total = data.data.total;
                cryptoSymbolSummary.results = data.data.records;
            }
            cryptoSymbolSummary.loading = false;
        },
        // 更新页码
        updatePage(page, cryptoSymbolSummary) {
            // 更新参数
            cryptoSymbolSummary.page = page;
            // 分页查询完成的加密货币NFR列表
            this.getCompletedNfrsByPage(cryptoSymbolSummary);
        },
        // 更新每页数量
        updateSize(size, cryptoSymbolSummary) {
            // 更新参数
            cryptoSymbolSummary.page = 1;
            cryptoSymbolSummary.size = size;
            // 分页查询完成的加密货币NFR列表
            this.getCompletedNfrsByPage(cryptoSymbolSummary);
        },
        // 跳转到加密货币详情页
        goToUnCrypto(unCrypto) {
            this.$router.push(`/uncrypto/${unCrypto.wrappedCurrency.toLowerCase()}/${unCrypto.id}`);
        }
    }
}
</script>
<style scoped>

</style>