<template>
	<div>
        <v-card max-width="414"  elevation="2" rounded="0">
            <div v-if="fileType == 'image'">
                <v-img :src="media.fileUrl" height="414px" :lazy-src="lazySrc" class="bg-lightGray" cover aspect-ratio="1">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular indeterminate color="primaryGrey"></v-progress-circular>
                        </div>
                    </template>
                </v-img>
            </div>
            <div v-if="fileType == 'video'">
                <MediaVideo :src="src"></MediaVideo>
            </div>
            <v-row no-gutters>
                <v-col cols="12" class="pa-8 d-flex flex-nowrap align-center gradient-text-hover">
                    <UserAvatar :avatar="user.avatar" :username="user.username"></UserAvatar>
                    <span class="body-p ml-4">
                        <Clipboard :text="user.wallet">
                            {{ $tools.privacyField(user.wallet) }}
                        </Clipboard>
                    </span>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import MediaAPI from '@/api/media.js';
import MediaTypeAPI from '@/api/media-type.js';
import MediaVideo from '@/components/media/MediaVideo.vue';
export default {
    data(){
        return {
            // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
            // 媒体对象
            media: {},
            // 文件类型：[image, video]，默认为图片
            fileType: 'image',
        }
    },
    props: {
        // 文件链接
        src: { type: String, default: null }
    },
    components: { MediaVideo },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user'])
    },
    watch:{
        src: {
            handler(newVal, oldVal) {
                // 查询媒体
                this.getMedia();
            },
            immediate: true
        }
    },
    methods: {
        // 查询媒体
        async getMedia() {
            if(this.src && this.src.indexOf('https://ipfs.io/ipfs/') > -1){
                this.getMediaByCid();
            } else {
                this.getMediaByFile();
            }
        },
        // 根据文件链接查询媒体
        async getMediaByFile() {
            if(this.src == null) {
                return;
            }
            let res = await MediaAPI.getMediaByFile(this.src);
            let data = res.data;
            if(data.success) {
                this.media = data.data;
                let mimeTypes = this.media.mimeType.split('/');
                this.fileType = mimeTypes[0];
            } else {
                // 获取文件类型
                this.getFileType();
                this.media.fileUrl = this.src;
            }
        },
        // 根据 CID 查询媒体
        async getMediaByCid() {
            if(this.src == null) {
                return;
            }
            this.loading = true;
            let cid = this.src.replaceAll("https://ipfs.io/ipfs/", "");
            let res = await MediaAPI.getMediaByCid(cid);
            let data = res.data;
            if(data.success) {
                this.media = data.data;
                let mimeTypes = this.media.mimeType.split('/');
                this.fileType = mimeTypes[0];
            } else {
                // 获取文件类型
                this.getFileType();
                this.media.fileUrl = this.src;
            }
        },
        // 获取文件类型
        async getFileType() {
            let res = await MediaTypeAPI.getFileType(this.src);
            let data = res.data;
            if(data.success) {
                this.fileType = data.data;
            } else {
                this.$emit('watchMediaFile', true)
            }
        }
    }
}
</script>
<style scoped>

</style>