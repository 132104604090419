<template>
	<div>
       <v-card max-width="500" elevation="0" rounded="0" color="transparent" class="text-primaryGrey">
        <v-row no-gutters>
            <v-col cols="12" class="pa-0">
                <div class="body-text-btn">
                   o-tokens
                </div>
            </v-col>
            <v-divider class="my-4"></v-divider>
            <v-col cols="12">
                <SkeletonCard :height="50" :length="2" v-if="loading"></SkeletonCard>
                <table class="bg-f2f body-p-small text-primaryGrey mx-0" v-if="!loading">
                    <tbody>
                        <tr v-for="(originator, i) in orPayment.originatorsRewards" :key="i">
                            <td class="px-0 d-flex flex-nowrap">
                                <UserAvatar :avatar="originator.beneficiary.avatar" :username="originator.beneficiary.username" :size="24"></UserAvatar>
                                <span class="ml-2">{{ originator.beneficiary.name }}</span>
                            </td>
                            <td class="text-right">
                                {{ originator.otoken }}
                            </td>
                        </tr>
                        <tr>
                            <th class="px-0">Total</th>
                            <th class="text-right">
                                <span v-if="orPayment.otokenTotal">{{ orPayment.otokenTotal.otoken }}</span>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-8">
            <v-col cols="12" class="pa-0">
                <div class="body-text-btn">
                   OR Payments Received
                </div>
            </v-col>
            <v-divider class="my-4"></v-divider>
            <v-col cols="12">
                <SkeletonCard :height="50" :length="10" v-if="loading"></SkeletonCard>
                <table class="bg-f2f text-primaryGrey mx-0" v-if="!loading">
                    <thead class="px-0">
                        <tr>
                            <td class="text-right form-text px-0" style="width: 50px;">Flow</td>
                            <td class="text-right form-text">From</td>
                            <td class="text-right form-text">Amount</td>
                            <td class="text-left form-text  text-right">Currency</td>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="payment in orPayment.payments" :key="payment.index" class="body-p-small">
                        <td class="text-right body-p-small">{{ payment.index }}</td>
                        <td class="text-right body-p-small">
                            <span v-if="payment.paid" class="d-flex flex-nowrap justify-end">
                                <span class="mr-2">{{ payment.from.name }}</span>
                                <UserAvatar :avatar="payment.from.avatar" :username="payment.from.username" :size="24"></UserAvatar>
                            </span>
                        </td>
                        <td class="text-right body-p-small">
                            <span v-if="payment.paid">
                                {{ payment.amount > 0 ? $tools.formatNumber(payment.amount, 8) : '--' }}
                            </span>
                        </td>
                        <td class="text-right body-p-small ellipsis-line">
                            <span v-if="payment.paid">
                                {{ payment.paymentToken }}
                            </span>
                        </td>
                    </tr>
              
                    <tr>
                        <th class="text-right form-text px-0">Total</th>
                        <th></th>
                        <th class="form-text text-right">{{ $tools.formatNumber(orPayment.total.amount, 8) }}</th>
                        <th class="form-text text-right">{{ orPayment.total.paymentToken }}</th>
                    </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>
       </v-card>
	</div>
</template>
<script>
import CryptoNFRAPI from '@/api/crypto-nfr.js';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // OR 付款记录
            orPayment: {
                originatorsRewards: [],
                otokenTotal: {},
                payments: [],
                total: {}
            }
        }
    },
    // 加密货币主键
    props: {
        cryptoId: {
            type: String,
            default: null
        }
    },
    components: { SkeletonCard },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{
        cryptoId: {
            handler(newVal, oldVal) {
                // 根据加密货币主键和用户主键查询OR信息
                this.getORPayment();
            },
            immediate: true
        },
    },
    methods: {
        // 根据加密货币主键和用户主键查询OR信息
        async getORPayment() {
            if(this.cryptoId == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoNFRAPI.getORPayment(this.cryptoId);
            let data = res.data;
            if(data.success) {
                this.orPayment = data.data;
            }
            this.loading = false;
        },
    }
}
</script>
<style scoped lang="scss">
table { 
	width: 100%; 
	border-collapse: collapse; 
	}

tr th {
	padding: 10px; 
	border-top: 2px solid #9e9e9e; 
	text-align: left; 
	}

td { 
	padding: 10px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
	}

[data-toggle="toggle"] {
	display: none;
}
</style>