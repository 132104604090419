import axios from '@/config/api.request'

export default {
    // 查询全部加密货币封装资产
    getCryptoWrappeds(blockchain) {
        return axios.request({
            url: '/crypto/wrapped/list',
            method: 'get',
            params: { blockchain }
        })
    },
    // 查询加密货币封装资产摘要信息
    getCryptoWrappedSummaries(blockchain) {
        return axios.request({
            url: '/crypto/wrapped/summaries',
            method: 'get',
            params: { blockchain }
        })
    },
    // 根据区块链和地址查询加密货币封装资产详情
    getCryptoWrappedDetail(blockchain, address) {
        return axios.request({
            url: '/crypto/wrapped/detail',
            method: 'get',
            params: { blockchain, address }
        })
    }
}