<template>
	<div>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="12" class="align-self-center image-container px-0 mt-16 pt-16">
                <v-img  contain width="1080" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f84c04dd-5ada-42ca-e0ad-5bdacc580100/public"></v-img>
                <v-img  contain class="overlay-image" width="300" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/99a93f28-dfd7-465d-fb00-f8af408dd900/public"></v-img>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.image-container{
    position: relative;
}
.overlay-image {
  position: absolute;
  bottom: 100px;
  left: 0px;
}
</style>