export default {
    /**
     * @date 2022/08/18
     * @author Louis
     * @param {字段脱敏} text 
     */
    privacyField(text){
        if(!text) return text;
        if(text.length > 10) {
            return text.substring(0, 6) + "..." + text.substring(text.length - 4);
        }
        return text;
    },
     // 获取 App Url
     getAppUrl(darkTheme, lang) {
        let url = 'https://untrading.org';
        if(darkTheme != null) {
            url += '?theme=' + (darkTheme == 1 ? 'dark' : 'light');
        }
        if(lang) {
            let hyphen = darkTheme != null ? '&' : '?';
            url += hyphen + 'lang=' + lang;
        }
        return url;
    },
    // 获取测试 App Url
    getTestnetAppUrl(darkTheme, lang) {
        let url = 'https://testnet.untrading.org';
        if(darkTheme != null) {
            url += '?theme=' + (darkTheme == 1 ? 'dark' : 'light');
        }
        if(lang) {
            let hyphen = darkTheme != null ? '&' : '?';
            url += hyphen + 'lang=' + lang;
        }
        return url;
    },
    // 格式化月份
    formatEngLishMonth(time) {
        var months = new Array("January","February","March","April","May","June","July","August","September","October","November","December");
        var date = new Date(time);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getMonth();
        // 拼接
        return (months[month] + " " + year);
    },
    // 格式化日期
    formatEngLishDate(time) {
        var months = new Array("January","February","March","April","May","June","July","August","September","October","November","December");
        var date = new Date(time);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getMonth();
        var day = date.getDate();
        // 拼接
        return (months[month] + " " + day + ", " + year);
    },
    // 格式化日期
    formatEngLishDatetime(time) {
        var months = new Array("January","February","March","April","May","June","July","August","September","October","November","December");
        var date = new Date(time);
        var year = date.getUTCFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getUTCMonth();
        var day = date.getUTCDate();
        var hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
        var minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
        var seconds = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
        // 拼接
        return months[month] + " " + day + ", " + year + " " + hours + ":" + minutes + ":" + seconds + " UTC";
    },
    // 格式化大头像
    bigAvatar(avatar) {
        if(!avatar) return avatar;
        if(avatar.indexOf('twimg.com') > -1 && avatar.indexOf('_normal') > -1) {
            return avatar.replace('_normal', '_400x400')
        }
        return avatar;
    },
    // 获取区块链浏览器链接
    getBlockchainExplorerUrl(blockchain) {
        switch(blockchain){
            case 'Arbitrum One': return 'https://arbiscan.io';
            case 'Ethereum': return 'https://etherscan.io';
            case 'Polygon': return 'https://polygonscan.com';
            case 'Sepolia': return 'https://sepolia.etherscan.io';
            case 'Goerli': return 'https://goerli.etherscan.io';
            case 'Mumbai': return 'https://mumbai.polygonscan.com';
        }
    },
    // 格式化文件大小：B，小数位
    formatFileSize(size, pointLength) {
        var unit;
        let units = [ 'B', 'KB', 'MB', 'GB', 'TB' ];
        
        while ( (unit = units.shift()) && size > 1024 ) {
            size = size / 1024;
        }
        return (unit === 'B' ? size : size.toFixed( pointLength === undefined ? 2 : pointLength)) + ' ' + unit;
    },
    // 格式化数字，指定小数位
    formatNumber: function (num, pointLength) {
        if (num == null) return '';
        if (num == NaN || num == 'NaN') return '';
        if (num == 'undefined') return '';
        if (num == '--') return '--';
        let number = 0;
        if(pointLength && pointLength >= 0) {
            number = Number(num).toFixed(pointLength);
        } else {
            number = num + '';
        }
        let numberArray = number.split('.');
        let integerPart = numberArray[0];
        // let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
        let decimalPart = '';
        if(pointLength === -1) {
            // 如果指定小数位是-1，则默认原来的小数位
            decimalPart = numberArray.length > 1 ? ('.' + numberArray[1]) : '';
        } else if(pointLength === 0) {
            // 如果指定小数位是0，则不添加小数位
            decimalPart = '';
        } else {
            // 否则小数位不足2位的补齐为2位
            if(numberArray.length > 1) {
                if(numberArray[1].length >= 2) {
                    decimalPart = '.' + numberArray[1];
                } else if(numberArray[1].length == 1) {
                    decimalPart = '.' + numberArray[1] + '0';
                } else {
                    decimalPart = '.00';
                }
            } else {
                decimalPart = '.00';
            }
        }
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(integerPart)) {
            integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
        }
        return integerPart + decimalPart;
    },
    // 格式化金额
    formatAmount(num, pointLength) {
        if (num == null) return '';
        if (num == NaN || num == 'NaN') return '';
        if (num == 'undefined') return '';
        if (num == '--') return '--';
        if (num >= 0) {
            return '$' + this.formatNumber(num, pointLength);
        } else {
            return '($' + this.formatNumber(-num, pointLength) + ')';
        }
    },
    // 格式化数量
    formatQuantity(num, pointLength) {
        if (num == null) return '';
        if (num == NaN || num == 'NaN') return '';
        if (num == 'undefined') return '';
        if (num == '--') return '--';
        if (num >= 0) {
            return this.formatNumber(num, pointLength);
        } else {
            return '(' + this.formatNumber(-num, pointLength) + ')';
        }
    },
    // 忽略大小写判断相等
    equalsIgnoreCase(s1, s2) {
        if(s1) s1 = s1.toLowerCase();
        if(s2) s2 = s2.toLowerCase();
        return s1 == s2;
    },
    // 日期是否为半年末最后一天(6/30,12/31)
    isHalfYearEnd(date){
        var date = new Date(date);
        var month = date.getMonth();
        var day = date.getDate();
        if((month == 5 && day == 30) || (month == 11 && day == 31)) {
            return true;
        }
        return false;
    },
    // 判断字符串是否存在文本
    hasText(str) {
        return str && str.trim().length > 0;
    },
    // 计算公比
    calcCommonRatio(generation) {
        // 公比：g = w / (w - 1.618)
        return this.formatNumber(generation / (generation - 1.618), 6);
    },
    // 转为序数词
    toOrdinalSuffix(num) {
        const int = parseInt(num),
            digits = [int % 10, int % 100],
            ordinals = ['st', 'nd', 'rd', 'th'],
            oPattern = [1, 2, 3, 4],
            tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
        return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
            ? int + ordinals[digits[0] - 1]
            : int + ordinals[3];
    },
    // 数字转为罗马数字
    convertToRoman(num) {
        var roman = {
          M: 1000,
          CM: 900,
          D: 500,
          CD: 400,
          C: 100,
          XC: 90,
          L: 50,
          XL: 40,
          X: 10,
          IX: 9,
          V: 5,
          IV: 4,
          I: 1
        };
        var str = '';
        for (var i of Object.keys(roman)) {
          var q = Math.floor(num / roman[i]);
          num -= q * roman[i];
          str += i.repeat(q);
        }
        return str;
    },
    // 获取 UnNFT 页面 URL
    getUnNFTUrl(blockchain, id) {
        return '/unnft/' + (blockchain && blockchain.replace(/ /g, '-').toLowerCase()) + '/' + id;
    },
    // 清除钱包信息
    clearWallet() {
        Object.keys(localStorage)
            .filter(key => key.startsWith("wagmi") || key.startsWith("wc@2"))
            .forEach(key => localStorage.removeItem(key));
    },
    // 获取跳点步长
    getTickStep(decimals) {
        if (decimals) {
          return Math.pow(10, -decimals);
        }
        return 1;
    },
}