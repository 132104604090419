<template>
	<div class="bg-lightGray h-100">
        <div>
            <v-container class="mb-9">
                <v-card max-width="1400" elevation="0" color="transparent" align="center" class="pt-7 pb-7 mx-auto">
                    <v-card max-width="740" rounded="0" elevation="0" color="transparent" class="mb-8">
                        <div class="text-left text-primaryGrey">
                            <div>
                                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                            </div>
                            <h1 class="sub-page-h1 text-grey04">Notifications</h1>
                        </div>
                    </v-card>
                    <v-card max-width="740" color="black" elevation="12" align="start" class="pa-8 pt-0" rounded="0">
                        <div class="d-flex justify-space-between my-8">
                            <div>
                                <v-icon icon="mdi mdi-bell-outline pointer" class="text-primaryGrey" size="28" @click="refresh"></v-icon>
                            </div>
                            <!-- 标为已读 -->
                            <div v-if="token" class="body-p text-primaryGrey pointer" @click="readAll">
                                <v-icon icon="far fa-envelope-open" aria-label="envelope-open" class="mr-2 mb-1" size="18" />
                                <span class="del-underline text-primaryGrey gradient-text-hover">Mark all as read</span>
                            </div>
                        </div>
                        <!-- 通知 -->
                        <div v-infinite-scroll="getNotificationsByPage" :infinite-scroll-disabled="noMoreData">
                            <v-divider class="mb-5"></v-divider>
                            <div v-for="(notification, i) in notifications" :key="notification.id">
                                <v-hover v-slot="{ isHovering, props }">
                                    <v-card rounded="0" elevation="0" v-bind="props" :color="notification.read ? undefined : (isHovering ? 'e6e' : undefined)" class="pa-3" @click="!notification.read && read(notification.autoIndex)">
                                        <div class="d-flex justify-space-between">
                                            <div class="body-p-small text-primaryGrey mb-5">
                                                <span v-time="notification.timestamp"></span>
                                                <span>{{ ' (' + $tools.formatEngLishDatetime(notification.timestamp) + ')'}}</span>
                                            </div>
                                            <div>
                                                <v-avatar size="16" class="bg-8e8 pointer" v-if="!notification.read"></v-avatar>
                                            </div>
                                        </div>
                                        <!-- ----------- MESSAGE || TESTNET_CRYPTO_SELL_TRANSACTION_TASK_MESSAGE ----------- -->
                                        <div v-if="notification.type == 'MESSAGE' || notification.type == 'TESTNET_CRYPTO_SELL_TRANSACTION_TASK_MESSAGE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-html="notification.message"></span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- USER_FOLLOW ----------- -->
                                        <div v-if="notification.type == 'USER_FOLLOW'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> followed you.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- USER_UNFOLLOW ----------- -->
                                        <div v-if="notification.type == 'USER_UNFOLLOW'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> unfollowed you.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_LIKE ----------- -->
                                        <div v-if="notification.type == 'NFT_LIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> liked your unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_UNLIKE ----------- -->
                                        <div v-if="notification.type == 'NFT_UNLIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> unliked your unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_BUY ----------- -->
                                        <div v-if="notification.type == 'NFT_BUY'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> bought your unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_BUYER ----------- -->
                                        <div v-if="notification.type == 'NFT_BUYER'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-if="notification.nft?.id == null" class="fw-b">Unknown item may have been deleted.</span>
                                                            <span v-else>
                                                                <span>You have successfully bought unNFT </span>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        {{ notification.nft.name }}
                                                                    </router-link>
                                                                </span>
                                                                <span> and it is now available to </span>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="`/unnft/list/${notification.nft?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        list for sale
                                                                    </router-link>
                                                                </span>
                                                                <span>.</span>
                                                            </span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_TRANSFER_FROM ----------- -->
                                        <div v-if="notification.type == 'NFT_TRANSFER_FROM'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>The unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> has been transferred to </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_TRANSFER_TO ----------- -->
                                        <div v-if="notification.type == 'NFT_TRANSFER_TO'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>The unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> has been transferred from </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_REQUEST_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'NFT_REQUEST_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-if="notification.user.username == user.username">
                                                                You are
                                                            </span>
                                                            <span v-else>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        {{ notification.user.name }}
                                                                    </router-link>
                                                                </span>
                                                                <span> is</span>
                                                            </span>
                                                            <span> requesting to unwrap the unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/unnft/unwrap/' + notification.nft.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>. </span>
                                                            <span v-if="notification.user.username == user.username">Please wait for the originator to approve this Unwrapping Request.</span>
                                                            <span v-else>Click to approve this Unwrapping Request.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_SIGN_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'NFT_SIGN_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-if="notification.user.username == user.username">
                                                                You have
                                                            </span>
                                                            <span v-else>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        {{ notification.user.name }}
                                                                    </router-link>
                                                                </span>
                                                                <span> has</span>
                                                            </span>
                                                            <span> approved to unwrap the unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/unnft/unwrap/' + notification.nft.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>. </span>
                                                            <span v-if="notification.user.username == user.username">Please wait for the owner to confirm this Unwrapping Request.</span>
                                                            <span v-else>Click to confirm this Unwrapping Request.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_EXPIRE_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'NFT_EXPIRE_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>
                                                                The previous Unwrapping Request was canceled automatically for the unNFT 
                                                            </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/unnft/unwrap/' + notification.nft.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'NFT_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>The unNFT </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/unnft/unwrap/' + notification.nft.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> has been unwrapped. You can view it in the </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop to="/dashboard" class="del-underline text-primaryGrey gradient-text-hover">Dashboard</router-link>
                                                            </span>
                                                            <span> Unwrapped nFRs tab.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- NFT_FUTURE_REWARD_AMOUNT_RECEIVER ----------- -->
                                        <div v-if="notification.type == 'NFT_FUTURE_REWARD_AMOUNT_RECEIVER'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters align="center">
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11">
                                                        <span class="text-primaryGrey">
                                                            <span>Congratulations! You earned </span>
                                                            <span>{{ $tools.formatNumber(notification.nfrAmountStat?.amount, 8) }}</span>
                                                            <span>{{ ' ' + notification.nfrAmountStat?.paymentToken }}</span>
                                                            <span> in Future Rewards (FRs) from your position in Flow of </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="$tools.getUnNFTUrl(notification.nft.blockchain, notification.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.nft.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                            <span class="ml-5 body-p text-primaryGrey">
                                                                <ShareSocialMedia :text="getNFTFutureRewardAmountReveiverText(notification)" :facebook="false" :linkedin="false"></ShareSocialMedia>
                                                            </span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- SET_LIKE ----------- -->
                                        <div v-if="notification.type == 'SET_LIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> liked your Set </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/set/' + notification.set.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.set.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- SET_UNLIKE ----------- -->
                                        <div v-if="notification.type == 'SET_UNLIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> unliked your Set </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/set/' + notification.set.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.set.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- COLLECTION_LIKE  -->
                                        <div v-if="notification.type == 'COLLECTION_LIKE'">
                                            <div v-if="notification.user">
                                               <v-row no-gutters>
                                                    <v-col cols="1"><UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar></v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> liked your collection </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/collection/' + notification.collection.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.collection.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                               </v-row>
                                            </div>
                                        </div>
                                        <!-- COLLECTION_UNLIKE  -->
                                        <div v-if="notification.type == 'COLLECTION_UNLIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> unliked your collection </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/collection/' + notification.collection.id" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.collection.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_LIKE ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_LIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> liked your unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_UNLIKE ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_UNLIKE'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="ml-2 text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> unliked your unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_BUY ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_BUY'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span> bought your unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_BUYER ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_BUYER'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item may have been deleted.</span>
                                                            <span v-else>
                                                                <span>You have successfully bought unCrypto </span>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                    </router-link>
                                                                </span>
                                                                <span> and it is now available to </span>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="`/uncrypto/list/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        list for sale
                                                                    </router-link>
                                                                </span>
                                                                <span>.</span>
                                                            </span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_TRANSFER_FROM ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_TRANSFER_FROM'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>The unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span> has been transferred to </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_TRANSFER_TO ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_TRANSFER_TO'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>The unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span> has been transferred from </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.user.name }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_REQUEST_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_REQUEST_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-if="notification.user.username == user.username">
                                                                You are
                                                            </span>
                                                            <span v-else>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        {{ notification.user.name }}
                                                                    </router-link>
                                                                </span>
                                                                <span> is</span>
                                                            </span>
                                                            <span> requesting to unwrap the unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/unwrap/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>. </span>
                                                            <span v-if="notification.user.username == user.username">Please wait for the originator to approve this Unwrapping Request.</span>
                                                            <span v-else>Click to approve this Unwrapping Request.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_SIGN_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_SIGN_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span v-if="notification.user.username == user.username">
                                                                You have
                                                            </span>
                                                            <span v-else>
                                                                <span class="fw-b gradient-underline-hover">
                                                                    <router-link @click.stop :to="'/profile/' + notification.user.username" class="del-underline text-primaryGrey gradient-text-hover">
                                                                        {{ notification.user.name }}
                                                                    </router-link>
                                                                </span>
                                                                <span> has</span>
                                                            </span>
                                                            <span> approved to unwrap the unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/unwrap/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>. </span>
                                                            <span v-if="notification.user.username == user.username">Please wait for the owner to confirm this Unwrapping Request.</span>
                                                            <span v-else>Click to confirm this Unwrapping Request.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_EXPIRE_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_EXPIRE_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>
                                                                The previous Unwrapping Request was canceled automatically for the unCrypto 
                                                            </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/unwrap/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_UNWRAP ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_UNWRAP'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters>
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11" class="mt-2">
                                                        <span class="text-primaryGrey">
                                                            <span>The unCrypto </span>
                                                            <span v-if="notification.crypto?.tokenId == null" class="fw-b">Unknown item</span>
                                                            <span v-else class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/unwrap/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span> has been unwrapped. You can view it in the </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop to="/dashboard" class="del-underline text-primaryGrey gradient-text-hover">Dashboard</router-link>
                                                            </span>
                                                            <span> Unwrapped nFRs tab.</span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <!-- ----------- CRYPTO_FUTURE_REWARD_AMOUNT_RECEIVER ----------- -->
                                        <div v-if="notification.type == 'CRYPTO_FUTURE_REWARD_AMOUNT_RECEIVER'">
                                            <div v-if="notification.user">
                                                <v-row no-gutters align="center">
                                                    <v-col cols="1">
                                                        <UserAvatar :avatar="notification.user.avatar" :username="notification.user.username"></UserAvatar>
                                                    </v-col>
                                                    <v-col cols="11">
                                                        <span class="text-primaryGrey">
                                                            <span>Congratulations! You earned </span>
                                                            <span>{{ $tools.formatNumber(notification.nfrAmountStat?.amount, 6) }}</span>
                                                            <span>{{ ' ' + notification.nfrAmountStat?.paymentToken }}</span>
                                                            <span> in Future Rewards (FRs) from your position in Flow of </span>
                                                            <span class="fw-b gradient-underline-hover">
                                                                <router-link @click.stop :to="`/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`" class="del-underline text-primaryGrey gradient-text-hover">
                                                                    {{ notification.crypto?.wrappedCurrency }} #{{ $tools.formatNumber(notification.crypto?.tokenId, 0) }}
                                                                </router-link>
                                                            </span>
                                                            <span>.</span>
                                                            <span class="ml-5 body-p text-primaryGrey">
                                                                <ShareSocialMedia :text="getCryptoFutureRewardAmountReveiverText(notification)" :facebook="false" :linkedin="false"></ShareSocialMedia>
                                                            </span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </v-card>
                                    <!-- ----------- 分割线 ----------- -->
                                    <v-divider class="my-5"></v-divider>
                                </v-hover>
                            </div>
                        </div>
                        <!-- 加载骨架 -->
                        <div v-if="loading">
                            <SkeletonNotification :length="5"></SkeletonNotification>
                        </div>
                        <!-- 无可用数据 -->
                        <div v-show="noDataAvailable">
                            <NoDataAvailable></NoDataAvailable>
                        </div>
                    </v-card>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SkeletonNotification from '@/components/util/skeleton/SkeletonNotification.vue';
import SettingAPI from '@/api/setting.js';
import NotificationAPI from '@/api/notification.js';
export default {
    data(){
        return {
            // 通知列表
            notifications: [],
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            page: 1,
            size: 10,
        }
    },
    components: { SkeletonNotification },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['token', 'user'])
    },
    watch:{

    },
    methods: {
        // 分页查询通知信息
        async getNotificationsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await NotificationAPI.getNotificationsByPage(this.page, this.size);
            let data = res.data;
            if(data.success) {
                this.notifications.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.notifications.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.notifications.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 阅读通知
        async read(index) {
            let res = await NotificationAPI.read(index);
            let data = res.data;
            if(data.success) {
                // 计算未读数量
                this.$bus.emit('emitCalcUnreadCount', 1);
                // 标记当前数据为已读
                this.notifications.filter(n => n.autoIndex == index).map(n => n.read = true);
            }
        },
        // 阅读全部通知
        async readAll() {
            let res = await NotificationAPI.readAll();
            let data = res.data;
            if(data.success) {
                // 重新查询未读数量
                this.$bus.emit('emitGetUnreadCount', null);
                // 标记全部数据为已读
                this.notifications.map(n => n.read = true);
            }
        },
        // 刷新
        refresh() {
            // 恢复data定义的所有参数
            Object.assign(this.$data, this.$options.data.call(this));
            // 重新查询数据
            this.getNotificationsByPage();
            // 重新查询未读数量
            this.$bus.emit('emitGetUnreadCount', null);
        },
        // NFT 未来奖励金额分享文本
        getNFTFutureRewardAmountReveiverText(notification) {
            let url = this.$config.domain + this.$tools.getUnNFTUrl(notification?.nft?.blockchain, notification?.nft?.id);
            let text = '🚀 Exciting news! 🎁 \n\nI just earned ' + this.$tools.formatNumber(notification.nfrAmountStat?.amount, 8) + ' ' + notification.nfrAmountStat?.paymentToken + ' in Future Rewards (FRs) from my position in Flow of ' + notification.nft?.name
                + ' AFTER selling it on @untradingOrg . \n\n' + url + ' \n\n#DeFi #FutureRewards\n';
            return text;
        },
        // Crypto 未来奖励金额分享文本
        getCryptoFutureRewardAmountReveiverText(notification) {
            let url = this.$config.domain + `/uncrypto/${notification.crypto?.wrappedCurrency.toLowerCase()}/${notification.crypto?.id}`;
            let text = '🚀 Exciting news! 🎁 \n\nI just earned ' + this.$tools.formatNumber(notification.nfrAmountStat?.amount, 6) + ' ' + notification.nfrAmountStat?.paymentToken + ' in Future Rewards (FRs) from my position in Flow of ' + `${ notification.crypto?.wrappedCurrency } #${ this.$tools.formatNumber(notification.crypto?.tokenId, 0) }`
                + ' AFTER selling it on @untradingOrg . \n\n' + url + ' \n\n#DeFi #FutureRewards\n';
            return text;
        },
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
</style>