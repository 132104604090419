let Time = {
  	//获取当前时间戳
  	getUnix: function(){
  		var date = new Date();
  		return date.getTime();
  	},
  	//获取今天0点0分0秒的时间戳
  	getTodayUnix: function(){
  		var date = new Date();
  		date.setHours(0);
  		date.setMinutes(0);
  		date.setSeconds(0);
  		date.setMilliseconds(0);
  		return date.getTime();
  	},
  	//获取今年1月1日0点0分0秒的时间戳
  	getYearUnix: function(){
  		var date = new Date();
  		date.setMonth(0);
  		date.setDate(1);
  		date.setHours(0);
  		date.setMinutes(0);
  		date.setSeconds(0);
  		date.setMilliseconds(0);
  		return date.getTime();
  	},
  	//获取标准年月日
  	getLastDate: function(time){
  		var date = new Date(time);
  		var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  		var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  		return date.getFullYear() + '-' + month + '-' + day;
  	},
  	//转换时间
  	getFormatTime: function(timeStamp){
  		var now = Time.getUnix();	//当前时间戳
  		var today = Time.getTodayUnix();	//今天0点时间戳
  		var year = Time.getYearUnix();	//今年0点时间戳
  		var timer = (now - timeStamp) / 1000;	//转换为秒级时间戳
      // 几秒钟前
  		if(timer <= 0 || Math.floor(timer/60) <=0){
  			return 'a few seconds ago';
  		}
      // 1小时以内
  		if(timer < 3600){
        let m = Math.floor(timer/60);
        if(m == 1){
          return m + (' minute ago');
        } else {
          return m + (' minutes ago');
        }
  		}
      // 当天的24小时内
  		if(timer >=3600 && (timeStamp - today >= 0)){
        let h = Math.floor(timer/3600);
        if(h == 1){
          return h + (' hour ago');
        } else {
          return h + (' hours ago');
        }
  		}
      // 超过当天0点的
  		if(timer/86400 <= 30){
        let d = Math.floor(timer/86400);
        if(d == 0){
          // 虽然隔天但还在24小时内
          let h = Math.floor(timer/3600);
          if(h == 1){
            return h + (' hour ago');
          } else {
            return h + (' hours ago');
          }
        } else if (d == 1) {
          return d + (' day ago');
        } else {
          return d + (' days ago');
        }
  		}
      // 几个月
      if(timer/86400/30 <= 12){
          let M = Math.floor(timer/86400/30);
          if(M == 1){
          return M + (' month ago');
          } else {
          return M + (' months ago');
          }
      }
      // 几年
      let y = Math.floor(timer/86400/365);
      if(y == 1){
      return y + (' year ago');
      } else {
      return y + (' years ago');
      }
      return '';
  	}
}

const TimeDirective = {
    // 在绑定元素的 attribute 前
    // 或事件监听器应用前调用
    created(el, binding, vnode, prevVnode) {
        let timestamp = new Date().getTime();
        // 给vnode更新key，用于数据更新时重新渲染改节点元素
        vnode.key = timestamp;
        el.innerHTML = Time.getFormatTime(binding.value);
        el.__timeout__ = setInterval(function(){
          el.innerHTML = Time.getFormatTime(binding.value);
        }, 60000);
    },
    // 在元素被插入到 DOM 前调用
    beforeMount(el, binding, vnode, prevVnode) {},
    // 在绑定元素的父组件
    // 及他自己的所有子节点都挂载完成后调用
    mounted(el, binding, vnode, prevVnode) {},
    // 绑定元素的父组件更新前调用
    beforeUpdate(el, binding, vnode, prevVnode) {},
    // 在绑定元素的父组件
    // 及他自己的所有子节点都更新后调用
    updated(el, binding, vnode, prevVnode) {},
    // 绑定元素的父组件卸载前调用
    beforeUnmount(el, binding, vnode, prevVnode) {
        clearInterval(el.__timeout__);
        delete el.__timeout__;
    },
    // 绑定元素的父组件卸载后调用
    unmounted(el, binding, vnode, prevVnode) {}
}

export default TimeDirective
