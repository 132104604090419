<template>
	<div>
        <v-menu location="top right">
            <template v-slot:activator="{ props }">
                <v-icon icon="fa-solid fa-ellipsis" :color="color" v-bind="props"></v-icon>
            </template>                    
            <v-list>

                <!-- --------------- List --------------- -->
                <router-link :to="`/uncrypto/list/${cryptoId}`" class="del-underline" v-if="!isUnwrapped && isOwner && !isListed">
                    <v-list-item :class="`form-text text-${color}`">
                        <v-list-item-title>List</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="!isUnwrapped && isOwner && !isListed"></v-divider>

                <!-- --------------- Change price --------------- -->
                <router-link :to="`/uncrypto/list/${cryptoId}`" class="del-underline" v-if="!isUnwrapped && isOwner && isListed">
                    <v-list-item :class="`form-text text-${color}`">
                        <v-list-item-title>Change price</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="!isUnwrapped && isOwner && isListed"></v-divider>

                <!-- --------------- Unlist --------------- -->
                <router-link :to="`/uncrypto/list/${cryptoId}`" class="del-underline" v-if="!isUnwrapped && isOwner && isListed">
                    <v-list-item :class="`form-text text-${color}`">
                        <v-list-item-title>Unlist</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="!isUnwrapped && isOwner && isListed"></v-divider>

                <!-- --------------- Unwrap --------------- -->
                <router-link :to="`/uncrypto/unwrap/${cryptoId}`" class="del-underline" v-if="!isUnwrapped && (hasRequestUnwrapPermission || hasSignUnwrapPermission || hasConfirmUnwrapPermission)">
                    <v-list-item :class="`form-text text-${color}`">
                        <v-list-item-title>Unwrap</v-list-item-title>
                    </v-list-item>
                </router-link>
                <v-divider v-if="!isUnwrapped && (hasRequestUnwrapPermission || hasSignUnwrapPermission || hasConfirmUnwrapPermission)"></v-divider>

                <!-- --------------- Copy link --------------- -->
                <v-list-item :class="`form-text text-${color}`">
                    <v-list-item-title>
                        <Clipboard :text="currentUrl">Copy link</Clipboard>
                    </v-list-item-title>
                </v-list-item>

            </v-list>
        </v-menu>
	</div>
</template>
<script>
export default {
    data(){
        return {
            // 当前网址
            currentUrl: window.location.href,
        }
    },
    props: {
        // 颜色
        color: {
            type: String,
            default: 'primaryGrey'
        },
        // 加密货币主键
        cryptoId: {
            type: String,
            default: null
        },
        // 区块链
        blockchain: {
            type: String,
            default: null
        },
        // 我是发起人吗
        isOriginator: {
            type: Boolean,
            default: false
        },
        // 我是拥有者吗
        isOwner: {
            type: Boolean,
            default: false
        },
        // 是否已上市
        isListed: {
            type: Boolean,
            default: false
        },
        // 是否已解封
        isUnwrapped: {
            type: Boolean,
            default: false
        },
        // 是否有请求解封权限
        hasRequestUnwrapPermission: {
            type: Boolean,
            default: false
        },
        // 是否有签名解封权限
        hasSignUnwrapPermission: {
            type: Boolean,
            default: false
        },
        // 是否有确认解封权限
        hasConfirmUnwrapPermission: {
            type: Boolean,
            default: false
        },
    },
    components: { },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>