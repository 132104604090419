<template>
    <!-- 分享媒体 -->
    <!-- 使用示例：<ShareSocialMedia :url="url" :text="text"></ShareSocialMedia> -->
    <v-menu open-on-hover>
        <template v-slot:activator="{ props }">
            <v-icon icon="mdi:mdi-share-variant" class="text-primaryGrey gradient-text-hover pointer" size="18" v-bind="props"></v-icon>
        </template>
        <v-list>
            <!-- 复制链接 -->
            <v-list-item v-if="copy">
                <v-list-item-title>
                    <v-icon icon="icon-link" class="text-primaryGrey" size="20"></v-icon>
                    <span class="ml-3 gradient-underline-hover">
                        <Clipboard :text="text">
                            <a class="text-primaryGrey gradient-text-hover">
                                <span v-if="url == null && text != null">Copy text</span>
                                <span v-else>Copy link</span>
                            </a>
                        </Clipboard>
                    </span>
                </v-list-item-title>
            </v-list-item>
            <!-- 分享媒体 -->
            <v-list-item v-for="(menu, index) in shareMenus" :key="index" :value="index" v-show="menu.show">
                <v-list-item-title class="gradient-underline-hover">
                    <v-icon :icon="menu.icon" class="text-primaryGrey" size="20"></v-icon>
                    <a :href="share(menu.type)" class="ml-3 text-primaryGrey gradient-text-hover" target="_blank">{{ menu.title }}</a>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    name: 'ShareSocialMedia',
    data(){
        return {
            id: 'share-' + Math.random(),
        }
    },
    props: {
        url: { type: String, default: null },
        text: { type: String, default: null },
        copy: { type: Boolean, default: true },
        twitter: { type: Boolean, default: true },
        facebook: { type: Boolean, default: true },
        linkedin: { type: Boolean, default: true }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        // 分享菜单
        shareMenus(){
            return [
                { type: 'X', title: 'Share on 𝕏', icon: 'icon-x', show: this.twitter },
                { type: 'facebook', title: 'Share on Facebook', icon: 'icon-facebook', show: this.facebook },
                { type: 'linkedin', title: 'Share on LinkedIn', icon: 'icon-linkedin', show: this.linkedin },
            ]
        },
    },
    watch:{

    },
    methods: {
        // 分享
        share(type) {
            switch(type) {
                case 'X':
                    // API - https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
                    return "https://x.com/intent/tweet?text=" + encodeURIComponent(this.text);
                case 'facebook':
                    // API - https://developers.facebook.com/docs/sharing/reference/share-dialog
                    return "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(this.url);
                case 'linkedin':
                    // API - https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/plugins/share-plugin
                    return "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(this.url);
            }
        },
    }
}
</script>
<style scoped>

</style>