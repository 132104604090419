import axios from '@/config/api.request'

export default {
    // 根据加密货币主键查询黑名单集合
    getBlacklistByCryptoId(cryptoId) {
        return axios.request({
            url: '/crypto/blacklist/blacklists',
            method: 'get',
            params: { cryptoId }
        })
    }
}