<template>
	<div>
        <!-- 钱包网络错误 -->
        <v-dialog v-model="invaildNetworkDialog" persistent content-class="v-echarts-dialog">
            <v-card width="462" class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters align="center">
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Invaild blockchain network</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <div class="body-p text-primaryGrey mb-16">Please select {{ currentBlockchain.blockchain }} in your wallet.</div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn :rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="closeInvaildNetworkDialog()">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import * as Ethers from "ethers";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { w3mProvider } from '@web3modal/ethereum'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli } from '@wagmi/core/chains'
import { 
    configureChains as wagmiConfigureChains,
    createConfig as wagmiCreateConfig,
    connect as wagmiConnect, 
    disconnect as wagmiDisconnect, 
    signMessage as wagmiSignMessage,
    sendTransaction as wagmiSendTransaction,
    getAccount as wagmiGetAccount,
    getNetwork as wagmiGetNetwork,
    switchNetwork as wagmiSwitchNetwork,
} from '@wagmi/core'
import UserAPI from '@/api/user.js'
export default {
    data(){
        return {
            // 项目ID：https://cloud.walletconnect.com/app
            projectId: '559db3265ecd5011af4e7884df5d4f91',
            // 无效的网络弹窗
            invaildNetworkDialog: false,
            // 当前交易的区块链
            currentBlockchain: {}
        }
    },
    components: { },
    created(){
        this.createConfig();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['walletAddress', 'blockchains'])
    },
    watch:{

    },
    methods: {
        // 创建 WC 连接器
        createWalletConnectConnector() {
            // WalletConnect 的项目ID：https://cloud.walletconnect.com/app
            let projectId = this.projectId;
            // 支持的区块链：https://wagmi.sh/core/chains
            let chains = [arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli];
            // 创建连接器 WalletConnect: https://wagmi.sh/core/connectors/walletConnect
            const walletConnectConnector = new WalletConnectConnector({
                chains: chains,
                options: {
                    projectId: projectId,
                    showQrModal: true
                },
            })
            return walletConnectConnector;
        },
        // 创建 wagmi 配置
        createConfig() {
            let projectId = this.projectId;
            let chains = [arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli];
            // 配置链
            const { publicClient } = wagmiConfigureChains(chains, [w3mProvider({ projectId })])
            // 创建配置，接受的变量暂时不用
            const wagmiConfig = wagmiCreateConfig({
                autoConnect: true,
                connectors: [ this.createWalletConnectConnector() ],
                publicClient
            });
        },
        // 链接 WalletConnect
        async connect(chainId) {
            // Web3Model HTML Installation: https://docs.walletconnect.com/2.0/web3modal/html/wagmi/installation
            // 创建之前，先把缓存链接断开
            await wagmiDisconnect();
            // 创建 WC 连接器: https://wagmi.sh/core/connectors/walletConnect
            let walletConnectConnector = this.createWalletConnectConnector();
            // 监听 Connected
            walletConnectConnector.on("connect", () => {
                console.log("WalletConnect Connected!")
            });
            // 链接 connect: https://wagmi.sh/core/actions/connect
            // 响应: {account: '0xE7277D5b612A4F84f8f9D92affbBC219aF401E81', chain: {id: 137, unsupported: false}, connector: WalletConnectConnector}
            await wagmiConnect({
                chainId: chainId ? chainId : mainnet.id,
                connector: walletConnectConnector
            }).then(res => {
                console.log(res);
                this.$store.dispatch('walletAddressHandler', res.account);
                this.$emit('emitGetRegisterStatus', null)
                return new Promise((resolve, reject) => {
                    resolve(res);
                });
            }).catch(err => {
                console.error(err);
                this.$store.dispatch('snackbarMessageHandler', "User rejected the request.");
                this.$emit('transactionClose', false, null)
                this.$emit('approveTransactionClose', false, null)
                return new Promise((resolve, reject) => {
                    reject(new Error("User rejected the request."));
                });
            });
        },
        // 签名 WalletConnect
        async sign(message) {
            // 签名: https://wagmi.sh/core/actions/signMessage
            wagmiSignMessage({
                message: message,
            }).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature);
            }).catch(err => {
                // Error returned when rejected
                console.error(err);
                this.$emit('signError', null)
                this.$store.dispatch('snackbarMessageHandler', "User rejected the request.");
            });
        },
        // 签名 WalletConnect
        async signTypedData(blockchain, tokenId) {
            // signTypedData: https://wagmi.sh/core/actions/signTypedData
            // 当前交易的区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                this.$emit('signError', null)
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID
            let currentBlockchainChainId = Number(web3.utils.toBN(currentBlockchain.chainId).toString());
            // 查询账户
            const account = await wagmiGetAccount();
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
            }
            // Ethers 签名
            let domain = {
                name: "untrading Shared Contract",
                version: "1",
                chainId: currentBlockchain.chainId,
                verifyingContract: currentBlockchain.unNFTContract,
                salt: "0x48cdc51538d5c18ef784aab525d9823a2119e55be5a1288a7a9c675f0f202bdd"
            };
            let types = { 
                Unwrap: [
                    { name: "tokenId", type: "uint256" }
                ]
            };
            let params = { tokenId: tokenId };
            // 支持的区块链：https://wagmi.sh/core/chains
            let chains = [arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli];
            let chainIds = chains.map(chain => chain.id);
            // 创建基于 EIP-1193 的 Ethereum Provider: https://docs.walletconnect.com/2.0/advanced/providers/ethereum
            let ethereumProvider = await EthereumProvider.init({
                projectId: this.projectId,
                chains: [currentBlockchainChainId], // chains added to required namespaces
                optionalChains: chainIds // chains added to optional namespaces
            });
            // 创建 Ethers 的 Web3Provider: https://docs.ethers.org/v5/api/providers/other/#Web3Provider
            let ethers = new Ethers.providers.Web3Provider(ethereumProvider);
            ethers.getSigner()._signTypedData(domain, types, params).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature)
            }).catch(err => {
                // 签名失败
                console.error(err);
                this.$emit('signError', null)
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
        },
        // 签名 WalletConnect
        async signUnCryptoTypedData(cryptoWrapped, blockchain, to, tokenId) {
            // signTypedData: https://wagmi.sh/core/actions/signTypedData
            // 当前交易的区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                this.$emit('signError', null)
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID
            let currentBlockchainChainId = Number(web3.utils.toBN(currentBlockchain.chainId).toString());
            // 查询账户
            const account = await wagmiGetAccount();
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
            }
            // Ethers 签名
            let domain = {
                name: cryptoWrapped.name,
                version: "1",
                chainId: currentBlockchain.chainId,
                verifyingContract: cryptoWrapped.address,
                salt: "0xc25ebea6dd97ec30f15ce845010d7e9fee0398194f29ee544b5062c074544590"
            };
            let types = { 
                Unwrap: [
                    { name: "to", type: "address" },
                    { name: "tokenId", type: "uint256" }
                ]
            };
            let params = { to: to, tokenId: tokenId };
            // 支持的区块链：https://wagmi.sh/core/chains
            let chains = [arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli];
            let chainIds = chains.map(chain => chain.id);
            // 创建基于 EIP-1193 的 Ethereum Provider: https://docs.walletconnect.com/2.0/advanced/providers/ethereum
            let ethereumProvider = await EthereumProvider.init({
                projectId: this.projectId,
                chains: [currentBlockchainChainId], // chains added to required namespaces
                optionalChains: chainIds // chains added to optional namespaces
            });
            // 创建 Ethers 的 Web3Provider: https://docs.ethers.org/v5/api/providers/other/#Web3Provider
            let ethers = new Ethers.providers.Web3Provider(ethereumProvider);
            ethers.getSigner()._signTypedData(domain, types, params).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature)
            }).catch(err => {
                // 签名失败
                console.error(err);
                this.$emit('signError', null)
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
        },
        // 发送交易
        async sendTransaction(blockchain, from, to, data, value) {
            // 判断账户登录过期时间
            let res = await UserAPI.getExpiration();
            let expirationData = res.data;
            if(expirationData.success) {
                let expiration = expirationData.data;
                // 过期时间在1小时以内的，直接重新登录
                if(expiration - new Date().getTime() < 60 * 60 * 1000) {
                    this.$emit('transactionClose', false, null)
                    this.$store.commit("tokenHandler", null);
                    this.$router.push("/connectwallet?redirectUrl=" + window.location.href);
                    return;
                }
            }
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID
            let currentBlockchainChainId = Number(web3.utils.toBN(this.currentBlockchain.chainId).toString());
            // 查询账户
            const account = await wagmiGetAccount();
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
            }
            // 查询当前钱包链接的网络
            const network = await wagmiGetNetwork();
            // 网络不一致则停止交易，重新连接
            if(network.chain.id != currentBlockchainChainId) {
                console.log("Connected network: ", network.chain.id);
                console.log("Target network: ", currentBlockchainChainId);
                console.log("wagmi switch network...");
                this.invaildNetworkDialog = true;
                this.$emit('transactionClose', false, null)
                // 创建之前，先把缓存链接断开
                await wagmiDisconnect();
                // 清除钱包缓存
                this.$tools.clearWallet();
                // const switchedNetwork = await wagmiSwitchNetwork({
                //     chainId: currentBlockchainChainId,
                // });
                // console.log(switchedNetwork);
                return;
            }
            // 请求超时定时器
            let timeoutTimer = setTimeout(() => {
                this.$store.dispatch('snackbarMessageHandler', "Request timeout");
                this.$emit('transactionClose', false, null)
            }, 60000);
            console.log("wagmi send transaction...");
            // 发送交易
            try {
                let hashResult = await wagmiSendTransaction({
                    chainId: currentBlockchainChainId,
                    account: from,
                    to: to,
                    data: data,
                    value: value
                });
                // 交易成功
                console.log(hashResult.hash);
                this.$emit('transactionClose', true, hashResult.hash)
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            } catch (error) {
                console.error(err);
                this.$emit('transactionClose', false, null)
                // -32603: Gas 太小错误
                if(err.code == -32603) {
                    this.$store.dispatch('snackbarMessageHandler', "Transaction underpriced");
                } else {
                    this.$store.dispatch('snackbarMessageHandler', "User rejected the request.");
                }
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            }
            console.log("wagmi close transaction!");
        },
        // 批准
        async approve(blockchain, from, to, data, value) {
            // 判断账户登录过期时间
            let res = await UserAPI.getExpiration();
            let expirationData = res.data;
            if(expirationData.success) {
                let expiration = expirationData.data;
                // 过期时间在1小时以内的，直接重新登录
                if(expiration - new Date().getTime() < 60 * 60 * 1000) {
                    this.$emit('approveTransactionClose', false, null)
                    this.$store.commit("tokenHandler", null);
                    this.$router.push("/connectwallet?redirectUrl=" + window.location.href);
                    return;
                }
            }
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID
            let currentBlockchainChainId = Number(web3.utils.toBN(this.currentBlockchain.chainId).toString());
            // 查询账户
            const account = await wagmiGetAccount();
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
            }
            // 查询当前钱包链接的网络
            const network = await wagmiGetNetwork();
            // 网络不一致则停止交易，重新连接
            if(network.chain.id != currentBlockchainChainId) {
                console.log("Connected network: ", network.chain.id);
                console.log("Target network", currentBlockchainChainId);
                console.log("wagmi switch network...");
                this.invaildNetworkDialog = true;
                this.$emit('approveTransactionClose', false, null)
                // 创建之前，先把缓存链接断开
                await wagmiDisconnect();
                // 清除钱包缓存
                this.$tools.clearWallet();
                // const switchedNetwork = await wagmiSwitchNetwork({
                //     chainId: currentBlockchainChainId,
                // });
                // console.log(switchedNetwork);
                return;
            }
            // 请求超时定时器
            let timeoutTimer = setTimeout(() => {
                this.$store.dispatch('snackbarMessageHandler', "Request timeout");
                this.$emit('approveTransactionClose', false, null)
            }, 60000);
            console.log("wagmi send transaction...");
            // 发送交易
            try {
                let hashResult = await wagmiSendTransaction({
                    chainId: currentBlockchainChainId,
                    account: from,
                    to: to,
                    data: data,
                    value: value
                });
                // 交易成功
                console.log(hashResult.hash);
                this.$emit('approveTransactionClose', true, hashResult.hash)
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            } catch (error) {
                console.error(err);
                this.$emit('approveTransactionClose', false, null)
                // -32603: Gas 太小错误
                if(err.code == -32603) {
                    this.$store.dispatch('snackbarMessageHandler', "Transaction underpriced");
                } else {
                    this.$store.dispatch('snackbarMessageHandler', "User rejected the request.");
                }
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            }
            console.log("wagmi close transaction!");
        },
        // 关闭无效网络弹窗
        closeInvaildNetworkDialog() {
            this.invaildNetworkDialog = false;
            location.reload();
        },
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>