const state = () => ({
	metaMask: null,
    walletConnectConnector: null,
    walletConnectProvider: null
})

const getters = {
    metaMask: state => state.metaMask,
    walletConnectConnector: state => state.walletConnectConnector,
    walletConnectProvider: state => state.walletConnectProvider,
}

const actions = {
    metaMaskHandler(context, payload){
		context.commit('metaMaskHandler', payload);
	},
    walletConnectConnectorHandler(context, payload){
		context.commit('walletConnectConnectorHandler', payload);
	},
    walletConnectProviderHandler(context, payload){
		context.commit('walletConnectProviderHandler', payload);
	}
}

const mutations = {
    metaMaskHandler(state, metaMask){
		state.metaMask = metaMask;
	},
    walletConnectConnectorHandler(state, walletConnectConnector){
		state.walletConnectConnector = walletConnectConnector;
	},
    walletConnectProviderHandler(state, walletConnectProvider){
		state.walletConnectProvider = walletConnectProvider;
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}