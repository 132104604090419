<template>
	<div v-for="i in length" :key="i">
        <v-row justify="center" no-gutters>
            <v-col cols="12">
                <div>
                    <v-card :class="bgColor" width="60%" height="60" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-8">
                    <v-card :class="bgColor" width="100" height="24" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-8">
                    <v-divider class="r-m-x-0"></v-divider>
                </div>
                <div class="mt-8">
                    <v-card :class="bgColor" width="300" height="48" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-8">
                    <v-card v-for="j in 10" :key="`data-1-${j}`" :class="bgColor" class="mb-2" height="36" rounded="0" elevation="0"></v-card>
                </div>
                <div class="float-right">
                    <v-card :class="bgColor" class="mb-2" width="300" height="48" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-16 pt-16">
                    <v-divider class="r-m-x-0"></v-divider>
                </div>
                <div class="mt-8">
                    <v-card :class="bgColor" width="300" height="48" rounded="0" elevation="0"></v-card>
                </div>
                <div class="mt-8">
                    <v-card v-for="k in 10" :key="`data-2-${k}`" :class="bgColor" class="mb-2" height="36" rounded="0" elevation="0"></v-card>
                </div>
                <div class="float-right">
                    <v-card :class="bgColor" class="mb-2" width="300" height="48" rounded="0" elevation="0"></v-card>
                </div>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {
            // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        },
        bgColor: {
            type: String,
            default: 'bg-lightGray'
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>