<template>
	<div>
        <v-col cols="12" class="px-0">
            <div class="d-flex flex-column">
                <div class="h5 text-primaryGrey">
                    Powered by <span class="text-black01">ERC-5173</span> Standard
                </div>
                <div class="h1 mt-15">
                    Profit Continues
                </div>
                <h3 class="fw-b">after Selling</h3>
                <div class="title-h4 mt-10 text-primaryGrey">
                    Untrading is the revolutionary commission-free protocol that keeps you in the game, even after you cash out. Every sale plants seeds for future growth. 
                </div>
                <div class="mt-100 d-flex ml-1">
                    <router-link to="/uncryptos" class="del-underline gradient-left-red-purple-200-dark mr-16">
                        <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small" width="200" height="48" aria-label="unCrypto App">unCrypto App</v-btn>
                    </router-link>
                    <router-link to="/unnfts" class="del-underline gradient-left-red-purple-200-dark">
                        <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white body-p-small" width="200" height="48" aria-label="unNFT App">unNFT App</v-btn>
                    </router-link>
                </div>
            </div>
        </v-col>        
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>