<template>
	<div class="align-center bg-f2f mb-16" :style="`padding-bottom: ${moreCryptosHeight}px`">
        <v-container id="fadeIn" class="crypto-detail bg-f2f r-p-b-0">
            <v-card color="f2f" rounded="0" elevation="0" max-width="1440" class="mx-auto pt-4 pr-16 r-p-r-1">
                <router-link to="/uncryptos" class="del-underline gradient-text-hover">
                    <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3"></v-icon>
                </router-link>
                <v-row no-gutters justify="center">
                    <v-col cols="12" lg="6">
                        <div class="pr-16 r-p-r-0">
                            <!-- PC 端显示在右侧，手机端显示在图表上方 -->
                            <!-- ------------------- 报价 -------------------- -->
                            <UnCryptoOffer class="mb-10" v-if="responsive != 'pc'"></UnCryptoOffer>
                            <!-- ------------------- 原始信息 -------------------- -->
                            <UnCryptoOwner :totalNFRPaid="nfr.totalNFRPaid"></UnCryptoOwner>
                            <!-- ------------------- 交易记录 -------------------- -->
                            <Provenance :cryptoId="id" :blockchain="unCrypto.blockchain" :contractAddress="unCrypto.contractAddress" :paymentTokenAddress="unCrypto.paymentTokenAddress"></Provenance>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card max-width="650" class="pa-8 pt-0 mb-16 pr-0 r-p-x-0" elevation="0" rounded="0" color="transparent">
                            <v-row no-gutters>
                                <!-- PC 端显示在右侧，手机端在 UnCryptoOwner 组件，显示在图表下方 -->
                                <v-col cols="12" class="px-0 pt-0" v-if="responsive == 'pc'">
                                    <!-- ------------------- 报价 -------------------- -->
                                    <UnCryptoOffer></UnCryptoOffer>
                                </v-col>
                                <!-- ------------------- 描述 ---------------------->
                                <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                    <h2 class="body-text-btn text-primaryGrey">Description</h2>
                                    <v-divider class="my-3"></v-divider>
                                    <h6 class="body-p-small text-primaryGrey">
                                        <div v-if="unCrypto.cryptoBase != null">
                                            <p>{{ unCrypto.wrappedCurrency }} is a form of {{ unCrypto.cryptoBase?.name }} ({{ unCrypto.cryptoBase?.currency }}) that follows specific token standards outlined in EIP-5173. Each {{ unCrypto.wrappedCurrency }} token is fully backed by an underlying {{ unCrypto.cryptoBase?.currency }} token in a 1:1 ratio. The main difference between {{ unCrypto.wrappedCurrency }} and {{ unCrypto.cryptoBase?.currency }} lies in their use cases. {{ unCrypto.wrappedCurrency }} tokens offer additional features, such as on-chain Originator Rewards and Future Rewards functionalities.</p><br/>
                                            <p>To create {{ unCrypto.wrappedCurrency }}, {{ unCrypto.cryptoBase?.currency }} tokens are sent to a smart contract, which generates the corresponding {{ unCrypto.wrappedCurrency }} tokens while locking the {{ unCrypto.cryptoBase?.currency }} as collateral. This ensures that {{ unCrypto.wrappedCurrency }} tokens have a reserve of {{ unCrypto.cryptoBase?.currency }} backing them. Importantly, the smart contracts involved in this process are non-custodial, meaning that neither untrading.org nor any other platform has control over the locked {{ unCrypto.cryptoBase?.currency }} tokens. Only the Originator (the one who initiated the wrapping) and the current Owner of the {{ unCrypto.wrappedCurrency }} have access to the locked {{ unCrypto.cryptoBase?.currency }} tokens.</p><br/>
                                            <p>If desired, an {{ unCrypto.wrappedCurrency }} token can be unwrapped and converted back into {{ unCrypto.cryptoBase?.currency }}. To initiate the unwrapping process, both the current Owner and the Originator must sign and approve the unwrapping transaction. This dual approval mechanism enhances the security and integrity of the unwrapping process.</p><br/>
                                        </div>
                                        <div v-else>--</div>
                                    </h6>
                                </v-col>
                                <!-- ------------------- 部门 -------------------- -->
                                <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                    <h2 class="body-text-btn text-primaryGrey">Department</h2>
                                    <v-divider class="my-3"></v-divider>
                                    <h6 class="body-p-small text-primaryGrey gradient-underline-hover">
                                        {{ unCrypto.department }}
                                    </h6>
                                </v-col>
                                <!-- ------------------- 详情 -------------------- -->
                                <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                    <h2 class="body-text-btn text-primaryGrey">Details</h2>
                                    <v-divider class="my-3"></v-divider>
                                    <div class="body-p-small">
                                        <v-row no-gutters>
                                            <v-col>
                                                <div class="text-primaryGrey">Contract Address</div>
                                                <div class="text-primaryGrey">
                                                    <span class="gradient-underline-hover">
                                                        <a :href="`${$tools.getBlockchainExplorerUrl(unCrypto.blockchain)}/address/${unCrypto.contractAddress}`" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                            {{ $tools.privacyField(unCrypto.contractAddress) }}
                                                        </a>
                                                    </span>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div class="text-primaryGrey">Token ID</div>
                                                <div class="text-primaryGrey">
                                                    <span class="gradient-underline-hover">
                                                        <a :href="`${$tools.getBlockchainExplorerUrl(unCrypto.blockchain)}/token/${unCrypto.contractAddress}?a=${unCrypto.tokenId}`" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                            {{ unCrypto.tokenId }}
                                                        </a>
                                                    </span>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div class="text-primaryGrey">Token Standard</div>
                                                <div class="text-primaryGrey">ERC721, ERC5173</div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-8">
                                            <v-col>
                                                <div class="text-primaryGrey">Blockchain</div>
                                                <div class="text-primaryGrey">{{ unCrypto.blockchain }}</div>
                                            </v-col>
                                        </v-row>
                                        <!-- pc -->
                                        <v-row no-gutters class="mt-8" align="center">
                                            <v-col class="d-flex align-center text-primaryGrey">
                                                <v-icon color="text-primaryGrey" size="16" v-if="unCrypto.blockchain == 'Ethereum' || unCrypto.blockchain == 'Goerli' || unCrypto.blockchain == 'Sepolia'">icon-etherscan</v-icon>
                                                <v-icon color="text-primaryGrey" size="16" v-if="unCrypto.blockchain == 'Arbitrum One'">icon-arbitrum</v-icon>
                                                <v-icon color="text-primaryGrey" size="16" v-if="unCrypto.blockchain == 'Polygon' || unCrypto.blockchain == 'Mumbai'">icon-polygonscan</v-icon>
                                                <span class="gradient-underline-hover ml-1">
                                                    <a :href="`${$tools.getBlockchainExplorerUrl(unCrypto.blockchain)}/tx/${unCrypto.transactionHash}`" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                        View on {{ currentBlockchain.blockchainExplorer }}
                                                    </a>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <!-- ------------------- 黑名单 -------------------- -->
                                <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                    <h2 class="body-text-btn text-primaryGrey">Blacklist of this Flow currently in effect</h2>
                                    <v-divider class="my-3"></v-divider>
                                    <Blacklist :cryptoId="id"></Blacklist>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <!-- ------------------- nFRs -------------------- -->
                <NonFungibleFutureRewards :cryptoId="id" :nfr="nfr" :unwrap="unCrypto.contractFunction == 'UNWRAP'"></NonFungibleFutureRewards>
            </v-card>
            <!-- 更多推荐 -->
            <v-card max-width="1440" class="mt-16 mx-auto" elevation="0" rounded="0" color="f2f" v-show="hasMoreCryptos">
                <v-row no-gutters justify="start">
                    <v-col cols="12">
                        <h1 class="sub-page-h1 text-primaryGrey">More from this Originator</h1>
                    </v-col>
                </v-row>
            </v-card>
       </v-container>
       <!-- 更多来自此发起人 -->
       <v-container class="more bg-grey01" v-show="hasMoreCryptos">
            <v-card color="transparent" rounded="0" elevation="0" width="1440" class="mx-auto">
                <v-row no-gutters justify="center" class="my-5 r-m-y-0">
                    <OriginatorMoreCrypto :userId="unCrypto.userId" :excludedCryptoId="id" @emitHasMoreCryptos="callHasMoreCryptos"></OriginatorMoreCrypto>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Provenance from '@/components/crypto/Provenance.vue'
import UnCryptoOwner from '@/components/crypto/UnCryptoOwner.vue'
import UnCryptoOffer from '@/components/crypto/UnCryptoOffer.vue'
import NonFungibleFutureRewards from '@/components/crypto/NonFungibleFutureRewards.vue'
import Blacklist from '@/components/crypto/Blacklist.vue'
import OriginatorMoreCrypto from '@/components/crypto/OriginatorMoreCrypto.vue'
import CryptoAPI from '@/api/crypto.js';
import CryptoNFRAPI from '@/api/crypto-nfr.js';
import CryptoStatAPI from '@/api/crypto-stat.js';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 封装后的加密货币
            unCrypto: {},
            // 当前的区块链
            currentBlockchain: {},
            // NFR 数据
            nfr: {
                numberOfGenerations: 1,
                totalNFRPaid: 0,
                originatorsRewards: [],
                futureRewards: [],
                cryptoQuote: {},
                hasHidden: false
            },
            // 有更多推荐
            hasMoreCryptos: false,
            // 更多数量高度
            moreCryptosHeight: 0
        }
    },
    components: { Provenance, NonFungibleFutureRewards, Blacklist, UnCryptoOwner, UnCryptoOffer, OriginatorMoreCrypto },
    created(){

    },
    mounted(){
        // 延时一分钟后重新查询一次，可能会更新信息
        setTimeout(() => {
            // 根据主键查询加密货币
            this.getCryptoById();
            // 根据加密货币主键查询NFR信息
            this.getNfrByCryptoId();
        }, 60000);
    },
    computed: {
        ...mapGetters(['blockchains', 'responsive']),
    },
    watch:{
        $route(to, from) {
            // 要去的页面还是当前页面，则更新id，否则是去别的页面，不更新
            if(to.name == "UnCrypto") {
                this.id = this.$route.params.id;
            } else {
                // 离开页面时停止循环任务
                // clearInterval(this.refreshInterval);
            }
        },
        id: {
            handler(newVal, oldVal) {
                // 根据加密货币主键查询加密货币
                this.getCryptoById();
                // 根据加密货币主键查询NFR信息
                this.getNfrByCryptoId();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.id == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.id);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
                // 更新标题
                this.$route.meta.name = this.unCrypto.wrappedCurrency + ' #' + this.unCrypto.tokenId;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.unCrypto.blockchain)[0];
                // 浏览
                this.view();
            } else {
                this.$router.push("/404")
            }
            this.loading = false;
        },
        // 浏览
        async view() {
            await CryptoStatAPI.view(this.id);
        },
        // 根据加密货币主键查询NFR信息
        async getNfrByCryptoId() {
            if(this.id == null) {
                return;
            }
            let res = await CryptoNFRAPI.getNfrByCryptoId(this.id);
            let data = res.data;
            if(data.success) {
                this.nfr = data.data;
            }
        },
        // 更多推荐
        callHasMoreCryptos(hasMoreCryptos, length) {
            this.hasMoreCryptos = hasMoreCryptos;
            this.moreCryptosHeight = 80 + 36 * length;
            // 不是pc端则空出底部60的菜单
            if (this.responsive != 'pc') {
                this.moreCryptosHeight -= 60;
            }
        }
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0; /*初始状态 透明度为0*/
    }
    50% {
        opacity: 0.3; /*中间状态 透明度为0*/
    }
    100% {
        opacity: 1; /*结尾状态 透明度为1*/
    }
}

#fadeIn {
    -webkit-animation-name: fadeIn; /*动画名称*/
    -webkit-animation-duration: 0.5s; /*动画持续时间*/
    -webkit-animation-iteration-count: 1; /*动画次数*/
}
.more {
    position: fixed;
    z-index: 0;
    bottom: 0px;
}
.crypto-detail {
    position: relative;
    z-index: 1;
}

</style>