<template>
	<div v-for="i in length" :key="i">
        <v-card class="bg-lightGray mb-5" width="300" height="30" rounded="0" elevation="0"></v-card>
        <div class="d-flex">
            <div>
                <v-avatar class="bg-lightGray"></v-avatar>
            </div>
            <div class="ml-2">
                <v-card class="bg-lightGray" width="500" height="40" rounded="0" elevation="0"></v-card>
                <v-card class="bg-lightGray mt-2" width="350" height="40" rounded="0" elevation="0"></v-card>
            </div>
        </div>
        <v-divider class="my-5"></v-divider>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>