<template>
    <div>
        
        <v-container>
            <v-row no-gutters justify="center" align="center">
                <v-col cols="12">
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            
        }
    },
    components: { Logo },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>