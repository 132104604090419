<template>
	<div>
        <v-row no-gutters>
            <v-col cols="12">
                <v-tabs v-model="tab" slider-color="primary" left fixed-tabs class="mx-6 r-m-l-2 r-m-r-2"  density="compact">
                    <v-tab value="forYou" @click="refresh" ><h3 class="title-h4 text-primaryGrey">For You</h3></v-tab>
                    <v-tab value="following" @click="refresh"><h3 class="title-h4 text-primaryGrey">Following</h3></v-tab>
                </v-tabs>
                <v-divider class="mx-6 r-m-l-2 r-m-r-2" color="primary"></v-divider>
                <v-window v-model="tab">
                    <v-window-item value="forYou" v-infinite-scroll="getProvenancesByPage" infinite-scroll-immediate :infinite-scroll-distance="100" :infinite-scroll-disabled="forYou.noMoreData">
                        <div v-if="tab == 'forYou'" class="pl-n6">
                            <div class="py-8 px-6 r-p-x-2" v-for="provenance in forYou.provenances" :key="provenance.id">
                                <v-card rounded="0" color="transparent" max-width="648" class="mx-auto" :class="responsive == 'pc' ? 'elevation-12 ' : 'elevation-6'">
                                    <v-row align="center" no-gutters class="pa-8 r-p-4">
                                        <v-col cols="1">
                                            <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                        </v-col>
                                        <v-col cols="11" class="pl-6 r-p-l-8">
                                            <div class="body-p text-primaryGrey">
                                                <span class="gradient-underline-hover">
                                                    <router-link @click.stop :to="'/profile/' + provenance.user.username" class="del-underline gradient-text-hover">
                                                        <span class="fw-b">{{ provenance.user.name }}</span>
                                                    </router-link>
                                                </span>
                                                <span v-function-action="provenance.contractFunction"></span>
                                                <span class="fw-b gradient-underline-hover">
                                                    <router-link :to="$tools.getUnNFTUrl(provenance.nft.blockchain, provenance.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                        {{ provenance.nft.name }}
                                                    </router-link>
                                                </span>
                                                <span v-if="provenance.contractFunction == 'LIST' || provenance.contractFunction == 'BUY'">
                                                    at {{ $tools.formatNumber(provenance.price) }} {{ provenance.paymentToken }}
                                                </span>
                                            </div>
                                            <div class="mt-1 body-p text-primaryGrey">
                                                <span v-time="provenance.timestamp"></span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <MediaFile :nftId="provenance.nft.id" overlay></MediaFile>
                                    </div>
                                    <div class="px-8 py-8" v-if="responsive == 'pc'">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6" class="pr-6">
                                                <div class="body-p-small text-primaryGrey">Total Reward
                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                        <template v-slot:activator="{ props }" >
                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                        </template>
                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                            <!-- {{ $t('common.totalReward') }} -->
                                                            The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                                        </v-card>
                                                    </v-menu>
                                                </div>
                                                <div class="my-2 body-p-small text-primaryGrey">{{ provenance.nft.totalRewardRatio }}%</div>
                                                <v-divider></v-divider>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <div class="body-p-small text-primaryGrey">Future Rewards (FR)
                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                        <template v-slot:activator="{ props }" >
                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                        </template>
                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                            <!-- {{ $t('common.futureRewards') }} -->
                                                            Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                        </v-card>
                                                    </v-menu>
                                                </div>
                                                <div class="my-2 body-p-small text-primaryGrey">{{ 100 - provenance.nft.originatorRewardRatio }}%</div>
                                                <v-divider></v-divider>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-4">
                                            <v-col cols="12" sm="6" class="pr-6">
                                                <div class="body-p-small text-primaryGrey"># of generations
                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                        <template v-slot:activator="{ props }" >
                                                            <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                        </template>
                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                            <!-- {{ $t('common.ofGenerations') }} -->
                                                             The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                        </v-card>
                                                    </v-menu>
                                                </div>
                                                <div class="my-2 body-p-small text-primaryGrey">{{ provenance.nft.numberOfGenerations }}</div>
                                                <v-divider></v-divider>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <div class="body-p-small text-primaryGrey">Originator Reward (OR)
                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                        <template v-slot:activator="{ props }" >
                                                            <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                                        </template>
                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                            <!-- {{ $t('common.originatorReward') }} -->
                                                            The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                                        </v-card>
                                                    </v-menu>
                                                </div>
                                                <div class="my-2 body-p-small text-primaryGrey">{{ provenance.nft.originatorRewardRatio }}%</div>
                                                <v-divider></v-divider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                                
                            </div>
                            <!-- 加载中骨架 -->
                            <div v-show="forYou.loading" class="mt-8 mx-6">
                                <SkeletonMoment :length="1"></SkeletonMoment>
                            </div>
                            <!-- 无可用数据 -->
                            <div v-show="forYou.noDataAvailable" class="my-10">
                                <NoDataAvailable></NoDataAvailable>
                            </div>
                        </div>
                    </v-window-item>
                    <v-window-item value="following" v-infinite-scroll="getFollowingProvenancesByPage" infinite-scroll-immediate :infinite-scroll-distance="100" :infinite-scroll-disabled="following.noMoreData">
                        <div v-if="tab == 'following'" class="pl-n6">
                            <div class="mt-10 body-p text_center text-primaryGrey r-m-l-15 mx-6" v-if="!token">
                                <router-link to="/market" class="del-underline text-primaryGrey gradient-underline">
                                    <span class="gradient-text-hover">Explore</span>
                                </router-link> 
                                the following of untrading.org after 
                                <router-link to="/connectwallet" class="del-underline text-primaryGrey gradient-underline">
                                    <span class="gradient-text-hover">sign in</span>
                                </router-link>.
                            </div>
                            <div class="mt-2" v-if="token">
                                <div class="py-8 px-6 r-p-x-2" v-for="provenance in following.provenances" :key="provenance.id">
                                    <v-card rounded="0" color="transparent" max-width="648" class="mx-auto" :class="responsive == 'pc' ? 'elevation-12 ' : 'elevation-6'">
                                        <v-row align="center" no-gutters class="r-m-l-4 pa-8">
                                            <v-col cols="1">
                                                <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                            </v-col>
                                            <v-col cols="11" class="pl-6 r-p-l-8">
                                                <div class="body-p text-primaryGrey body-p gradient-underline-hover">
                                                    <router-link @click.stop :to="'/profile/' + provenance.user.username" class="del-underline gradient-text-hover">
                                                        <span class="fw-b">{{ provenance.user.name }}</span>
                                                    </router-link>
                                                    <span v-function-action="provenance.contractFunction"></span>
                                                    <span class="fw-b">
                                                        <router-link :to="$tools.getUnNFTUrl(provenance.nft.blockchain, provenance.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                            {{ provenance.nft.name }}
                                                        </router-link>
                                                    </span>
                                                    <span v-if="provenance.contractFunction == 'LIST' || provenance.contractFunction == 'BUY'">
                                                        at {{ $tools.formatNumber(provenance.price) }} {{ provenance.paymentToken }}
                                                    </span>
                                                </div>
                                                <div class="mt-1 body-p text-primaryGrey">
                                                    <span v-time="provenance.timestamp"></span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <div class="mt-5">
                                            <MediaFile :nftId="provenance.nft.id" overlay></MediaFile>
                                        </div>
                                        <div class="px-8 py-8">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="6" class="pr-6">
                                                    <div class="body-p-small text-primaryGrey">Total Reward
                                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                            <template v-slot:activator="{ props }" >
                                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                            </template>
                                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                {{ $t('common.totalReward') }}
                                                            </v-card>
                                                        </v-menu>
                                                    </div>
                                                    <div class="my-2 body-p-small text-primaryGrey">{{ provenance.nft.totalRewardRatio }}%</div>
                                                    <v-divider></v-divider>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <div class="body-p-small text-primaryGrey">Future Rewards (FR)
                                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                            <template v-slot:activator="{ props }" >
                                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                            </template>
                                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                {{ $t('common.futureRewards') }}
                                                            </v-card>
                                                        </v-menu>
                                                    </div>
                                                    <div class="my-2 body-p-small text-primaryGrey">{{ 100 - provenance.nft.originatorRewardRatio }}%</div>
                                                    <v-divider class="mr-6"></v-divider>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-4">
                                                <v-col cols="12" sm="6" class="pr-6">
                                                    <div class="body-p-small text-primaryGrey"># of generations
                                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                            <template v-slot:activator="{ props }" >
                                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                            </template>
                                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                {{ $t('common.ofGenerations') }}
                                                            </v-card>
                                                        </v-menu>
                                                    </div>
                                                    <div class="my-2 body-p-small text-primaryGrey">{{ provenance.nft.numberOfGenerations }}</div>
                                                    <v-divider></v-divider>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <div class="body-p-small text-primaryGrey">Originator Reward (OR)
                                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                            <template v-slot:activator="{ props }" >
                                                                <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                                            </template>
                                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                {{ $t('common.originatorReward') }}
                                                            </v-card>
                                                        </v-menu>
                                                    </div>
                                                    <div class="my-2 body-p-small text-primaryGrey">{{ provenance.nft.originatorRewardRatio }}%</div>
                                                    <v-divider class="mr-6"></v-divider>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </div>
                                <!-- 加载中骨架 -->
                                <div v-show="following.loading" class="mt-8 mx-6">
                                    <SkeletonMoment :length="1"></SkeletonMoment>
                                </div>
                                <!-- 无可用数据 -->
                                <div v-show="following.noDataAvailable" class="my-10 text_center r-m-l-16 mx-16 px-2">
                                    <span class="text-primaryGrey body-p">
                                        <router-link to="/market" class="del-underline text-primaryGrey gradient-underline">
                                            <span class="gradient-text-hover">Explore</span>
                                        </router-link> 
                                        the rest of untrading.org to find originators and collectors to follow.</span>
                                </div>
                            </div>
                        </div>
                    </v-window-item>
                </v-window>
            </v-col>   
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTProvenanceAPI from '@/api/nft-provenance.js';
import SkeletonMoment from '@/components/util/skeleton/SkeletonMoment.vue';
import MediaFile from '@/components/media/MediaFile.vue';
export default {
    data(){
        return {
            tab: null,
            size: 5,
            forYou: {
                // 加载中
                loading: false,
                // 无可用数据
                noDataAvailable: false,
                // 无更多数据
                noMoreData: false,
                // 搜索结果列表
                provenances: [],
                page: 1,
            },
            following: {
                // 加载中
                loading: false,
                // 无可用数据
                noDataAvailable: false,
                // 无更多数据
                noMoreData: false,
                // 搜索结果列表
                provenances: [],
                page: 1,
            },
            
        }
    },
    components: { SkeletonMoment, MediaFile },
    created(){
        this.getProvenancesByPage();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['token', 'blockchain', 'responsive'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 分页查询所有NFT的历史记录
        async getProvenancesByPage() {
            if(this.forYou.noDataAvailable || this.forYou.noMoreData || this.forYou.loading) {
                return;
            }
            this.forYou.noDataAvailable = false;
            this.forYou.noMoreData = false;
            this.forYou.loading = true;
            let res = await NFTProvenanceAPI.getProvenancesByPage(this.forYou.page, this.size, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.forYou.provenances.push(...data.data.records);
                this.forYou.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.forYou.noMoreData = true;
                }
            } else {
                if(this.forYou.provenances.length == 0) {
                    this.forYou.noDataAvailable = true;
                    this.forYou.noMoreData = false;
                } else if(this.forYou.provenances.length > 0) {
                    this.forYou.noDataAvailable = false;
                    this.forYou.noMoreData = true;
                }
            }
            this.forYou.loading = false;
        },
        // 分页查询关注用户的 NFT 的历史记录
        async getFollowingProvenancesByPage() {
            if(this.following.noDataAvailable || this.following.noMoreData || this.following.loading) {
                return;
            }
            this.following.noDataAvailable = false;
            this.following.noMoreData = false;
            this.following.loading = true;
            let res = await NFTProvenanceAPI.getFollowingProvenancesByPage(this.following.page, this.size, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.following.provenances.push(...data.data.records);
                this.following.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.following.noMoreData = true;
                }
            } else {
                if(this.following.provenances.length == 0) {
                    this.following.noDataAvailable = true;
                    this.following.noMoreData = false;
                } else if(this.following.provenances.length > 0) {
                    this.following.noDataAvailable = false;
                    this.following.noMoreData = true;
                }
            }
            this.following.loading = false;
        },
        // 刷新
        refresh() {
            this.forYou.loading = false;
            this.forYou.noDataAvailable = false;
            this.forYou.noMoreData = false;
            this.forYou.provenances = [];
            this.forYou.page = 1;
            this.following.loading = false;
            this.following.noDataAvailable = false;
            this.following.noMoreData = false;
            this.following.provenances = [];
            this.following.page = 1;
            // 重新查询数据
            this.getProvenancesByPage();
            this.getFollowingProvenancesByPage();
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}

</style>