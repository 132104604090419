<template>
	<div></div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){
        this.$bus.on('emitGetTransactionReceipt', params => {
            // {
            //     type: "",
            //     blockchain: blockchain,
            //     transactionHash: transactionHash
            // }
            this.getTransactionReceipt(params);
        })
        this.$bus.on('emitGetApproveTransactionReceipt', params => {
            // {
            //     type: "",
            //     blockchain: blockchain,
            //     transactionHash: transactionHash
            // }
            this.getApproveTransactionReceipt(params);
        })
        this.$bus.on('emitGetListInfo', params => {
            // {
            //     type: "",
            //     blockchain: blockchain,
            //     tokenId: tokenId
            // }
            this.getListInfo(params);
        })
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchains']),
    },
    watch:{

    },
    methods: {
        // 查询交易收据：https://web3js.readthedocs.io/en/v1.8.0/web3-eth.html#gettransactionreceipt
        async getTransactionReceipt(params, callback){
            if(!params.blockchain || !params.transactionHash) {
                return;
            }
            // 当前区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == params.blockchain)[0];
            // 创建 web3
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            // 当交易还在 Pending 中的时候，data 为 null
            let data = await web3.eth.getTransactionReceipt(params.transactionHash);
            if(data){
                console.log('Got Transaction Receipt!');
            }
            // 回调传值
            this.$bus.emit('callGetTransactionReceipt', { type: params.type, data: data });
        },
        // 查询批准交易收据：https://web3js.readthedocs.io/en/v1.8.0/web3-eth.html#gettransactionreceipt
        async getApproveTransactionReceipt(params, callback){
            if(!params.blockchain || !params.transactionHash) {
                return;
            }
            // 当前区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == params.blockchain)[0];
            // 创建 web3
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            // 当交易还在 Pending 中的时候，data 为 null
            let data = await web3.eth.getTransactionReceipt(params.transactionHash);
            if(data){
                console.log('Got Transaction Receipt!');
            }
            // 回调传值
            this.$bus.emit('callGetApproveTransactionReceipt', { type: params.type, data: data });
        },
        // 查询NFT上市信息
        async getListInfo(params){
            // 当前区块链
            let currentBlockchain = this.blockchains.filter(b => b.blockchain == params.blockchain)[0];
            // 创建 web3
            let web3 = new Web3(new Web3.providers.HttpProvider(currentBlockchain.RPCUrl));
            // 创建合约
            let NFTContract = new web3.eth.Contract(currentBlockchain.unNFTAbi, currentBlockchain.unNFTContract);
            // {0: '0', 1: '0x0000000000000000000000000000000000000000', 2: false}
            let data = await NFTContract.methods.getListInfo(params.tokenId).call();
            // uint256 :  0 [ETH mantissa of the listed selling price]
            // address :  0x0000000000000000000000000000000000000000 [Owner/Lister of the Token]
            // bool :  false [Boolean indicating whether the Token is listed or not]
            // console.log(data);
            // 回调传值
            this.$bus.emit('callGetListInfo', { type: params.type, data: data });
        }
    }
}
</script>
<style scoped>

</style>