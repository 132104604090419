<template>
	<div class="mt-100">
        <v-container class="r-p-x-0">
            <v-row no-gutters>
                <v-col cols="12" sm="6">
                    <router-link to="/sets" class="pointer mb-13 data-card r-m-l-1">
                        <div class="list-title">
                            <h4>Collect</h4>
                        </div>
                        <div class="body-p-small mt-4">Discover & owner unique art</div>
                    </router-link>
                    <router-link to="/unnft/mint" class="pointer mb-13 data-card r-m-l-1">
                        <div class="list-title">
                            <h4>Create</h4>
                        </div>
                        <div class="body-p-small mt-4">Discover & owner unique art</div>
                    </router-link>
                    <router-link to="/unnft/wrap" class="pointer data-card r-m-l-1">
                        <div class="list-title">
                            <h4>Sell</h4>
                        </div>
                        <div class="body-p-small mt-4">Upgrade the NFTs you own</div>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme'])
    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>