<template>
	<div>
        <v-row no-gutters>
            <v-col cols="12">
                <v-tabs v-model="tab" left fixed-tabs slider-color="primary" class="mx-6 r-m-l-0 r-m-r-2 text-primaryGrey" density="compact">
                    <v-tab value="forYou" @click="refresh"><h3 class="body-p">For You</h3></v-tab>
                    <v-tab value="following" @click="refresh"><h3 class="body-p">Following</h3></v-tab>
                </v-tabs>
                <v-divider class="mx-6 r-m-l-0 r-m-r-2" color="primaryGrey"></v-divider>
                <v-window v-model="tab">
                    <v-window-item value="forYou">
                        <div v-if="tab == 'forYou'" class="mt-7 pl-n6">
                            <v-carousel v-model="carouselIndex" v-show="!loading" :continuous="false" :show-arrows="false" interval="5000" :height="height" hide-delimiter-background color="d7d" delimiter-icon="mdi:mdi-circle" class="carousel-item-active">
                                <v-carousel-item class="r-p-x-2" v-for="provenance in forYou.provenances" :key="provenance.id">
                                        <v-card id="for-you-carousel-card" rounded="0" color="transparent" max-width="648" class="mx-auto" elevation="6">
                                            <v-row align="center" no-gutters class="pa-8 r-p-4">
                                                <v-col cols="1">
                                                    <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                                </v-col>
                                                <v-col cols="11" class="pl-6 r-p-l-8">
                                                    <div class="body-p text-primaryGrey">
                                                        <span class="gradient-underline-hover">
                                                            <router-link @click.stop :to="'/profile/' + provenance.user.username" class="del-underline gradient-text-hover">
                                                                <span class="fw-b">{{ provenance.user.name }}</span>
                                                            </router-link>
                                                        </span>
                                                        <span v-function-action="provenance.contractFunction"></span>
                                                        <span class="fw-b gradient-underline-hover">
                                                            <router-link :to="$tools.getUnNFTUrl(provenance.nft.blockchain, provenance.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                {{ provenance.nft.name }}
                                                            </router-link>
                                                        </span>
                                                        <span v-if="provenance.contractFunction == 'LIST' || provenance.contractFunction == 'BUY'">
                                                            at {{ $tools.formatNumber(provenance.price) }} {{ provenance.paymentToken }}
                                                        </span>
                                                    </div>
                                                    <div class="mt-1 body-p text-primaryGrey">
                                                        <span v-time="provenance.timestamp"></span>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <div>
                                                <MediaFile :nftId="provenance.nft.id" overlay></MediaFile>
                                            </div>
                                            <div class="d-flex justify-space-between mt-5 mx-4 pb-8">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="6">
                                                        <div class="body-p-small">
                                                            <div>Total Reward
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        <!-- {{ $t('common.totalReward') }} -->
                                                                        The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ provenance.nft.totalRewardRatio }}%</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="mt-4">
                                                        <div class="body-p-small">
                                                            <div>Future Rewards
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        <!-- {{ $t('common.futureRewards') }} -->
                                                                        Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ 100 - provenance.nft.originatorRewardRatio }}%</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="ml-4">
                                                    <v-col cols="12" sm="6">
                                                        <div class="body-p-small">
                                                            <div>Originator Rewards
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        <!-- {{ $t('common.originatorReward') }} -->
                                                                        The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split).
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ provenance.nft.originatorRewardRatio }}%</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="mt-4">
                                                        <div class="body-p-small">
                                                            <div># of generations
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        <!-- {{ $t('common.ofGenerations') }} -->
                                                                        The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ provenance.nft.numberOfGenerations }}</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card>
                            </v-carousel-item>
                            </v-carousel>
                            <!-- 加载中骨架 -->
                            <div v-show="forYou.loading" class="mt-8 mx-6">
                                <SkeletonMoment :length="1"></SkeletonMoment>
                            </div>
                            <!-- 无可用数据 -->
                            <div v-show="forYou.noDataAvailable" class="my-10">
                                <NoDataAvailable></NoDataAvailable>
                            </div>
                            <div>
                                <TopFlowsMobile></TopFlowsMobile>
                                <TrendingOriginatorsMobile class="mt-100"></TrendingOriginatorsMobile>
                                <TopCollectorsMobile class="mt-100"></TopCollectorsMobile>
                                <SetsMobile class="mt-100"></SetsMobile>
                                <FeaturedReleasesMobile class="mt-100"></FeaturedReleasesMobile>
                                <MarketStats class="mt-100"></MarketStats>
                                <HomeTag class="mt-100"></HomeTag>
                                <HomeFooter class="mt-100"></HomeFooter>
                            </div>
                            <div>
                                   <ForYouMobile class="mt-100"></ForYouMobile>
                            </div>
                        </div>
                    </v-window-item>
                    <v-window-item value="following" v-infinite-scroll="getFollowingProvenancesByPage" infinite-scroll-immediate :infinite-scroll-distance="100" :infinite-scroll-disabled="following.noMoreData">
                        <div v-if="tab == 'following'" class="pl-n6">
                            <div class="mt-10 body-p text_center text-primaryGrey r-m-l-12 mx-6" v-if="!token">
                                <router-link to="/market" class="del-underline text-primaryGrey gradient-underline">
                                    <span class="gradient-text-hover">Explore</span>
                                </router-link> 
                                the following of untrading.org after 
                                <router-link to="/connectwallet" class="del-underline text-primaryGrey gradient-underline">
                                    <span class="gradient-text-hover">sign in</span>
                                </router-link>.
                            </div>
                            <div class="mt-2" v-if="token">
                                    <div class="py-8 r-p-x-2" v-for="provenance in following.provenances" :key="provenance.id">
                                        <v-card rounded="0" color="transparent" max-width="648" class="mx-auto" elevation="6">
                                            <v-row align="center" no-gutters class="r-m-l-4 pa-8">
                                                <v-col cols="1">
                                                    <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                                </v-col>
                                                <v-col cols="11" class="pl-6 r-p-l-8">
                                                    <div class="body-p text-primaryGrey body-p gradient-underline-hover">
                                                        <router-link @click.stop :to="'/profile/' + provenance.user.username" class="del-underline gradient-text-hover">
                                                            <span class="fw-b">{{ provenance.user.name }}</span>
                                                        </router-link>
                                                        <span v-function-action="provenance.contractFunction"></span>
                                                        <span class="fw-b">
                                                            <router-link :to="$tools.getUnNFTUrl(provenance.nft.blockchain, provenance.nft.id)" class="del-underline text-primaryGrey gradient-text-hover">
                                                                {{ provenance.nft.name }}
                                                            </router-link>
                                                        </span>
                                                        <span v-if="provenance.contractFunction == 'LIST' || provenance.contractFunction == 'BUY'">
                                                            at {{ $tools.formatNumber(provenance.price) }} {{ provenance.paymentToken }}
                                                        </span>
                                                    </div>
                                                    <div class="mt-1 body-p text-primaryGrey">
                                                        <span v-time="provenance.timestamp"></span>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <div class="mt-5">
                                                <MediaFile :nftId="provenance.nft.id" overlay></MediaFile>
                                            </div>
                                            <div class="d-flex justify-space-between mt-5 mx-4 pb-8">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="6">
                                                        <div class="body-p-small">
                                                            <div>Total Reward
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        {{ $t('common.totalReward') }}
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ provenance.nft.totalRewardRatio }}%</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="mt-4">
                                                        <div class="body-p-small">
                                                            <div>Future Rewards
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        {{ $t('common.futureRewards') }}
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ 100 - provenance.nft.originatorRewardRatio }}%</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="ml-4">
                                                    <v-col cols="12" sm="6">
                                                        <div class="body-p-small">
                                                            <div>Originator Rewards
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        {{ $t('common.originatorReward') }}
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ provenance.nft.originatorRewardRatio }}%</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="mt-4">
                                                        <div class="body-p-small">
                                                            <div># of generations
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        {{ $t('common.ofGenerations') }}
                                                                    </v-card>
                                                                </v-menu>
                                                            </div>
                                                            <div>{{ provenance.nft.numberOfGenerations }}</div>
                                                            <v-divider></v-divider>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card>
                                    </div>
                                <!-- 加载中骨架 -->
                                <div v-show="following.loading" class="mt-8 mx-6">
                                    <SkeletonMoment :length="1"></SkeletonMoment>
                                </div>
                                <!-- 无可用数据 -->
                                <div v-show="following.noDataAvailable" class="my-10 text_center r-m-l-16 mx-16 px-2">
                                    <span class="text-primaryGrey body-p">
                                        <router-link to="/market" class="del-underline text-primaryGrey gradient-underline">
                                            <span class="gradient-text-hover">Explore</span>
                                        </router-link> 
                                        the rest of untrading.org to find originators and collectors to follow.</span>
                                </div>
                            </div>
                        </div>
                    </v-window-item>
                </v-window>
            </v-col>   
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTProvenanceAPI from '@/api/nft-provenance.js';
import SkeletonMoment from '@/components/util/skeleton/SkeletonMoment.vue';
import MediaFile from '@/components/media/MediaFile.vue';
import TopFlowsMobile from '@/components/home/mobile/TopFlows.vue'
import TrendingOriginatorsMobile from '@/components/home/mobile/TrendingOriginators.vue'
import TopCollectorsMobile from '@/components/home/mobile/TopCollectors.vue'
import SetsMobile from '@/components/home/mobile/Sets.vue'
import FeaturedReleasesMobile from '@/components/home/mobile/FeaturedReleases.vue'
import MarketStats from '@/components/home/MarketStats.vue'
import HomeTag from '@/components/home/HomeTag.vue'
import HomeFooter from '@/components/home/HomeFooter.vue'
import ForYouMobile from '@/components/home/mobile/ForYou.vue'
export default {
    data(){
        return {
            tab: null,
            size: 5,
            forYou: {
                // 加载中
                loading: false,
                // 无可用数据
                noDataAvailable: false,
                // 无更多数据
                noMoreData: false,
                // 搜索结果列表
                provenances: [],
                page: 1,
            },
            following: {
                // 加载中
                loading: false,
                // 无可用数据
                noDataAvailable: false,
                // 无更多数据
                noMoreData: false,
                // 搜索结果列表
                provenances: [],
                page: 1,
            },
            // 轮播图的下标
            carouselIndex: 0,
            // 默认高度，根据数据动态计算
            // 692 高 = 632 内容 + 10 间距 + 50 轮播
            height: 692
        }
    },
    components: { SkeletonMoment, MediaFile,TopFlowsMobile, TrendingOriginatorsMobile, TopCollectorsMobile, SetsMobile, FeaturedReleasesMobile, MarketStats, HomeTag, HomeFooter,ForYouMobile  },
    created(){

    },
    mounted(){
        this.getProvenancesByPage();
        this.getFollowingProvenancesByPage();
    },
    computed: {
        ...mapGetters(['token', 'blockchain', 'responsive'])
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 分页查询所有NFT的历史记录
        async getProvenancesByPage() {
            if(this.forYou.noDataAvailable || this.forYou.noMoreData || this.forYou.loading) {
                return;
            }
            this.forYou.noDataAvailable = false;
            this.forYou.noMoreData = false;
            this.forYou.loading = true;
            let res = await NFTProvenanceAPI.getProvenancesByPage(this.forYou.page, this.size, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.forYou.provenances.push(...data.data.records);
            } else {
                if(this.forYou.provenances.length == 0) {
                    this.forYou.noDataAvailable = true;
                    this.forYou.noMoreData = false;
                } else if(this.forYou.provenances.length > 0) {
                    this.forYou.noDataAvailable = false;
                    this.forYou.noMoreData = true;
                }
            }
            this.forYou.loading = false;
            setTimeout(() => {
                let forYouCarouselCard = document.getElementById('for-you-carousel-card');
                if(forYouCarouselCard) {
                    let contentHeight = forYouCarouselCard.height || forYouCarouselCard.clientHeight || forYouCarouselCard.offsetHeight || forYouCarouselCard.scrollHeight || this.height;
                    this.height = contentHeight + 10 + 50;
                }
            }, 1)
        },
        // 分页查询关注用户的 NFT 的历史记录
        async getFollowingProvenancesByPage() {
            if(this.following.noDataAvailable || this.following.noMoreData || this.following.loading) {
                return;
            }
            this.following.noDataAvailable = false;
            this.following.noMoreData = false;
            this.following.loading = true;
            let res = await NFTProvenanceAPI.getFollowingProvenancesByPage(this.following.page, this.size, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.following.provenances.push(...data.data.records);
                this.following.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.following.noMoreData = true;
                }
            } else {
                if(this.following.provenances.length == 0) {
                    this.following.noDataAvailable = true;
                    this.following.noMoreData = false;
                } else if(this.following.provenances.length > 0) {
                    this.following.noDataAvailable = false;
                    this.following.noMoreData = true;
                }
            }
            this.following.loading = false;
        },
        // 刷新
        refresh() {
            this.forYou.loading = false;
            this.forYou.noDataAvailable = false;
            this.forYou.noMoreData = false;
            this.forYou.provenances = [];
            this.forYou.page = 1;
            this.following.loading = false;
            this.following.noDataAvailable = false;
            this.following.noMoreData = false;
            this.following.provenances = [];
            this.following.page = 1;
            // 重新查询数据
            this.getProvenancesByPage();
            this.getFollowingProvenancesByPage();
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}
</style>