import axios from '@/config/api.request'

export default {
  // 分页查询通知信息
  getNotificationsByPage(page, size) {
    return axios.request({
        url: '/notification/infos',
        method: 'get',
        params: { 
            page: page,
            size: size
        }
    })
  },
  // 阅读通知
  read(index) {
    return axios.request({
        url: '/notification/read',
        method: 'get',
        params: { index: index }
    })
  },
  // 阅读全部通知
  readAll() {
    return axios.request({
        url: '/notification/read/all',
        method: 'get'
    })
  },
  // 查询未读通知数量
  getUnreadCount() {
    return axios.request({
        url: '/notification/unread/count',
        method: 'get'
    })
  },
}