<template>
	<div class="align-center pb-16">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1440" class="mx-auto pt-8">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card elevation="0" color="transparent">
                            <v-row no-gutters >
                                <v-col cols="12" class="ml-4 r-m-l-0">
                                    <h2 class="body-h4 text-primaryGrey">
                                        EXPLORE NFTs
                                    </h2>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <!-- 基本信息 -->
                                <v-col cols="12" class="ml-3 r-m-l-0 text-grey04">
                                    <h1 :class="responsive == 'pc' ? 'hero-h1' :'mobile-h1'">MARKET</h1>
                                </v-col>
                                <v-col cols="12" class="ml-4 r-m-l-0 mt-8 text-primaryGrey">
                                    <h6 class="body-p">Explore and collect rare digital art.</h6>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <!---------------------------- type ---------------------------->
                <v-row no-gutters class="mt-16 mx-4">
                    <v-col cols="12">
                        <v-tabs hide-slider density="compact" height="50" class="fw-b text-primaryGrey">
                            <router-link to="/market">
                                <v-tab value="market" rounded="0" width="200" class="px-0 bg-primary text-white">
                                    <h4>Market</h4>
                                </v-tab>
                            </router-link>
                            <router-link to="/sets">
                                <v-tab value="sets" rounded="0" width="200" class="px-0">
                                    <h4>Sets</h4>
                                </v-tab>
                            </router-link>
                            <router-link to="/collections">
                                <v-tab value="collections" rounded="0" width="200" class="px-0">
                                    <h4>Collections</h4>
                                </v-tab>
                            </router-link>
                        </v-tabs>
                    </v-col>
                    <v-divider class="border-opacity-100" thickness="3" color="primary"></v-divider>
                </v-row>
                <!---------------------------- 过滤条件 ---------------------------->
                <v-row no-gutters class="mt-16 pr-4 r-p-r-0">
                    <v-col cols="12">
                        <v-row no-gutters justify="end">
                            <v-col cols="12">
                                <div class="float-right">
                                    <!-- 区块链 -->
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                                <v-icon aria-label="mdi-menu-down" end icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="changeBlockchainCondition(null)">
                                                <v-list-item-title>
                                                    <Blockchain text all-chains></Blockchain>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                                <v-list-item-title>
                                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- 排序 -->
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn  aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                                {{ sortCondition }}
                                                <v-icon aria-label="mdi-menu-down" end icon="mdi:mdi-menu-down" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mb-8 mx-4 r-m-x-0"></v-divider>
                <v-row no-gutters class="mx-4 r-m-x-0" v-infinite-scroll="getNFTsByPage" :infinite-scroll-disabled="noMoreData">
                    <v-col cols="12">
                        <v-row no-gutters class="mb-5">
                            <div class="c-container">
                                <div class="c-card c-grid d-flex flex-column">
                                    <div class="c-height" v-for="(nft, i) in nfts" :key="i">
                                        <UnNft :nftId="nft.id"></UnNft>
                                    </div>
                                    <!-- 加载中骨架 -->
                                    <SkeletonUnNFT :length="4" v-if="loading"></SkeletonUnNFT>
                                </div>
                            </div>
                        </v-row>
                        <!-- 无可用数据 -->
                        <div v-show="noDataAvailable" class="my-10">
                            <NoDataAvailable></NoDataAvailable>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UnNft from '@/components/nft/UnNft';
import SkeletonUnNFT from '@/components/util/skeleton/SkeletonUnNFT.vue';
import NFTAPI from '@/api/nft.js';
export default {
    data(){
        return {
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Price', 'Lowest Price', 'Highest Views', 'Lowest Views'],
            sortCondition: 'Newest',
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            // 搜索结果列表
            nfts: [],
            page: 1,
            size: 10,
        }
    },
    components: { UnNft, SkeletonUnNFT },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain','responsive']),
    },
    watch:{
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 分页查询 NFT 列表
        async getNFTsByPage() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await NFTAPI.getNFTsByPage(this.page, this.size, this.sortCondition, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.nfts.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.nfts.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.nfts.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        },
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
        // 改变排序条件
        changeSortCondition(sortCondition) {
            // 恢复data定义的所有参数
            // Object.assign(this.$data, this.$options.data.call(this));
            // 改变条件
            this.sortCondition = sortCondition;
            // 刷新
            this.refresh();
        },
        // 刷新
        refresh() {
            this.loading = false;
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.nfts = [];
            this.page = 1;
            // 重新查询数据
            this.getNFTsByPage();
        }
    }
}
</script>
<style scoped>

</style>