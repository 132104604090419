import axios from '@/config/api.request'

export default {
    // 根据加密货币主键查询统计数据
    getStat(cryptoId) {
        return axios.request({
            url: '/crypto/stat/stat',
            method: 'get',
            params: { cryptoId }
        })
    },
    // 浏览加密货币
    view(cryptoId) {
        return axios.request({
            url: '/crypto/stat/view',
            method: 'get',
            params: { cryptoId }
        })
    },
}