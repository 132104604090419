<template>
	<div>
       <v-card max-width="500" elevation="0" rounded="0" color="transparent" class="text-primaryGrey">
        <v-row no-gutters>
            <v-col cols="12" class="pa-0">
                <div class="body-text-btn">
                   FR Payments Received
                </div>
            </v-col>
            <v-divider class="my-4"></v-divider>
            <v-col cols="12">
                <SkeletonCard :height="50" :length="10" v-if="loading"></SkeletonCard>
                <table class="bg-f2f text-primaryGrey mx-0" v-if="!loading">
                    <thead class="px-0">
                        <tr>
                            <td class="text-right form-text" style="width: 50px;">Epoch</td>
                            <td class="text-right form-text">Gen</td>
                            <td class="text-right form-text">From</td>
                            <td class="text-right form-text">Amount</td>
                            <td class="text-left form-text  text-right">Currency</td>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="payment in frPayment.payments" :key="payment.index" class="body-p-small">
                        <td class="text-right body-p-small">{{ $tools.convertToRoman(payment.epoch) }}</td>
                        <td class="text-right body-p-small">{{ payment.generation }}</td>
                        <td class="text-right body-p-small">
                            <span v-if="payment.from" class="d-flex flex-nowrap justify-end">
                                <span class="mr-2">{{ payment.from.name }}</span>
                                <UserAvatar :avatar="payment.from.avatar" :username="payment.from.username" :size="24"></UserAvatar>
                            </span>
                        </td>
                        <td class="text-right body-p-small">
                            <span v-if="payment.paid">
                                {{ payment.amount > 0 ? $tools.formatNumber(payment.amount, 8) : '--' }}
                            </span>
                        </td>
                        <td class="text-right body-p-small ellipsis-line">
                            <span v-if="payment.paid">
                                {{ payment.paymentToken }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-right form-text">Total</th>
                        <th></th>
                        <th></th>
                        <th class="form-text text-right">{{ $tools.formatNumber(frPayment.total.amount, 8) }}</th>
                        <th class="form-text text-right">{{ frPayment.total.paymentToken }}</th>
                    </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>
       </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFRAPI from '@/api/nfr.js';
import SkeletonCard from '@/components/util/skeleton/SkeletonCard';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // FR 付款记录
            frPayment: {
                payments: [],
                total: {}
            },
        }
    },
    // NFT 主键
    props: {
        nftId: {
            type: String,
            default: null
        }
    },
    components: { SkeletonCard },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user']),
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                // 根据NFT主键和用户主键查询FR信息
                this.getFRPayment();
            },
            immediate: true
        },
        'user.id': {
            handler(newVal, oldVal) {
                // 根据NFT主键和用户主键查询FR信息
                this.getFRPayment();
            },
            immediate: true
        }
    },
    methods: {
        // 根据NFT主键和用户主键查询FR信息
        async getFRPayment() {
            if(this.nftId == null || this.user.id == null) {
                return;
            }
            this.loading = true;
            let res = await NFRAPI.getFRPayment(this.nftId, this.user.id);
            let data = res.data;
            if(data.success) {
                this.frPayment = data.data;
            }
            this.loading = false;
        },
    }
}
</script>
<style scoped>
table { 
	width: 100%; 
	border-collapse: collapse; 
	}

tr th {
	padding: 10px; 
	border-top: 2px solid #9e9e9e; 
	text-align: left; 
	}

td { 
	padding: 10px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
	}

[data-toggle="toggle"] {
	display: none;
}
</style>