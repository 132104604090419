<template>
    <span class="d-flex flex-nowrap align-center">
        <router-link v-if="collectionId" :to="'/collection/' + collectionId" class="del-underline text-black">
            <v-avatar rounded="0" :size="size" class="pointer">
                <v-icon v-if="!avatar" aria-label="mdi-file-multiple-outline" color="#666" icon="mdi:mdi-file-multiple-outline" :size="size * 0.7"></v-icon>
                <v-img v-if="avatar" :src="avatar" cover :height="size"></v-img>
            </v-avatar>
        </router-link>
        <v-avatar v-if="!collectionId" rounded="0" :size="size">
            <v-icon v-if="!avatar" aria-label="mdi-file-multiple-outline" color="#666" icon="mdi:mdi-file-multiple-outline" :size="size * 0.7"></v-icon>
            <v-img v-if="avatar" :src="avatar" cover :height="size"></v-img>
        </v-avatar>
        <span class="gradient-underline-hover ml-2" v-if="collectionId">
            <router-link @click.stop :to="'/collection/' + collectionId" class="del-underline gradient-text-hover parent">
                <span class="body-p text-primaryGrey ellipsis-line">{{ name }}</span>
            </router-link>
        </span>
        <span class="gradient-underline-hover ml-2" v-if="!collectionId">
            <span class="body-p text-primaryGrey ellipsis-line">{{ name }}</span>
        </span>
    </span>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    props: {
        collectionId: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        avatar: {
            type: String,
            default: null
        },
        size: {
            type: Number,
            default: 36
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>