<template>
	<div v-for="i in length" :key="i">
        <v-card rounded="0" elevation="12">
            <v-card class="bg-lightGray" rounded="0" elevation="0">
                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public" :lazySrc="lazySrc" class="w-100" :aspect-ratio="1" cover></v-img>
            </v-card>
        </v-card>
	</div>
</template>
<script>
export default {
    data(){
        return {
            // 加载中的默认图
            lazySrc: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ab045100-af14-4a88-9345-a0c368211c00/public',
        }
    },
    props: {
        length: {
            type: Number,
            default: 1
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>