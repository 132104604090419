import axios from '@/config/api.request'

export default {
    // 转发 GET URL 返回原API数据
    forwardGet(url) {
        return axios.request({
            url: '/forward/get',
            method: 'get',
            params: { url: url }
        })
    },
    // 转发 POST URL 返回原API数据
    forwardPost(data) {
        return axios.request({
            url: '/forward/post',
            method: 'post',
            data: data
        })
    },
}