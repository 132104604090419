import axios from '@/config/api.request'
const token = localStorage.getItem("untrading-token");

export default {
    // 是否喜欢收藏夹
    liked(collectionId) {
        if(!token) {
            return { data: { success: false } }
        }
        return axios.request({
            url: '/collection/like/liked',
            method: 'get',
            params: { collectionId: collectionId }
        })
    },
    // 喜欢收藏夹
    like(collectionId) {
        return axios.request({
            url: '/collection/like/like',
            method: 'get',
            params: { collectionId: collectionId }
        })
    },
    // 取消喜欢收藏夹
    unlike(collectionId) {
        return axios.request({
            url: '/collection/like/unlike',
            method: 'get',
            params: { collectionId: collectionId }
        })
    },
    
}