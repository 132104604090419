<template>
	<div>
        <v-container fluid class="px-0" >
            <v-row no-gutters justify="space-between">
                <div class="d-flex flex-column text-center">
                    <h2 class="text-grey04 mt-4">456M+</h2> 
                    <div class="body-p text-primaryGrey mt-4">Total User Rewards Paid (UN)</div> 
                </div>
                <div class="d-flex flex-column text-center">
                    <h2 class="text-grey04 mt-4">67K+</h2> 
                    <div class="body-p text-primaryGrey mt-4">Total Traders</div> 
                </div>
                <div class="d-flex flex-column text-center">
                    <h2 class="text-grey04 mt-4">155K+</h2> 
                    <div class="body-p text-primaryGrey mt-4">Total Value Locked (TVL)</div> 
                </div>
                <div class="d-flex flex-column text-center">
                    <h2 class="text-grey04 mt-4">120+</h2> 
                    <div class="body-p text-primaryGrey mt-4">Countries Serviced</div> 
                </div>
            </v-row>
        </v-container>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>